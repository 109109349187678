import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import { validateName, validatedescription } from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";

const EditRolePermission = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [resourceList, setResourceList] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [defaultResource, setDefaultResource] = useState([]);
  const { setLoading } = useLoader();

  const signInSchema = Yup.object().shape({
    name: validateName,
    description: validatedescription,
    permissions: Yup.array()
      .required("Please select at least one permission")
      .min(1, "Please select at least one permission"),
  });

  const initialValues = {
    name: roleById?.name || "",
    description: roleById?.description || "",
    is_active: roleById?.is_active || false,
    permissions: defaultResource || [],
  };

  const getResource = async () => {
    setLoading(true);
    getNodeApi("/permissions/get-all-permissions")
      .then((res) => {
        setLoading(false);
        let resourceArr = res?.data || {};
        resourceArr = resourceArr.map((value) => {
          return {
            label: value.name,
            value: value._id,
          };
        });
        setResourceList(resourceArr);
      })
      .catch((err) => {
        if (typeof err?.response?.message !== "undefined") {
          ErrorToast(err?.response?.message || "something went wrong");
        } else {
          ErrorToast(err?.message || "something went wrong");
        }
      });
  };

  const getRoleList = async () => {
    setLoading(true);
    await getNodeApi(`/role/${id}`)
      .then((res) => {
        setLoading(false);
        setRoleById(res?.data || []);
        let editresourceArr = res?.data?.permissions || [];
        let roleMeta = editresourceArr.map((value) => {
          return value.permissions._id;
        });
        setDefaultResource(roleMeta);
      })
      .catch((err) => {
        if (typeof err?.response?.message !== "undefined") {
          ErrorToast(err?.response?.message || "something went wrong");
        } else {
          ErrorToast(err?.message || "something went wrong");
        }
      });
  };

  useEffect(() => {
    getResource();
    getRoleList();
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Edit Role Management</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Role &amp; Permissions</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/role">Role &amp; Permissions</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Role &amp; Permissions
                  </li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/role" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Role Information</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setLoading(true);
                      const rights = values?.permissions || [];
                      if (values?.rights && values?.rights?.length) {
                        for (let i = 0; i < values?.rights?.length; i++) {
                          rights.push(values?.rights[i]);
                        }
                      }
                      const postData = {
                        id: id,
                        name: values?.name,
                        description: values?.description,
                        is_active: values?.is_active,
                        permissions: rights,
                      };
                      await putNodeApi("/role", postData)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(res?.message || "Role updated.");
                            setSubmitting(false);
                            navigate("/admin/role");
                            resetForm();
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        values,
                        isSubmitting,
                        handleChange,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Role Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  onChange={handleChange}
                                  value={values?.name}
                                  placeholder="Enter role"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.description && touched.description
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="description"
                                  onChange={handleChange}
                                  value={values?.description}
                                  placeholder="Enter description"
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <h5 className="role_title">
                            Permissions Information{" "}
                            <span className="text-danger">*</span>
                          </h5>
                          <div className="row mx-3 my-4">
                            {resourceList.map((resource, index) => (
                              <div className="col-md-3" key={index}>
                                <div className="form-group">
                                  <label className="custom_check">
                                    <Field
                                      type="checkbox"
                                      className={`form-control ${
                                        errors.permissions &&
                                        touched.permissions
                                          ? "input-error"
                                          : null
                                      }`}
                                      checked={values?.permissions.includes(
                                        resource.value
                                      )}
                                      name="permissions"
                                      value={resource.value}
                                      onChange={(e) => {
                                        const checkedValue = e.target.value;
                                        const updatedPermissions = [
                                          ...values?.permissions,
                                        ];
                                        if (e.target.checked) {
                                          updatedPermissions.push(checkedValue);
                                        } else {
                                          const indexToRemove =
                                            updatedPermissions.indexOf(
                                              checkedValue
                                            );
                                          if (indexToRemove !== -1) {
                                            updatedPermissions.splice(
                                              indexToRemove,
                                              1
                                            );
                                          }
                                        }
                                        setFieldValue(
                                          "permissions",
                                          updatedPermissions
                                        );
                                      }}
                                    />
                                    <span className="checkmark" />
                                    <label>{resource.label}</label>
                                  </label>
                                </div>
                              </div>
                            ))}
                            <ErrorMessage
                              name="permissions"
                              component="span"
                              className="error"
                            />
                          </div>
                          <SwitchToggle
                            setFieldValue={setFieldValue}
                            value={values}
                          />
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Role
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRolePermission;
