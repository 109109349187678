import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { postNodeApi } from "../../../Services/Api/appUtilsApi";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Editor from "../../../Utils/CkEditor";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  validateImage,
  validateName,
  validatedescription,
} from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";
import { customSelectStyles } from "../../../Utils/CSS/select";

const AddNews = () => {
  const navigate = useNavigate();
  const Url =
    /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
  const [editorData, setEditorData] = useState("");
  const { setLoading } = useLoader();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");

  const handleEditorDataChange = (newData) => {
    setEditorData(newData);
  };

  const [image, setImage] = useState("");

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return; // Return if no file is selected

    if (!file.type.startsWith("image/")) {
      // Clear the file input
      e.currentTarget.value = null;
      // Clear the image preview
      setSelectedImage("");
      // Display an error message to the user
      setFileError("Only image files are allowed.");
      return;
    }

    // If an image file is selected, update the image preview and clear any previous error message
    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };

  const typeOfUser = [
    { label: "User", value: "general" },
    { label: "Jatha or Parchaarak", value: "jatha" },
    // { label: "Parchaarak", value: "parchaarak" },
  ];

  const initialValues = {
    name: "",
    description: "",
    url: "",
    image: "",
    expiry_date: new Date(),
    news_for: typeOfUser.map((item) => item.value),
    is_active: true,
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    /*const logoFile = values?.image;
    const formData = new FormData();
    formData.append("image", logoFile);
    formData.append("module", "NEWS");
    let img = "";
    await getImageUrl(formData).then((res) => {
      setLoading(false);
      setImage(res.data.data[0].s3_key);
      img = res.data.data[0].s3_key;
    });*/

    let img = "";
    if (values.image) {
      const logoFile = values.image;
      const formData = new FormData();
      formData.append("image", logoFile);
      formData.append("module", "NEWS");

      // Upload image
      try {
        const res = await getImageUrl(formData);
        setLoading(false);
        img = res.data.data[0].s3_key;
      } catch (error) {
        setLoading(false);
        setSubmitting(false);
        ErrorToast(error?.response?.message || "Failed to upload image");
        return;
      }
    }

    const postData = {
      name: values?.name,
      description: values?.description,
      url: values?.url,
      image: img,
      expiry_date: values?.expiry_date,
      news_for: values?.news_for,
      is_active: values?.is_active,
    };

    await postNodeApi("news", postData)
      .then((res) => {
        setLoading(false);
        if (res?.status === 0) {
          ErrorToast(res?.message || "something went wrong");
        } else {
          SuccessToast(res?.message || "News added successfully.");
          setSubmitting(false);
          navigate("/admin/news-bulletins");
        }
      })
      .catch((err) => {
        setLoading(false);
        if (typeof err?.response?.message !== "undefined") {
          ErrorToast(err?.response?.message || "something went wrong");
        } else {
          ErrorToast(err?.message || "something went wrong");
        }
        setSubmitting(false);
      });
  };

  // useEffect(() => {}, [image]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Add News</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add News</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/news-bulletins">News Bulletin</Link>
                  </li>
                  <li className="breadcrumb-item active">Add News</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/news-bulletins"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">News Information</h4>
                </div>
                <div className="card-body">
                  <Formik
                    // enableReinitialize
                    initialValues={initialValues}
                    validationSchema={Yup.object()
                      .shape({
                        name: validateName,
                        description: Yup.string().nullable(),
                        url: Yup.string()
                          .matches(Url, "Please enter valid url"),
                        image: validateImage,
                        expiry_date: Yup.date().required().label('Expiry date'),
                        news_for: Yup.array()
                          .required("Please select at least one permission")
                          .min(1, "Please select at least one permission"),
                      })
                      .defined()}
                    validateOnChange
                    onSubmit={handleSubmit}
                  >
                    {(formik) => {
                      const {
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        isSubmitting,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>

                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          errors.name && touched.name
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="name"
                                        placeholder="Enter News Title"
                                      />
                                      <ErrorMessage
                                        name="name"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>URL</label>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          errors.url && touched.url
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="url"
                                        placeholder="Enter News URL"
                                      />
                                      <ErrorMessage
                                        name="url"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <label>Description</label>
                                <Editor
                                  name="description"
                                  data={editorData}
                                  onDataChange={(newData) => {
                                    setFieldValue("description", newData);
                                  }}
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Logo</label>
                                <input
                                  type="file"
                                  name="image"
                                  style={customSelectStyles}
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                {selectedImage && (
                                  <img
                                    src={selectedImage}
                                    alt="News Image"
                                    className="news-img-space"
                                  />
                                )}
                                {fileError && (
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Expiry Date{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  autoComplete="off"
                                  className={`form-control ${
                                    errors.expiry_date && touched.expiry_date
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="expiry_date"
                                  onChange={(date) =>
                                    setFieldValue("expiry_date", date)
                                  }
                                  minDate={new Date()}
                                  value={moment(values.expiry_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                                <ErrorMessage
                                  name="expiry_date"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="card-header">
                            <lable>
                              Notification Send To{" "}
                              <span className="text-danger">*</span>
                            </lable>
                          </div>
                          <div className="row mx-3 my-4">
                            {typeOfUser.map((resource, index) => (
                              <div className="col-md-3" key={index}>
                                <div className="form-group">
                                  <label className="custom_check">
                                    <input
                                      type="checkbox"
                                      className={`form-control ${
                                        errors.news_for && touched.news_for
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="news_for"
                                      value={resource?.value}
                                      onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const updatedNewsFor =
                                          values.news_for.includes(
                                            selectedValue
                                          )
                                            ? values.news_for.filter(
                                                (item) => item !== selectedValue
                                              )
                                            : [
                                                ...values.news_for,
                                                selectedValue,
                                              ];

                                        setFieldValue(
                                          "news_for",
                                          updatedNewsFor
                                        );
                                      }}
                                      checked={values.news_for.includes(
                                        resource.value
                                      )}
                                    />
                                    <span className="checkmark" />
                                    <label>{resource?.label}</label>
                                  </label>
                                </div>
                              </div>
                            ))}

                            <ErrorMessage
                              name="news_for"
                              component="span"
                              className="error"
                            />
                          </div>
                          <SwitchToggle
                            setFieldValue={setFieldValue}
                            value={values}
                          />
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add News
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNews;
