import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
// import { userTypeOPtions } from "../../../Utils/constance/options";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { customSelectStyles } from "../../../Utils/CSS/select";
import { getNodeApi, postNodeApi } from "../../../Services/Api/appUtilsApi";
import {
  validateAddress,
  validateCountryId,
  validateEmail,
  validateImage,
  validatePassword,
  validateUrl,
  validatecontact,
} from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import PhonePicker from "../Common/PhonePicker";
// import AddressCommon from "../AddressCommon/";

const AddJathasGianiPriest = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { setLoading } = useLoader();
  const [countryList, setCountryList] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");

  const addUserInitialValues = {
    user_type: "",
    full_name: "",
    email: "",
    country_id: "",
    password: "",
    profile_picture: "",
    contact_number: "",
    whatsapp_contact_number: "",
    facebook_profile: "",
    instagram_profile: "",
    youtube_profile: "",
    other_information: "",
    official_name: "",
    is_active: true,
    // address: "",
  };

  const addUserValidationSchema = Yup.object().shape({
    // user_type: Yup.object().nullable().required("Please select user"),
    full_name: Yup.string().required("Full name is required."),
    contact_number: validatecontact,
    email: validateEmail,
    // address: validateAddress,
    password: validatePassword,
    country_id: validateCountryId,
    profile_picture: validateImage,
    facebook_profile: validateUrl,
    instagram_profile: validateUrl,
    youtube_profile: validateUrl,
  });

  const getCountry = async () => {
    setLoading(true);
    await getNodeApi("../general/get-all-countries").then((res) => {
      setLoading(false);
      let allcountry = res?.data?.countries;
      allcountry = allcountry.map((value) => {
        return {
          label: value?.name,
          value: value?._id,
        };
      });
      setCountryList(allcountry);
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return; // Return if no file is selected

    if (!file.type.startsWith("image/")) {
      // Clear the file input
      e.currentTarget.value = null;
      // Clear the image preview
      setSelectedImage("");
      // Display an error message to the user
      setFileError("Only image files are allowed.");
      return;
    }

    // If an image file is selected, update the image preview and clear any previous error message
    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("profile_picture", file);
    setFileError("");
  };

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Add Jatha or Parchaarak</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Jatha or Parchaarak</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/jathas">Jatha or Parchaarak</Link>
                  </li>
                  <li className="breadcrumb-item active">Add</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/jathas" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Jatha or Parchaarak Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={addUserInitialValues}
                    validationSchema={addUserValidationSchema}
                    innerRef={formRef}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const logoFile = values?.profile_picture;
                      const formData = new FormData();
                      formData.append("image", logoFile);
                      formData.append("module", "PROFILE_PICTURE");
                      let img = "";
                      if (values.profile_picture) {
                        await getImageUrl(formData).then((res) => {
                          img = res.data.data[0].s3_key;
                        });
                      }
                      const postData = {
                        full_name: values?.full_name,
                        email: values?.email,
                        password: values?.password,
                        country_id: values?.country_id.value,
                        user_type: "jatha",
                        profile_picture: img,
                        contact_number: values?.contact_number.toString(),
                        whatsapp_contact_number:
                          values?.whatsapp_contact_number.toString(),
                        official_name: values?.official_name,
                        facebook_profile: values?.facebook_profile,
                        instagram_profile: values?.instagram_profile,
                        youtube_profile: values?.youtube_profile,
                        other_information: values?.other_information,
                        is_active: values?.is_active,
                        // address: values.address,
                      };

                      await postNodeApi("app-users/", postData)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "App user created successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/jathas");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Full Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.full_name && touched.full_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="full_name"
                                  placeholder="Enter full name"
                                />
                                <ErrorMessage
                                  name="full_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              {/* <div className="form-group">
                                <label>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.last_name && touched.last_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="last_name"
                                  placeholder="Enter last name"
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                              <div className="form-group">
                                <label>
                                  Email<span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  autoComplete="off"
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={isShowPassword ? "text" : "password"}
                                    className={`form-control ${
                                      errors.password && touched.password
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="password"
                                    placeholder="Enter password"
                                    autoComplete="off"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    className={`fas ${
                                      isShowPassword ? "fa-eye-slash" : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group">
                                <label>
                                  Main Contact Number
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  className="form-control"
                                  component={PhonePicker}
                                  name="contact_number"
                                  value={values.contact_number}
                                  onChange={(value) =>
                                    setFieldValue("contact_number", value)
                                  }
                                />
                                <ErrorMessage
                                  name="contact_number"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              {/* <div className="form-group">
                                <label>
                                  Main Contact Number
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="number"
                                  className={`form-control ${
                                    errors.contact_number &&
                                    touched.contact_number
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="contact_number"
                                  autoComplete="off"
                                  placeholder="Enter main contact number"
                                />
                                <ErrorMessage
                                  name="contact_number"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                              <div className="form-group">
                                <label>WhatsApp Contact Number</label>
                                <Field
                                  className="form-control"
                                  component={PhonePicker}
                                  name="whatsapp_contact_number"
                                  value={values.whatsapp_contact_number}
                                  onChange={(value) =>
                                    setFieldValue(
                                      "whatsapp_contact_number",
                                      value
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name="whatsapp_contact_number"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              {/* <div className="form-group">
                                <label>WhatsApp Contact Number</label>
                                <Field
                                  type="number"
                                  className={`form-control ${
                                    errors.whatsapp_contact_number &&
                                    touched.whatsapp_contact_number
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="whatsapp_contact_number"
                                  autoComplete="off"
                                  placeholder="Enter whatsapp number"
                                />
                                <ErrorMessage
                                  name="whatsapp_contact_number"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                              <div className="form-group">
                                <label>Profile Picture</label>
                                <input
                                  type="file"
                                  name="profile_picture"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                <ul><li className="list-dics">Resolution must be less than 1000x1000 pixels</li><li className="list-dics">Only 'jpg', 'jpeg', 'png', 'webp' format allow</li></ul>
                                {selectedImage && (
                                  <img
                                    src={selectedImage}
                                    alt="profile_picture"
                                    style={{ width: "auto", height: "80px" }}
                                    className="mx-5 px-5 mt-2"
                                  />
                                )}
                                {fileError && (
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage
                                  name="profile_picture"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Official Name</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.official_name &&
                                    touched.official_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="official_name"
                                  placeholder="Enter official name"
                                />
                                <ErrorMessage
                                  name="official_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Country of Residence
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={countryList}
                                  onChange={(e) => {
                                    setFieldValue("country_id", e);
                                  }}
                                  name="country_id"
                                  placeholder="Select Country Name"
                                  styles={customSelectStyles}
                                />
                                <ErrorMessage
                                  name="country_id"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group">
                                <label>Facebook Url</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.facebook_profile &&
                                    touched.facebook_profile
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="facebook_profile"
                                  autoComplete="off"
                                  placeholder="Enter facebook Url"
                                />
                                <ErrorMessage
                                  name="facebook_profile"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Instagram Url</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.instagram_profile &&
                                    touched.instagram_profile
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="instagram_profile"
                                  autoComplete="off"
                                  placeholder="Enter instagram Url"
                                />
                                <ErrorMessage
                                  name="instagram_profile"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>YouTube Channel Url</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.youtube_profile &&
                                    touched.youtube_profile
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="youtube_profile"
                                  autoComplete="off"
                                  placeholder="Enter youtube channel Url"
                                />
                                <ErrorMessage
                                  name="youtube_profile"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Other Details</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.other_information &&
                                    touched.other_information
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="other_information"
                                  autoComplete="off"
                                  placeholder="Enter other details"
                                />
                                <ErrorMessage
                                  name="other_information"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              {/* <div className="form-group">
                                <label>
                                  User Type
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={userTypeOPtions}
                                  onChange={(e) => {
                                    setFieldValue("user_type", e);
                                  }}
                                  name="user_type"
                                  placeholder="Select User Type"
                                  autoComplete="off"
                                  styles={customSelectStyles}
                                />
                                <ErrorMessage
                                  name="user_type"
                                  component="span"
                                  className="error"
                                />
                              </div> */}

                              {/* <AddressCommon
                                setFieldValue={setFieldValue}
                                values={values}
                                onChange={(e) => {
                                  setFieldValue("address", e);
                                }}
                              /> */}
                            </div>
                            <SwitchToggle
                              setFieldValue={setFieldValue}
                              value={values}
                            />
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddJathasGianiPriest;
