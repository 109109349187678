import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useLoader } from "../../../Hooks/LoaderContext";

const NewsDetails = () => {
  const { id } = useParams();
  const [newsBulletins, setNewsBulletins] = useState([]);
  const { setLoading } = useLoader();

  const GetNewsBulletins = async () => {
    setLoading(true);
    await getNodeApi(`news/${id}`)
      .then((res) => {
        setLoading(false);
        if (res) {
          setNewsBulletins(res?.data);
        } else {
          setNewsBulletins([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNewsBulletins([]);
      });
  };

  useEffect(() => {
    GetNewsBulletins();
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | News Details</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">News Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/news-bulletins">News Bulletin</Link>
                  </li>
                  <li className="breadcrumb-item active">News Details</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/news-bulletins"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">News Details</h4>
                </div>
                <div className="details-main other-class">
                  <div className="card-body details-sub-main">
                    <h5>Name</h5>
                    <div>{newsBulletins?.name}</div>
                  </div>
                  <div className="card-body details-sub-main">
                    <h5>Url</h5>
                    <div
                      className="link-color-news"
                      onClick={() => {
                        window.location.href = newsBulletins?.url;
                      }}
                    >
                      {newsBulletins?.url}
                    </div>
                  </div>
                </div>
                <div className="details-main other-class">
                  <div className="card-body details-sub-main">
                    <div>
                      <h5>Description</h5>
                      {newsBulletins?.description && (
                        <div
                          className="description-height"
                          dangerouslySetInnerHTML={{
                            __html: newsBulletins?.description,
                          }}
                        />
                      )}
                    </div>
                    <div className="news-details">
                      <h5>News</h5>
                      <div>
                        {newsBulletins?.news_for?.length === 0
                          ? "-"
                          : newsBulletins?.news_for?.map((item, index) => {
                              return (
                                <div key={index}>
                                  {item.toUpperCase()}
                                  <br />
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  </div>
                  <div className="card-body details-sub-main">
                    <h5>Image</h5>
                    <div className="details-image">
                      <img
                        src={`${process.env.REACT_APP_API_IMAGE_URL}${newsBulletins?.image}`}
                        alt="pic"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetails;
