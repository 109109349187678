import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../../Utils/SweetAlert";
import usePermission from "../../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../../Hooks/LoaderContext";
import NameInitial from "../../../../Utils/NameInitial";
import Modal from "react-bootstrap/Modal";
import InfoIcon from "@mui/icons-material/Info";
import Button from "react-bootstrap/Button";
import StatusButton from "../../../../Utils/StatusButton";

const OnbordingList = () => {
  const { hasPermission, role } = usePermission();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchOnbording, setSearchOnbording] = useState("");
  const [onbordingData, setOnbordingData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoader();

  const GetOnbordingList = async () => {
    setLoading(true);
    await getNodeApi(
      `onboardings?offset=${page}&limit=${limit}&search=${searchOnbording}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.totalRecords > 0) {
          setOnbordingData(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setOnbordingData([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setOnbordingData([]);
        setTotalRecords(0);
        throw err;
      });
  };

  const openDescriptionModal = (onboarding) => {
    setSelectedDescription(onboarding.description);
    setModalTitle(onboarding.title);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedDescription("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchOnbording(searchValue);
    } else {
      setSearchOnbording("");
    }
  };

  const handleOnbordingStatusChange = async (postObj) => {
    try {
      setLoading(true);
      const res = await putNodeApi("onboardings/change-status", postObj);
      setLoading(false);
      GetOnbordingList(limit, page);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetOnbordingList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetOnbordingList(parseInt(event.target.value, 10), 0, "");
  };

  const deleteOnbordingById = (id) => {
    Confirmation("Want to delete onbording?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`onboardings/`, { id })
          .then((res) => {
            SuccessToast(res?.message || "Onbording Deleted.");
            GetOnbordingList(limit, page, "");
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetOnbordingList(limit, page, searchOnbording);
  }, [limit, page, searchOnbording]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Onbording</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Onbording</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Onbording</li>
                </ul>
              </div>
              {role == RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link
                    to="/admin/add-onbording"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add Onbording
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.ONBORDINGADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-onbording"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add Onbording
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search Onbording"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Image</th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.ONBORDINGEDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}

                          {role == RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.ONBORDINGEDIT ||
                                  hasPermission(RolePermission.ONBORDINGDELETE)
                              ) && <th className="text-center">Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {onbordingData.map((onbording, index) => (
                              <tr key={index}>
                                <td>{onbording.title || "-"}</td>
                                <td>
                                  {onbording?.description &&
                                  onbording?.description?.length > 50 ? (
                                    <>
                                      <span>
                                        {truncateText(
                                          onbording.description,
                                          20
                                        )}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openDescriptionModal(
                                            onbording
                                          )
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    onbording?.description || "-"
                                  )}
                                </td>
                                <td>
                                  {onbording.image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_IMAGE_URL}${onbording?.image}`}
                                      alt="img"
                                      className="list-image-news"
                                    />
                                  ) : (
                                    <NameInitial
                                      name={onbording.title}
                                      type="event"
                                    />
                                  )}
                                </td>
                                {role == RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="onbording"
                                    role={onbording}
                                    changeActive={handleOnbordingStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(
                                      RolePermission.ONBORDINGEDIT
                                    ) && (
                                      <StatusButton
                                        module="onbording"
                                        role={onbording}
                                        changeActive={handleOnbordingStatusChange}
                                      />
                                    )}
                                  </>
                                )}

                                <td>
                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-onbording/${onbording?._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ONBORDINGEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-onbording/${onbording?._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() => {
                                        deleteOnbordingById(onbording?._id);
                                      }}
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ONBORDINGDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() => {
                                            deleteOnbordingById(onbording?._id);
                                          }}
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {/* <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate(
                                        `/admin/edit-onbording/${onbording?._id}`
                                      )
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() =>
                                      deleteOnbordingById(onbording?._id)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                </td>
                              </tr>
                            ))}
                            {onbordingData.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{selectedDescription}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnbordingList;
