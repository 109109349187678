import { RolePermission } from "../../../Utils/constance/options";
import usePermission from "../../../Hooks/useHasPermisson";
import StatusButton from "../../../Utils/StatusButton";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";
import NameInitial from "../../../Utils/NameInitial";
import moment from "moment";

const ListNews = () => {
  const navigate = useNavigate();
  const { hasPermission, role } = usePermission();
  const [newsBulletins, setNewsBulletins] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limits, setLimits] = useState(10);
  const [page, setPage] = useState(0);
  const [searchNews, setSearchNews] = useState("");
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  const GetNewsBulletins = async () => {
    setLoading(true);
    await getNodeApi(`news?offset=${page}&limit=${limits}&search=${searchNews}`)
      .then((response) => {
        setLoading(false);
        if (response) {
          setNewsBulletins(response?.data);
          setTotalRecords(response?.totalRecords);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error", error);
      });
  };

  const handlePageChange = async (pageNew) => {
    setPage(pageNew);
    await GetNewsBulletins(limits, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimits(parseInt(event.target.value, 10));
    setPage(0);
    await GetNewsBulletins(parseInt(event.target.value, 10), 0);
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchNews(searchValue);
    } else {
      setSearchNews("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchNews(searchValue);
      } else {
        setSearchNews("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const changeStatus = async (postObj) => {
    try {
      const res = await putNodeApi("news/change-status", postObj);
      GetNewsBulletins(limits, page);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const deleteNewsBulletin = (id) => {
    Confirmation("Want to delete news?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`news/`, { id })
          .then((res) => {
            SuccessToast(res?.message || "News Deleted.");
            GetNewsBulletins(limits, page);
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetNewsBulletins(limits, searchNews, page);
  }, [limits, searchNews, page]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | News Bulletins</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">News Bulletins</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">News Bulletins</li>
                </ul>
              </div>
              {role == RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link to="/admin/add-news" className="btn btn-primary me-1">
                    <i className="fas fa-plus" /> Add News Bulletin
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.NEWSADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-news"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add News Bulletin
                      </Link>
                    </div>
                  )}
                </>
              )}
              <div className="form-custom">
                <input
                  type="text"
                  className="form-control"
                  id="role_search"
                  placeholder="Search News"
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th className="set-width-th">Name</th>
                          <th className="set-width-th">Url</th>
                          <th>Expiry Date</th>
                          <th>Image</th>
                          <th>News </th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.NEWSEDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {newsBulletins.map((news) => (
                              <tr key={news._id}>
                                <td className="news-name-table">
                                  {news?.name}
                                </td>
                                <td
                                  className="new-url-table link-color-news"
                                  onClick={() => {
                                    window.location.href = news?.url;
                                  }}
                                >
                                  {news?.url}
                                </td>
                                <td>
                                  {news?.expiry_date ? moment(news?.expiry_date).format('DD-MM-YYYY') : "-"}
                                </td>
                                <td>
                                  {news.image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_IMAGE_URL}${news?.image}`}
                                      alt="img"
                                      className="list-image-news"
                                    />
                                  ) : (
                                    <NameInitial
                                      name={news.name}
                                      type="event"
                                    />
                                  )}
                                </td>
                                <td>
                                  {news?.news_for?.length === 0
                                    ? "-"
                                    : news?.news_for.map((item, index) => {
                                        return (
                                          <div key={index}>
                                            {item.toUpperCase()}
                                            <br />
                                          </div>
                                        );
                                      })}
                                </td>
                                {role == RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="Gurudwara"
                                    role={news}
                                    changeActive={changeStatus}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(RolePermission.NEWSEDIT) && (
                                      <StatusButton
                                        module="Gurudwara"
                                        role={news}
                                        changeActive={changeStatus}
                                      />
                                    )}
                                  </>
                                )}

                                <td>
                                  <Tooltip
                                    title="Details"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate(
                                        `/admin/details-news/${news?._id}`
                                      )
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>

                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-news/${news?._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.NEWSEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-news/${news?._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() =>
                                        deleteNewsBulletin(news?._id)
                                      }
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.NEWSDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() =>
                                            deleteNewsBulletin(news?._id)
                                          }
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {newsBulletins.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limits}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListNews;
