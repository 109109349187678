import { postNodeApi } from "../../../Services/Api/appUtilsApi";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { useLoader } from "../../../Hooks/LoaderContext";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import { validateImage, validateTitle } from "../../../Utils/validation";

const AddOtherApps = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  const { setLoading } = useLoader();

  const InitialValues = {
    name: "",
    image: "",
    appstore_url: "",
    playstore_url: "",
  };

  const ValidationSchema = Yup.object().shape(
    {
      name: validateTitle,
      // image: Yup.string().nullable().required("Please upload image"),
      image: validateImage,
      appstore_url: Yup.string().when("playstore_url", {
        is: (playstoreUrl) => !playstoreUrl,
        then: Yup.string()
          .matches(
            /^(https?:\/\/)?(www\.)?apps\.apple\.com\/.*$/,
            "Enter correct url for App Store!"
          )
          .required("Please provide either App Store URL or Play Store URL"),
        otherwise: Yup.string().matches(
          /^(https?:\/\/)?(www\.)?apps\.apple\.com\/.*$/,
          "Enter correct url for App Store!"
        ),
      }),
      playstore_url: Yup.string().when("appstore_url", {
        is: (appstoreUrl) => !appstoreUrl,
        then: Yup.string()
          .matches(
            /^(https?:\/\/)?(www\.)?play\.google\.com\/store\/apps\/.*$/,
            "Enter correct url for Play Store!"
          )
          .required("Please provide either Play Store URL or App Store URL"),
        otherwise: Yup.string().matches(
          /^(https?:\/\/)?(www\.)?play\.google\.com\/store\/apps\/.*$/,
          "Enter correct url for Play Store!"
        ),
      }),
    },
    [["appstore_url", "playstore_url"]]
  );

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return; // Return if no file is selected

    if (!file.type.startsWith("image/")) {
      // Clear the file input
      e.currentTarget.value = null;
      // Clear the image preview
      setSelectedImage("");
      // Display an error message to the user
      setFileError("Only image files are allowed.");
      return;
    }

    // If an image file is selected, update the image preview and clear any previous error message
    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };
  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Other App Add</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Other App</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/otherapps-list">Other App</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Other App</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/otherapps-list"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Other App Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={InitialValues}
                    validationSchema={ValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const logoFile = values?.image;
                      const formData = new FormData();
                      formData.append("image", logoFile);
                      formData.append("module", "OTHER_APPS");
                      let img = "";
                      if (values.image) {
                        await getImageUrl(formData).then((res) => {
                          img = res.data.data[0].s3_key;
                        });
                      }
                      const data = {
                        name: values.name,
                        image: img,
                        appstore_url: values?.appstore_url,
                        playstore_url: values?.playstore_url,
                      };
                      await postNodeApi("otherapp/", data)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Other App created successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/otherapps-list");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting, setFieldValue } =
                        formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter App Name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>App Store Url</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.appstore_url && touched.appstore_url
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="appstore_url"
                                  placeholder="Enter App Store Url"
                                />
                                <ErrorMessage
                                  name="appstore_url"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Play Store Url</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.playstore_url &&
                                    touched.playstore_url
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="playstore_url"
                                  placeholder="Enter Play Store Url"
                                />
                                <ErrorMessage
                                  name="playstore_url"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group">
                                <label>
                                  Upload Icon Image{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                {selectedImage && (
                                  <img
                                    src={selectedImage}
                                    alt="otherapp-img"
                                    style={{ width: "auto", height: "80px" }}
                                    className="mx-5 px-5 mt-2"
                                  />
                                )}
                                {fileError && ( // Display error message if fileError is not empty
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage // Include ErrorMessage component
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Other App
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOtherApps;
