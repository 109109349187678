import React, { createContext, useContext, useReducer } from "react";

// Action Types
const SET_LOADING = "SET_LOADING";

// Initial State
const initialState = false;

// Reducer
const loaderReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return action.payload; // true or false
    default:
      return state;
  }
};

// Context
const LoaderContext = createContext();

// Loader Provider
export const LoaderProvider = ({ children }) => {
  const [isLoading, dispatch] = useReducer(loaderReducer, initialState);

  const setLoading = (value) => {
    dispatch({ type: SET_LOADING, payload: value });
  };

  return (
    <LoaderContext.Provider value={{ isLoading, setLoading }}>
      {children}
    </LoaderContext.Provider>
  );
};

// Custom Hook
export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};
