import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { getNodeApi, postNodeApi } from "../../../Services/Api/appUtilsApi";
import {
  validateAddress,
  validateCountryId,
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePassword,
} from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";
import AddressCommon from "../AddressCommon";
import Select from "react-select";
import { customSelectStyles } from "../../../Utils/CSS/select";

const AddNormalUser = () => {
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { setLoading } = useLoader();
  const [countryList, setCountryList] = useState([]);

  const addUserInitialValues = {
    user_type: "",
    full_name: "",
    last_name: "",
    email: "",
    password: "",
    country_id: "",
    // address: "",
    is_active: true,
  };

  const addUserValidationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required."),
    // last_name: validateLastName,
    email: validateEmail,
    country_id: validateCountryId,
    // address: validateAddress,
    password: validatePassword,
  });

  const getCountry = async () => {
    setLoading(true);
    await getNodeApi("../general/get-all-countries").then((res) => {
      setLoading(false);
      let allcountry = res?.data?.countries;
      allcountry = allcountry.map((value) => {
        return {
          label: value.name,
          value: value._id,
        };
      });
      setCountryList(allcountry);
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Add Normal User </title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">User</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/normal-user">User</Link>
                  </li>
                  <li className="breadcrumb-item active">Add User</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/normal-user" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Normal User Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={addUserInitialValues}
                    validationSchema={addUserValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const postData = {
                        full_name: values.full_name,
                        // last_name: values.last_name,
                        email: values.email,
                        password: values.password,
                        user_type: "general",
                        // address: values.address,
                        country_id: values.country_id.value,
                        is_active: values.is_active,
                      };
                      await postNodeApi("app-users/", postData)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message ||
                                "Normal user created successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/normal-user");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err.response.message !== "undefined") {
                            ErrorToast(
                              err.response.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Full Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.full_name && touched.full_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="full_name"
                                  placeholder="Enter full name"
                                />
                                <ErrorMessage
                                  name="full_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              {/* <div className="form-group">
                                <label>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.last_name && touched.last_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="last_name"
                                  placeholder="Enter last name"
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={isShowPassword ? "text" : "password"}
                                    className={`form-control ${
                                      errors.password && touched.password
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="password"
                                    placeholder="Enter password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    className={`fas ${
                                      isShowPassword ? "fa-eye-slash" : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Country Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={countryList}
                                  onChange={(e) => {
                                    setFieldValue("country_id", e);
                                  }}
                                  value={values.country_id}
                                  name="country_id"
                                  placeholder="Select Country Name"
                                  styles={customSelectStyles}
                                />
                                <ErrorMessage
                                  name="country_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              {/* <AddressCommon
                                isrequired
                                setFieldValue={setFieldValue}
                                values={values}
                                onChange={(e) => {
                                  setFieldValue("address", e);
                                }}
                              /> */}
                            </div>
                            <SwitchToggle
                              setFieldValue={setFieldValue}
                              value={values}
                            />
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add User
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNormalUser;
