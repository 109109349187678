import Api from "../../Utils/Axios";

export const PostCustomer = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/admin/customer', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const PostCustomerCreditRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/admin/credit_request/change_status', data));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetCustomerPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/customer?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetCustomerCreditRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/credit_request/admin/customer_credit_request?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetCustomerById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/customer/details/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const PutCustomer = async ({ id, data }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put(`/admin/customer/${id}`, data));
    } catch (error) {
      reject(error);
    }
  })
}
export const DeleteCustomer = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/admin/customer/${id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const StatusCustomer = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/customer/active_inactive", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const BlockCustomer = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/customer/block_unblock", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const ApproveRejectDocumentService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/file/change_status", data));
    } catch (error) {
      reject(error);
    }
  });
};
/* 13-07-2022 */
export const GetCustomerWalletRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/admin/customer_request?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetCustomerWalletRequestCardPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/payby/card_deposits?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const ApproveRejectCustomerWalletRequest = async (transaction_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/wallet/admin/approve_reject_request', transaction_id));
    } catch (error) {
      reject(error);
    }
  })
}

export const ApproveRefundCustomerWalletRequest = async (payment_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/payby/admin/refund_order', payment_id));
    } catch (error) {
      reject(error);
    }
  })
}

export const DeleteCustomerWalletRequest = async (transaction_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete('/master/wallet/admin/customer_request/' + transaction_id));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetMerchantWalletRequestPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/admin/list_deposits?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const ApproveRejectCustomerCreditRequest = async (request_Id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post('/master/credit_request/admin/approve_reject_request', request_Id));
    } catch (error) {
      reject(error);
    }
  })
}
export const DeleteCustomerCreditRequest = async (request_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete('/master/credit_request/admin/customer_credit_request/' + request_id));
    } catch (error) {
      reject(error);
    }
  })
}
export const GetMerchantWalletLogHistoryPaginate = async ({ limit, page, id }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/admin/deposit_actions_logs?offset=${page}&limit=${limit}&transaction_id=${id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetStatementPaginate = async ({ limit, page, user_id }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/customer/statements?offset=${page}&limit=${limit}&user_id=${user_id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetStatementPaginateFilter = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/master/wallet/customer/statements",data));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetTransactionExportFile = async (type, user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet/export_statements?type=${type}&user_id=${user_id}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetTransactionExportFileFilter = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("master/wallet/export_statements",data));
    } catch (error) {
      reject(error);
    }
  })
}

export const ClientGetWalletBalanceService = async (user_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/master/wallet?user_id=${user_id}`));
    } catch (error) {
      reject(error);
    }
  })
}
export const ServiceChargeListService = async ({customer_id,page,limit}) =>{
  return new Promise(async (resolve,reject)=>{
    try{
      resolve(await Api.get(`/admin/customer/service_charge?customer_id=${customer_id}&offset=${page}&limit=${limit}`));
    }catch(error){
      reject(error);
    }
  })
}

export const AddServiceChargeService = async (data) =>{
  return new Promise(async (resolve,reject) =>{
    try{
      resolve(await Api.post("/admin/customer/add_service_charge",data));
    }catch(error){
      reject(error);
    }
  })
} 
export const GetServiceChargeEditDataService = async (id) =>{
  return new Promise(async (resolve,reject) => {
    try{
      resolve(await Api.get(`/admin/customer/service_charge_details/${id}`));
    }catch(error){
      reject(error)
    }
  })
}
export const EditServiceChargeService = async (data) =>{
  return new Promise(async (resolve,reject)=>{
    try{
      resolve(await Api.post("/admin/customer/update_service_charge",data));
    }catch(error){
      reject(error);
    }
  })
}

export const getCustomerGenerateReport = async (data) =>{
  return new Promise(async (resolve,reject)=>{
    try{
      resolve(await Api.post("/admin/customer/generate_report",data));
    }catch(error){
      reject(error);
    }
  })
}

export const GetCustomerUserPaginate = async ({ limit, page, paginate = true }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/customer?offset=${page}&limit=${limit}&paginate=${paginate}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetExpiredDocumentUserPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/customer/expired_docs?offset=${page}&limit=${limit}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const GetCustomerCreditLine = async ({ limit, page, status }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/customer/credit_line?offset=${page}&limit=${limit}&status=${status}`));
    } catch (error) {
      reject(error);
    }
  })
}

export const closeCreditRequest = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/admin/customer/close_customer_credit`, data));
    } catch (error) {
      reject(error);
    }
  })
}