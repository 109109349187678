import { useEffect, useState } from "react";

const usePermission = () => {
  const [permissions, setPermissions] = useState([]);
  const role = JSON.parse(localStorage.getItem("userType"));
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const getPermissions = () => {
      const storedPermissions = JSON.parse(
        localStorage.getItem("adminPermission")
      );
      setPermissions(storedPermissions || []);
    };
    getPermissions();
  }, []);

  const hasPermission = (permissionKey) => {
    return permissions.includes(permissionKey);
  };

  return { role, hasPermission, token };
};

export default usePermission;
