import { BrowserRouter as Router } from "react-router-dom";
import Routings from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { LoaderProvider } from "./Hooks/LoaderContext";
import Loader from "./Utils/Loader";
function App() {
  // const { isLoading } = useLoader();

  return (
    <div className="App">
      <HelmetProvider>
        <LoaderProvider>
          {/* {isLoading && <div className="loader">Loading...</div>} */}
          <Router>
            <Routings />
          </Router>
          <Loader />
        </LoaderProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
