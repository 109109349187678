import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { PostCustomer } from "../../../Services/Api/customer";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { GetCountry, GetCRPenaltySetting } from "../../../Services/Api/master";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import YupPassword from "yup-password";
import { Helmet } from "react-helmet-async";
import DragAndDropFile from "../../../Utils/DragAndDropFile";
import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import { useDebounce } from "use-debounce";
import { validateEmail, validateName } from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";

YupPassword(Yup);
const AddCustomer = () => {
  const fileTypes = ["image/*", ".pdf", ".docx", ".doc", ".pptx"];
  const [imageKey, setImageKey] = useState(0);
  const [debounceKey] = useDebounce(imageKey, 1000);
  const { setLoading } = useLoader();

  const paneltyPeriod = [
    { label: "Daily", value: "DAILY" },
    { label: "Weekly", value: "WEEKLY" },
    { label: "Fort Night", value: "FORT_NIGHT" },
  ];

  let dfltFormData = {
    profile_picture: "",
    vatNumber: "",
    email: "",
    name: "",
    compnayName: "",
    currency: "",
    pName: "",
    state: "",
    address: "",
    city: "",
    bphoneNumber: "",
    country: "",
    zipcode: "",
    tphoneNumber: "",
    mobileNumber: "",
    password: "",
    confirm_password: "",
    cr_due_days: "",
    cr_penalty_start_days: "",
    cr_penalty_amount: "",
    cr_penalty_period: "",
  };
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [country, setCountry] = useState([]);
  const [initData, setInitData] = useState(dfltFormData);
  const [id, setId] = useState();
  const [dfltCr, setDfltCr] = useState(null);
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

  const getCountryData = async () => {
    setLoading(true);
    await GetCountry().then((res) => {
      setLoading(false);
      const resCountryData = res?.data?.data || [];
      const countryFilterData = resCountryData.map((country) => {
        return {
          label: country?.name,
          value: country?.name,
        };
      });
      setCountry(countryFilterData);
    });
    await GetCRPenaltySetting().then((res) => {
      setLoading(false);
      const dfltDataCRP = res?.data?.data?.creditReqPenalty || null;
      setInitData({
        ...initData,
        cr_due_days: dfltDataCRP.cr_due_days,
        cr_penalty_start_days: dfltDataCRP.cr_penalty_start_days,
        cr_penalty_amount: dfltDataCRP.cr_penalty_amount,
        cr_penalty_period: dfltDataCRP.cr_penalty_period,
      });
      setDfltCr(dfltDataCRP);
    });
  };
  useEffect(() => {
    let items = JSON.parse(localStorage.getItem("userData"));
    setId(items._id);
    getCountryData();
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Add Customer</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Customers</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/customer">Customers</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Customer</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/customer" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initData}
                    validationSchema={Yup.object().shape({
                      email: validateEmail,
                      name: validateName,
                      vatNumber: Yup.string().required(
                        "VAT number is required"
                      ),
                      compnayName: Yup.string().required(
                        "Compnay name is required"
                      ),
                      mobileNumber: Yup.string()
                        .matches(phoneRegExp, "Mobile number is not valid")
                        .required("Mobile number is required"),
                      tphoneNumber: Yup.string()
                        .matches(phoneRegExp, "Telephone number is not valid")
                        .required("Telephone number is required"),
                      password: Yup.string()
                        .password()
                        .required("Password is required"),
                      confirm_password: Yup.string()
                        .oneOf(
                          [Yup.ref("password"), null],
                          "Passwords must match"
                        )
                        .required("Confirm password is required"),
                      pName: Yup.string().required(
                        "Primary contact name is required"
                      ),
                      state: Yup.string().required("State is required"),
                      address: Yup.string().required("Address is required"),
                      city: Yup.string().required("City is required"),
                      bphoneNumber: Yup.string()
                        .matches(phoneRegExp, "Mobile number is not valid")
                        .required("Mobile number is required"),
                      country: Yup.object().required("Please select a country"),
                      zipcode: Yup.string().required(
                        "PO box number / Pin code is required"
                      ),
                      cr_due_days: Yup.number()
                        .positive("Must be greater then 0")
                        .nullable(true)
                        .typeError("Please enter number only"),
                      cr_penalty_start_days: Yup.number()
                        .nullable(true)
                        .typeError("Please enter number only"),
                      cr_penalty_amount: Yup.number()
                        .positive("Must be greater then 0")
                        .nullable(true)
                        .typeError("Please enter number only"),
                      cr_penalty_period: Yup.object().nullable(true),
                      profile_picture: Yup.mixed().required(
                        "Profile picture is required"
                      ),
                    })}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postObj = {
                        name: values.name,
                        authorized_person_name: values.pName,
                        email: values.email,
                        company_name: values.compnayName,
                        telephone_number: values.tphoneNumber,
                        mobile: values.mobileNumber,
                        profile_picture: values.profile_picture,
                        password: values.password,
                        vat_number: values.vatNumber,
                        billing_address: {
                          name: values.name,
                          address: values.address,
                          phone: values.bphoneNumber,
                          country: values.country.value,
                          state: values.state,
                          city: values.city,
                          zip_code: values.zipcode.toString(),
                        },
                        cr_due_days: values.cr_due_days,
                        cr_penalty_start_days: values.cr_penalty_start_days,
                        cr_penalty_period: values.cr_penalty_period,
                        cr_penalty_amount: values.cr_penalty_amount,
                      };
                      await PostCustomer(postObj)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Customer Created."
                          );
                          setSubmitting(false);
                          navigate("/admin/customer");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  value={values.name}
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  placeholder="Enter email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  value={values.email}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Telephone Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  placeholder="Enter telephone number"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.tphoneNumber && touched.tphoneNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="tphoneNumber"
                                  value={values.tphoneNumber}
                                />
                                <ErrorMessage
                                  name="tphoneNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>
                                  Mobile Number (Country Code){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.mobileNumber}
                                  onChange={(newVal) => {
                                    setFieldValue("mobileNumber", newVal);
                                  }}
                                  className={`form-control ${
                                    errors.mobileNumber && touched.mobileNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="mobileNumber"
                                />
                                <ErrorMessage
                                  name="mobileNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group dropzone-image-area">
                                <label className="image-label">
                                  Profile Picture{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DragAndDropFile
                                  randerKey={debounceKey}
                                  maxFileSize={2000000} //2mb
                                  label="Drag and drop a image or click"
                                  fileTypes={[
                                    "image/*",
                                    ".pdf",
                                    ".docx",
                                    ".doc",
                                    ".pptx",
                                  ]}
                                  className={`form-control dropzoneBox ${
                                    errors.profile_picture &&
                                    touched.profile_picture
                                      ? "input-error"
                                      : null
                                  }`}
                                  handleChange={async (file) => {
                                    setFieldValue("profile_picture", file[0]);
                                    let imageData = file[0];
                                    const imagePostData = {
                                      user_id: id,
                                      file_name: imageData.name || "",
                                      file_module: "PROFILE_PICTURE",
                                      mime_type: imageData.type,
                                      document_sides: "NONE",
                                    };
                                    await fileUploadToBucket({
                                      data: imagePostData,
                                      file: imageData,
                                    })
                                      .then(async (res) => {
                                        setFieldValue(
                                          "profile_picture",
                                          res?.data?.data?.s3_key
                                        );
                                      })
                                      .catch((err) => {
                                        // if (
                                        //   typeof err.response.data.message !== "undefined"
                                        // ) {
                                        //   ErrorToast(
                                        //     err.response.data.message || "Server Error!!"
                                        //   );
                                        // } else {
                                        //   ErrorToast(err?.message || "Server Error!!");
                                        // }
                                      });
                                  }}
                                  acceptedFiles={fileTypes}
                                />
                                <ErrorMessage
                                  name="profile_picture"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Primary Contact Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.pName && touched.pName
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="pName"
                                  value={values.pName}
                                  placeholder="Enter primary contact name"
                                />
                                <ErrorMessage
                                  name="pName"
                                  component="span"
                                  className="error"
                                />
                              </div>

                              <div className="form-group">
                                <label>
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={isShowPassword ? "text" : "password"}
                                    className={`form-control ${
                                      errors.password && touched.password
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="password"
                                    placeholder="Enter password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    className={`fas ${
                                      isShowPassword ? "fa-eye-slash" : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Confirm Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={
                                      isShowConfirmPassword
                                        ? "text"
                                        : "password"
                                    }
                                    className={`form-control ${
                                      errors.confirm_password &&
                                      touched.confirm_password
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="confirm_password"
                                    placeholder="Confirm password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowConfirmPassword(
                                        !isShowConfirmPassword
                                      )
                                    }
                                    className={`fas ${
                                      isShowConfirmPassword
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="confirm_password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Compnay Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.compnayName && touched.compnayName
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="compnayName"
                                  value={values.compnayName}
                                  placeholder="Enter company name"
                                />
                                <ErrorMessage
                                  name="compnayName"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  VAT Number{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.vatNumber && touched.vatNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="vatNumber"
                                  value={values.vatNumber}
                                  placeholder="Enter VAT number"
                                />
                                <ErrorMessage
                                  name="vatNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <h4 className="card-title mt-4">Billing Address</h4>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  City <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.city && touched.city
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="city"
                                  value={values.city}
                                  placeholder="Enter city"
                                />
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Country <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={country}
                                  onChange={(newVal) => {
                                    setFieldValue("country", newVal);
                                  }}
                                  name="country"
                                  value={values.country}
                                  placeholder="Enter country"
                                />
                                <ErrorMessage
                                  name="country"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Address <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  rows={3}
                                  cols={5}
                                  type="text"
                                  className={`form-control ${
                                    errors.address && touched.address
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="address"
                                  onChange={handleChange}
                                  value={values.address}
                                  placeholder="Enter address"
                                />
                                <ErrorMessage
                                  name="address"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  State <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.state && touched.state
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="state"
                                  onChange={handleChange}
                                  placeholder="Enter state"
                                  value={values.state}
                                />
                                <ErrorMessage
                                  name="state"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  PO box number / Pin code{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.zipcode && touched.zipcode
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="zipcode"
                                  onChange={handleChange}
                                  placeholder="Enter PO box number / pin code"
                                  value={values.zipcode}
                                />
                                <ErrorMessage
                                  name="zipcode"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>
                                  Mobile Number (Country Code){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <PhoneInput
                                  international
                                  countryCallingCodeEditable={false}
                                  defaultCountry="AE"
                                  type="text"
                                  value={values.bphoneNumber}
                                  onChange={(newVal) => {
                                    setFieldValue("bphoneNumber", newVal);
                                  }}
                                  className={`form-control ${
                                    errors.bphoneNumber && touched.bphoneNumber
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="bphoneNumber"
                                />
                                <ErrorMessage
                                  name="bphoneNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <h4 className="card-title mt-4">
                            Credit Request Penalty Setting
                          </h4>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Due Days</label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_due_days && touched.cr_due_days
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_due_days"
                                  placeholder="Enter due days"
                                  value={values.cr_due_days}
                                />
                                <ErrorMessage
                                  name="cr_due_days"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Penalty Start Days</label>
                                <Field
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_penalty_start_days &&
                                    touched.cr_penalty_start_days
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_penalty_start_days"
                                  placeholder="Enter penalty start days"
                                  value={values.cr_penalty_start_days}
                                />
                                <ErrorMessage
                                  name="cr_penalty_start_days"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                            <div className="form-group col-md-3">
                              <label>Penalty Period</label>
                              <Select
                                options={paneltyPeriod}
                                placeholder="Select panalty period"
                                onChange={(e) => {
                                  setFieldValue("cr_penalty_period", e.value);
                                }}
                                name="cr_penalty_period"
                                value={paneltyPeriod.filter(
                                  (option) =>
                                    option.value === values.cr_penalty_period
                                )}
                              />
                              <ErrorMessage
                                name="cr_penalty_period"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>Penalty Amount</label>
                                <Field
                                  placeholder="Enter penalty amount"
                                  type="text"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.cr_penalty_amount &&
                                    touched.cr_penalty_amount
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="cr_penalty_amount"
                                  value={values.cr_penalty_amount}
                                />
                                <ErrorMessage
                                  name="cr_penalty_amount"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            {/* col-md-4 */}
                          </div>
                          {/* 12-07-2022 */}
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Customer
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddCustomer;
