import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";
import NameInitial from "../../../Utils/NameInitial";
import Modal from "react-bootstrap/Modal";
import InfoIcon from "@mui/icons-material/Info";
import Button from "react-bootstrap/Button";
import StatusButton from "../../../Utils/StatusButton";

const SponserList = () => {
  const { hasPermission, role } = usePermission();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchSponser, setSearchSponser] = useState("");
  const [sponserData, setSponserData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedWebsite, setSelectedWebsite] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  const GetSponserList = async () => {
    setLoading(true);
    await getNodeApi(
      `sponsors?offset=${page}&limit=${limit}&search=${searchSponser}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.totalRecords > 0) {
          setSponserData(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setSponserData([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setSponserData([]);
        setTotalRecords(0);
        throw err;
      });
  };

  const openDescriptionModal = (description) => {
    setSelectedDescription(description);
    setModalTitle("Sponsor Description");
    setShow(true);
  };

  const openWebsiteModal = (website) => {
    setSelectedWebsite(website);
    setModalTitle("Sponsor Website");
    setShow(true);
  };

  const closeModal = () => {
    setSelectedDescription("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchSponser(searchValue);
      } else {
        setSearchSponser("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchSponser(searchValue);
    } else {
      setSearchSponser("");
    }
  }; */

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetSponserList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetSponserList(parseInt(event.target.value, 10), 0, "");
  };

  const handleSponserStatusChange = async (postObj) => {
    try {
      const res = await putNodeApi("sponsors/change-status", postObj);
      GetSponserList(limit, page);
      return res;
    } catch (err) {
      throw err;
    }
  };

 /* const handleStatusChange = (statusText, id) => {
    Confirmation(statusText).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          id: id,
          is_active: !sponserData.is_active,
        };
        try {
          const res = await putNodeApi("sponsors/change-status", postObj);
          SuccessToast(res.message || "Status Updated");
          GetSponserList(limit, page);
          // return res;
        } catch (err) {
          if (typeof err.response.data.message !== "undefined") {
            // ErrorToast(err.response.data.message || "Server Error!!");
            ErrorToast(err.response.message || "Server Error!!");
          } else {
            ErrorToast(err?.message || "Server Error!!");
          }
        }
      }
    });
  };*/

  const deleteSponserById = (id) => {
    Confirmation("Want to delete sponser?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`sponsors/`, { id })
          .then((res) => {
            SuccessToast(res?.message || "Sponsor Deleted.");
            GetSponserList(limit, page, "");
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetSponserList(limit, page, searchSponser);
  }, [limit, page, searchSponser]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Sponsor</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Sponsor</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Sponsor List</li>
                </ul>
              </div>
              {role == RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link
                    to="/admin/add-sponser"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add Sponsor
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.SPONSORADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-sponser"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add Sponsor
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search Sponsor"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Order Number</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Website</th>
                          <th>Image</th>
                          <th>Status</th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.SPONSOREDIT ||
                                  hasPermission(RolePermission.SPONSORDELETE)
                              ) && <th className="text-center">Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {sponserData.map((sponser, index) => (
                              <tr key={index}>
                                <td>{sponser?.order_number || "-"}</td>
                                <td>{sponser.title || "-"}</td>
                                <td>
                                  {sponser?.description &&
                                  sponser?.description?.length > 40 ? (
                                    <>
                                      <span>
                                        {truncateText(sponser.description, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openDescriptionModal(
                                            sponser.description
                                          )
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    sponser?.description || "-"
                                  )}
                                </td>
                                <td>
                                  {sponser?.website ?
                                    sponser.website.length > 40 ? (
                                      <>
                                        <span>
                                          {truncateText(sponser.website, 20)}
                                        </span>
                                        <Tooltip
                                          title="Details"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            openWebsiteModal(
                                              <div className="text-wrap">
                                                <a
                                                  href={sponser.website}
                                                  target="_blank"
                                                >
                                                  {sponser.website}
                                                </a>
                                              </div>
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <InfoIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <a
                                        href={sponser.website}
                                        target="_blank"
                                      >
                                        {sponser.website}
                                      </a>
                                    )
                                    : "-"
                                  }


                                  {/* {sponser?.website ? (
                                    <a href={sponser?.website} target="_blank">
                                      {sponser?.website}
                                    </a>
                                  ) : (
                                    "-"
                                  )} */}
                                </td>
                                <td>
                                  {sponser.image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_IMAGE_URL}${sponser?.image}`}
                                      alt="img"
                                      className="list-image-news"
                                    />
                                  ) : (
                                    <NameInitial
                                      name={sponser.title}
                                      type="event"
                                    />
                                  )}
                                </td>

                                {role === RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="Gurudwara"
                                    role={sponser}
                                    changeActive={handleSponserStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(
                                      RolePermission.GURDWARAEDIT
                                    ) && (
                                      <StatusButton
                                        module="Gurudwara"
                                        role={sponser}
                                        changeActive={handleSponserStatusChange}
                                      />
                                    )}
                                  </>
                                )}

                                {/* <StatusButton
                                  module="role"
                                  role={sponser}
                                  changeActive={handleSponserStatusChange}
                                /> */}
                                {/* <td>
                                  {sponser?.is_active === false && (
                                    <button
                                      className="btn btn-sm btn-block btn-outline-danger"
                                      onClick={() =>
                                        handleStatusChange(
                                          "Want to activate?",
                                          sponser?._id
                                        )
                                      }
                                    >
                                      {module === "sponsor"
                                        ? "Not Show"
                                        : "Inactive"}
                                    </button>
                                  )}
                                  {sponser?.is_active === true && (
                                    <button className="btn btn-sm btn-block btn-outline-success">
                                      {module === "sponsor" ? "Show" : "Active"}
                                    </button>
                                  )}
                                </td> */}

                                <td>
                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-sponser/${sponser?._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ONBORDINGEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-sponser/${sponser?._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() => {
                                        deleteSponserById(sponser?._id);
                                      }}
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ONBORDINGDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() => {
                                            deleteSponserById(sponser?._id);
                                          }}
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {/* <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate(
                                        `/admin/edit-sponser/${sponser?._id}`
                                      )
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() =>
                                      deleteSponserById(sponser?._id)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                </td>
                              </tr>
                            ))}
                            {sponserData.length === 0 && (
                              <tr>
                                <td align="center" colSpan={5}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {selectedDescription}
                        {selectedWebsite}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SponserList;
