import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate,useSearchParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import { Pagination } from "../../../Utils";
import StatusButton from "../../../Utils/StatusButton";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  postNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InfoIcon from "@mui/icons-material/Info";
import { useLoader } from "../../../Hooks/LoaderContext";
import NameInitial from "../../../Utils/NameInitial";

const RequestedGurdwaraList = () => {
  const navigate = useNavigate();
   const [searchParams] = useSearchParams();
  const { hasPermission, role } = usePermission();
  const [requestedgurdwaraData, setRequestedgurdwaraData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchRequestedGurdwara, setSearchRequestedGurdwara] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  const openAddressModal = (data) => {
    setSelectedAddress(`${data?.address}`);
    setModalTitle(data.name);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedAddress("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const getRequestedGurdwara = async () => {
    setLoading(true);
    await getNodeApi(
      `request-gurdwara/?offset=${page}&limit=${limit}&search=${searchRequestedGurdwara}`
    )
      .then((res) => {
        setLoading(false);
        if (res) {
          setRequestedgurdwaraData(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setRequestedgurdwaraData([]);
          setTotalRecords(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        setRequestedgurdwaraData([]);
        setTotalRecords(0);
      });
  };

  useEffect(() => {
    getRequestedGurdwara(limit, page, searchRequestedGurdwara);
  }, [limit, page, searchRequestedGurdwara]);

  const deleteGurdwara = (id) => {
    Confirmation("Want to delete gurdwara?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`gurudwara/`, { id })
          .then((res) => {
            SuccessToast(res?.data?.message || "Gurdwara Deleted.");
            getRequestedGurdwara(limit, page, "");
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getRequestedGurdwara(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getRequestedGurdwara(parseInt(event.target.value, 10), 0, "");
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchRequestedGurdwara(searchValue);
      } else {
        setSearchRequestedGurdwara("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const handleSendmail = (data) => {
    Confirmation("Are you sure you want to add this gurdwara?").then(async (result) => {
      if (result.isConfirmed) {
        const postObj = new URLSearchParams({
          id: data?._id,
          user_id: data?.users._id,
          name: data?.name,
          address: data?.address,
        });
        navigate(`/admin/add-gurdwara?${postObj.toString()}`);

        // try {
        //   const res = await postNodeApi("request-gurdwara/send-gurdwara-added-mail", postObj);
        //   SuccessToast(res?.message || "Gurudwara added notification and mail sending successfully.");
        //   getRequestedGurdwara(limit, page, searchRequestedGurdwara);
        // } catch (err) {
        //   if (typeof err?.response?.message !== "undefined") {
        //     ErrorToast(err?.response?.message || "something went wrong");
        //   } else {
        //     ErrorToast(err?.message || "something went wrong");
        //   }
        // }
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Kirtan4u | Requested Gurdwara List</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Requested Gurdwara</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Requested Gurdwara</li>
                </ul>
              </div>
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search Gurdwara"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          {role === RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.ADDREQUESTGURDWARA
                              ) && <th>Status</th>}
                            </>
                          )}
                          <th>Name</th>
                          <th>Address</th>
                          <th>User</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {requestedgurdwaraData.map((data) => (
                              <tr key={data.id}>
                                <td>
                                  {data?.users ? (
                                    data.status === 0 ? (
                                      <button
                                        className="btn btn-sm btn-block btn-outline-success"
                                        onClick={() => 
                                          handleSendmail(data)
                                        }
                                      >
                                        Add
                                      </button>
                                    ) : ( data.status === 1 &&
                                      <span className="badge badge-pill bg-success-light">
                                        Added
                                      </span>
                                    )
                                  ) : (
                                    <span className="badge badge-pill bg-danger-light">
                                      User Deleted
                                    </span>
                                  )}
                                </td>

                                <td>{data.name}</td>
                                <td>
                                  {data?.address &&
                                    data?.address.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(data?.address, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() => openAddressModal(data)}
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    `${data?.address}` || "-"
                                  )}
                                </td>
                                <td>
                                  {data?.users ? data?.users?.full_name : "-"}
                                </td>
                              </tr>
                            ))}
                            {requestedgurdwaraData.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{selectedAddress}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestedGurdwaraList;
