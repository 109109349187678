import React from 'react'
import { Link } from "react-router-dom";

const PermissionAlert = () => {
  return (
    <>
     <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row justify-content-lg-center">
            <div className="col-lg-10">
              <div className="page-header">
                <div className="row">
                  <div className="col">
                  </div>
                </div>
              </div>
             <div className="row">
             <div className="col-2"></div>
                <div className="col-8">
                  <div className="card">
                    <div className="card-body text-center my-3">
                        <h4 className="text-danger">You don't have permission to access this module.</h4>
                        <Link className="btn btn-primary my-2" to="/admin/dashboard">
                        <i className="fas fa-angle-double-left" /> Back To Dashboard
                        </Link>
                    </div>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PermissionAlert