import React from "react";

const SwitchToggle = ({ value, setFieldValue }) => {
  return (
    <div className="form-group toggle-switch-wrapper">
      <label className="row toggle-switch" htmlFor="switchOn">
        <div className="toggle-switch-content">
          <span className="d-block text-dark" style={{ fontSize: "larger" }}>
            Status
          </span>
        </div>
        <div className="">
          <input
            type="checkbox"
            name="is_active:"
            value="1"
            checked={value.is_active}
            className="toggle-switch-input"
            id="switchOn"
            onChange={(e) => {
              setFieldValue("is_active", e.target.checked);
            }}
          />
          <span className="toggle-switch-label">
            <span className="toggle-switch-indicator"></span>
          </span>
        </div>
      </label>
    </div>
  );
};

export default SwitchToggle;
