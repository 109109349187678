import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";

const DonationList = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [donations, setDonations] = useState([]);
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  const GetDonationList = async () => {
    setLoading(true);
    await getNodeApi(
      `general/get-donation-list?offset=${page}&limit=${limit}&search=${search}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.totalRecords > 0) {
          setDonations(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setDonations([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setDonations([]);
        setTotalRecords(0);
        throw err;
      });
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearch(searchValue);
      } else {
        setSearch("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearch(searchValue);
    } else {
      setSearch("");
    }
  }; */

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetDonationList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetDonationList(parseInt(event.target.value, 10), 0, "");
  };

  useEffect(() => {
    GetDonationList(limit, page, search);
  }, [limit, page, search]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Donation</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Donation</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Donation List</li>
                </ul>
              </div>
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Amount</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {donations.map((donation, index) => (
                              <tr key={index}>
                                <td>{donation?.amount || "-"}</td>
                                <td>{donation?.user?.first_name || "-"}</td>
                                <td>{donation?.user?.last_name || "-"}</td>
                                <td>{donation?.user?.email || "-"}</td>
                              </tr>
                            ))}
                            {donations.length === 0 && (
                              <tr>
                                <td align="center" colSpan={5}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationList;
