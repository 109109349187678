import React, { Fragment } from "react";
import { DropzoneArea } from "material-ui-dropzone";

const DragAndDropFile = ({
  className,
  handleChange,
  label,
  maxFileSize,
  acceptedFiles,
  randerKey,
}) => {
  return (
    <Fragment>
      <DropzoneArea
        key={randerKey}
        dropzoneText={label}
        maxFileSize={maxFileSize}
        filesLimit={1}
        acceptedFiles={acceptedFiles}
        multiple={false}
        className={className}
        onChange={handleChange}
        previewGridProps={{
          container: {
            spacing: 1,
            direction: "row",
            justifyContent: "center",
          },
          item: {
            md: 11,
          },
        }}
      />
    </Fragment>
  );
};
export default DragAndDropFile;
