import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import StatusButton from "../../../Utils/StatusButton";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";

const Role = () => {
  const navigate = useNavigate();
  const { hasPermission, role } = usePermission();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchRole, setSearchRole] = useState("");
  const [roleListPagination, setRoleListPagination] = useState([]);
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  const GetRoleList = async () => {
    setLoading(true);
    await getNodeApi(`/role?offset=${page}&limit=${limit}&search=${searchRole}`)
      .then((res) => {
        setLoading(false);
        if (res) {
          setRoleListPagination(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setRoleListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setRoleListPagination([]);
        setTotalRecords(0);
        if (typeof err?.response?.message !== "undefined") {
          ErrorToast(err?.response?.message || "something went wrong");
        } else {
          ErrorToast(err?.message || "something went wrong");
        }
      });
  };

  const handlePageChange = async (pageNew) => {
    setPage(pageNew);
    await GetRoleList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetRoleList(parseInt(event.target.value, 10), 0, "");
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchRole(searchValue);
    } else {
      setSearchRole("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchRole(searchValue);
      } else {
        setSearchRole("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const handleRoleStatusChange = async (postObj) => {
    try {
      const res = await putNodeApi("/role/change-status", postObj);
      GetRoleList(limit, page, "");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleDeleteRole = (id) => {
    Confirmation("Want to delete role?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi("/role", { id })
          .then((res) => {
            if (res?.status === 1) {
              SuccessToast(res?.message || "Role Deleted.");
            } else {
              ErrorToast(res?.message || "something went wrong");
            }
            GetRoleList(limit, page, "");
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetRoleList(limit, page, searchRole);
  }, [limit, page, searchRole]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Role Management</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Role &amp; Permissions</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Role &amp; Permissions
                  </li>
                </ul>
              </div>
              {role == RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link
                    to="/admin/add-role-management"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add Role &amp; Permissions
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.ROLEADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-role-management"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add Role &amp; Permissions
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search Role"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Role Name</th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.ROLEEDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}

                          {role == RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.ROLEEDIT ||
                                  hasPermission(RolePermission.ROLEDELETE)
                              ) && <th>Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {roleListPagination.map((roles, index) => (
                              <tr key={index}>
                                <td>{roles?.display_name || "-"}</td>
                                {role == RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="role"
                                    role={roles}
                                    changeActive={handleRoleStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(RolePermission.ROLEEDIT) && (
                                      <StatusButton
                                        module="role"
                                        role={roles}
                                        changeActive={handleRoleStatusChange}
                                      />
                                    )}
                                  </>
                                )}
                                <td>
                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-role-permission/${roles._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ROLEEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-role-permission/${roles._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() =>
                                        handleDeleteRole(roles?._id)
                                      }
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.ROLEDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() =>
                                            handleDeleteRole(roles?._id)
                                          }
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {roleListPagination.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;
