import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FilterComponentCustomer } from "../Common";
import { Pagination } from "../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Helmet } from "react-helmet-async";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import {
  DeleteCustomer,
  GetCustomerPaginate,
  BlockCustomer,
  getCustomerGenerateReport,
} from "../../../Services/Api/customer";
import format from "date-fns/format";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { saveAs } from "file-saver";
import { useLoader } from "../../../Hooks/LoaderContext";

const Customer = () => {
  const history = useNavigate();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  // const [customerUserId, setCustomerUserId] = useState();
  const [customerListPagination, setCustomerListPagination] = useState([]);
  const [resetDate, setResetDate] = useState(0);
  const { isLoading, setLoading } = useLoader();

  const handleReset = async () => {
    await setResetDate(0);
    setResetDate(1);
    setPage(0);
  };
  const handleGenerateReport = async ({ fDate, fStatus, fUser }) => {
    setResetDate(0);
    setPage(0);
    const postData = {
      filter_date: fDate || "",
      filter_status: fStatus || "",
      filter_user: fUser || "",
    };
    await getCustomerGenerateReport(postData)
      .then((res) => {
        handleReset();
        if (res?.data?.data?.url) {
          saveAs(res?.data?.data?.url, res?.data?.data?.file_name);
        } else {
          ErrorToast("Export data does not exist.");
        }
      })
      .catch((err) => {
        handleReset();
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const getCustomerList = async (limitO = "", pageO = "") => {
    setLoading(true);
    await GetCustomerPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setLoading(false);
        if (res?.data?.data?.totalRecords > 0) {
          setCustomerListPagination(res?.data?.data?.customers);
          setTotalRecords(res?.data?.data?.totalRecords);
        } else {
          setCustomerListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setCustomerListPagination([]);
        setTotalRecords(0);
        // if (typeof err.response.data.message !== "undefined") {
        //   ErrorToast(err.response.data.message || "Server Error!!");
        // } else {
        //   ErrorToast(err?.message || "Server Error!!");
        // }
      });
  };
  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getCustomerList(limit, pageNew);
  };
  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getCustomerList(parseInt(event.target.value, 10), 0);
  };
  const deleteCustomer = (deleteCustomerId) => {
    Confirmation("Want to delete customer?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteCustomer(deleteCustomerId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Customer Deleted.");
            getCustomerList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };
  const changeBlock = (customer, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          customer_id: customer._id,
          is_blocked: `${customer.is_blocked ^ 1}`,
        };
        await BlockCustomer(postObj)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            getCustomerList(limit, page);
          })
          .catch((err) => {
            // if (typeof err.response.data.message !== "undefined") {
            //   ErrorToast(err.response.data.message || "Server Error!!");
            // } else {
            //   ErrorToast(err?.message || "Server Error!!");
            // }
          });
      }
    });
  };
  useEffect(() => {
    getCustomerList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Customer</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Customers</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Customers</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/customer-expired-document"
                  className="btn btn-primary me-3 mb-2"
                >
                  Expired Document Users
                </Link>
                <Link
                  to="/admin/add-customer"
                  className="btn btn-primary me-1 mb-2"
                >
                  <i className="fas fa-plus" /> Add Customer
                </Link>
                {/* <Link
                  className="btn btn-primary filter-btn"
                  to="/"
                  id="filter_search"
                >
                  <i className="fas fa-filter" />
                </Link> */}
              </div>
            </div>
          </div>
          <FilterComponentCustomer
            resetDate={resetDate}
            handleGenerateReport={handleGenerateReport}
          />
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-center table-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>#</th>
                          <th>Actions</th>
                          <th>Customer</th>
                          <th>Email</th>
                          <th>Total Balance</th>
                          <th>Registered On</th>
                          <th>Block</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {customerListPagination.map((customer, index) => (
                              <tr key={index}>
                                <td>{limit * page + (index + 1)}</td>
                                <td>
                                  <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      history("/admin/edit-customer", {
                                        state: { id: customer._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() => deleteCustomer(customer._id)}
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title={
                                      customer?.docs.length > 0
                                        ? "Document Pending For Verification"
                                        : "View Document"
                                    }
                                    className={`btn btn-sm btn-white text-warning me-2 ${
                                      customer?.docs.length > 0 ? `ripple` : ``
                                    }`}
                                    onClick={() =>
                                      history("/admin/customer-legaldocument", {
                                        state: { id: customer._id },
                                      })
                                    }
                                    // style={{border: customer?.docs.length > 0 ? "2px solid red" : "0"}}
                                  >
                                    <IconButton>
                                      <InsertDriveFileIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Agreement"
                                    className="btn btn-sm btn-white text-warning me-2"
                                    onClick={() =>
                                      history("/admin/customer-agreement", {
                                        state: { id: customer._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <div className="handshake-img">
                                        <img
                                          src="../inner-assets/img/handshake-solid .svg"
                                          alt="handshake"
                                        />
                                      </div>
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="View Service Charge"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      history("/admin/service-charge", {
                                        state: {
                                          customerid: customer?._id,
                                          currency: customer?.primary_currency,
                                        },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <RequestQuoteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    {/* <Link to="/admin/customer-profile" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="../inner-assets/img/profiles/avatar-02.jpg" alt="User" /></Link>
                              <Link to="/admin/customer-profile">{customer.name} <span>{customer.phone}</span></Link> */}
                                    <Link
                                      to="/admin/customer"
                                      className="avatar avatar-sm me-2"
                                    >
                                      <img
                                        className="avatar-img rounded-circle"
                                        src={
                                          customer.profile_picture ||
                                          "../inner-assets/img/profiles/default_image.jpg"
                                        }
                                        alt="User"
                                      />
                                    </Link>

                                    <Link to="/admin/customer">
                                      {customer.name}{" "}
                                      <span>{customer.phone}</span>
                                    </Link>
                                  </h2>
                                </td>
                                <td>{customer.email}</td>
                                <td>
                                  <a
                                    className="invoice-link"
                                    onClick={() =>
                                      history("/admin/customer-statement", {
                                        state: {
                                          id: customer?.wallet?.user_id,
                                        },
                                      })
                                    }
                                  >
                                    <span className="currency-type-text">
                                      {customer?.wallet?.currency}
                                    </span>{" "}
                                    {customer?.wallet?.total_amount || "0"}
                                  </a>
                                </td>
                                <td>
                                  {format(
                                    new Date(customer.createdAt),
                                    "dd MMMM, yyyy"
                                  )}
                                </td>
                                <td>
                                  {customer.is_blocked === 1 && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeBlock(
                                          customer,
                                          "Want to active customer?"
                                        );
                                      }}
                                    >
                                      Suspend
                                    </button>
                                  )}
                                  {customer.is_blocked === 0 && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-secondary"
                                      onClick={() => {
                                        changeBlock(
                                          customer,
                                          "Want to suspend customer?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {customerListPagination.length === 0 && (
                              <tr>
                                <td colSpan={7}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}

                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={7}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
