/**
 * Handles errors that comming from server
 * @param {Object} error = Error object of axios that contain error from server
 */

const handleError = (error) => {
  const { response } = error;
  if (response) {
    let obj = {};
    switch (response.status) {
      case 501:
        obj = {
          title: "VALIDATION ERROR",
          message: response.data.message,
          status: true,
        };
        break;
      case 500:
        obj = {
          title: "ERROR",
          message:
            "There was error in processing request.Please try again later",
          status: true,
        };
        break;
      case 400:
        obj = {
          title: "ERROR",
          message: response?.data?.message || "Bad Request",
          status: true,
        };
        break;
      case 403:
        /* if (response?.data?.message === "accessDenied") {
          window.location.href = UNAUTHORISED_ACCESS;
        } */
        obj = {
          title: "ERROR",
          message: response?.data?.message,
          status: 403,
        };
        break;
      case 404:
        obj = {
          title: "Resource You are trying to access is not available",
          message: "",
          status: true,
        };
        break;
      case 405:
        obj = {
          title: "ERROR",
          message: response?.data?.message || "accessDenied",
          status: true,
        };
        break;
      case 401:
        if (response?.data?.message === "Invalid-JWT") {
          localStorage.clear();
          window.location.href = "/";
        }
        obj = {
          title: "ERROR",
          message: "Unauthorized", // Invalid-JWT
          status: true,
        };
        // window.location.href = "/";
        break;
      default:
        obj = {
          title: "ERROR",
          message:
            "There was error in processing request.Please try again later",
          status: true,
        };
    }
    return obj;
  }
  return null;
};

module.exports = handleError;
