import { usePlacesWidget } from "react-google-autocomplete";
import { ErrorMessage } from "formik";
import React from "react";


const AddressCommon = ({ values, onChange, isrequired, setFieldValue }) => {
  
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      // types: ["(regions)"],
      types: ["establishment"],
    },
    onPlaceSelected: (place) => {
      console.log("place", place);
      let city_obj = place.address_components.filter(
        // val => val.types.indexOf('administrative_area_level_2') !== -1,
        val => val.types.indexOf('administrative_area_level_3') !== -1,
      );
      let state_obj = place.address_components.filter(
        val => val.types.indexOf('administrative_area_level_1') !== -1,
      );
      let country_obj = place.address_components.filter(val => val.types.indexOf('country') !== -1);
      let postal_code_obj = place.address_components.filter(
        val => val.types.indexOf('postal_code') !== -1,
      );
      const addrObj = {
        latitude: place?.geometry?.location?.lat() || 0,
        longitude: place?.geometry?.location?.lng() || 0,
        address: place.formatted_address,
        city: city_obj[0]?.long_name,
        state: state_obj[0]?.long_name,
        country: country_obj[0]?.long_name,
        zipcode: postal_code_obj[0]?.long_name || '',
      };
      setFieldValue('address', addrObj);
      onChange(addrObj);
    }
  })

  return (
    <>
      <div className="form-group">
        <label>
          Address {isrequired && <span className="text-danger">*</span>}
        </label>
        <input
          ref={ref}
          type="text"
          className={`form-control`}
          name="address"
          defaultValue={values?.address?.address || ""}
          placeholder="Enter Address"
        />
        <ErrorMessage name="address" component="span" className="error" />
      </div>
    </>
  );
};

export default AddressCommon;
