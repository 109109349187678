import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhonePicker = ({ value, onChange }) => {
  return (
    <div>
      <PhoneInput
        country={"us"}
        inputStyle={{ width: "100%", height: "45px" }}
        enableSearch
        value={value}
        onChange={onChange}
        name="contact_number"
        autoFormat
      />
    </div>
  );
};

export default PhonePicker;
