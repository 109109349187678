import * as Yup from "yup";
import {
  validateImage,
  validateName,
  validatedescription,
} from "../../../../Utils/validation";
import moment from "moment";

export const addEventInitialValues = {
  name: "",
  description: "",
  event_location: "",
  date: "",
  start_time: "",
  end_time: "",
  // start_time: "",
  // end_time: "",
  jatha_id: "",
  // parchaarak_id: "",
  gurudwara_id: "",
  image: "",
  country: "",
  state: "",
  city: "",
  country_gurdwara: "",
  state_gurdwara: "",
  is_active: true,
};

export const addEventValidationSchema = Yup.object().shape({
  date: Yup.date().required("Please select date"),
  start_time: Yup.date().required("Start time is required"),
  end_time: Yup.date()
    .required("End time is required")
    .when("start_time", (startTime, schema) => {
      return startTime
        ? schema.min(startTime, "End time must be after the start time")
        : schema;
    }),

  // start_time: Yup.date().required("Please select start time"),
  // end_time: Yup.date()
  //   .required("Please select end time")
  //   .min(Yup.ref("start_time"), "End time can't be before start time"),
  // start_time: Yup.string().required("Start time is required"),
  // end_time: Yup.string()
  //   .required("End time is required")
  //   .test(
  //     "is-greater",
  //     "End time must be after start time",
  //     function (value) {
  //       const { startTime } = this.parent;
  //       if (!startTime || !value) return true; // Allow if start time or end time is not selected
  //       const startTimeHour = parseInt(startTime.split(":")[0]);
  //       const startTimeMinute = parseInt(startTime.split(":")[1]);
  //       const endTimeHour = parseInt(value.split(":")[0]);
  //       const endTimeMinute = parseInt(value.split(":")[1]);
  //       if (endTimeHour < startTimeHour) return false;
  //       if (endTimeHour === startTimeHour && endTimeMinute <= startTimeMinute)
  //         return false;
  //       return true;
  //     }
  //   ),
  // start_time: Yup.string().required("Start time is required"),
  // end_time: Yup.string()
  //   .required("End time is required")
  //   .test("is-greater", "End time must be after start time", function (value) {
  //     const { start_time } = this.parent;
  //     if (!start_time || !value) return true; // Allow if start time or end time is not selected

  //     // Convert time strings to Date objects for comparison
  //     const startTime = new Date(`2000-01-01T${start_time}`);
  //     const endTime = new Date(`2000-01-01T${value}`);

  //     // Compare the times
  //     return startTime < endTime;
  //   }),

  jatha_id: Yup.object()
    .nullable()
    .required("Please select jatha or parchaarak"),

  /*
    parchaarak_id: Yup.object()
      .nullable()
      .when("jatha_id", {
        is: (jathaId) => !jathaId,
        then: Yup.object()
          .nullable()
          .required("Please select any one: jatha or parchaarak"),
        otherwise: Yup.object().nullable(),
      }),
    */

  gurudwara_id: Yup.string().nullable().required("Please select gurdwara"),
  image: validateImage,
});

export const editEventValidationSchema = Yup.object().shape({
  date: Yup.date().required("Please select date"),
  start_time: Yup.date().required("Start time is required"),
  end_time: Yup.date()
    .required("End time is required")
    .when("start_time", (startTime, schema) => {
      return startTime
        ? schema.min(startTime, "End time must be after the start time")
        : schema;
    }),
  // start_time: Yup.date().required("Please select start time"),
  // end_time: Yup.date()
  //   .required("Please select end time")
  //   .min(Yup.ref("start_time"), "end time can't be before start time"),
  // start_time: Yup.string().required("Start time is required"),
  // end_time: Yup.string()
  //   .required("End time is required")
  //   .test("is-greater", "End time must be after start time", function (value) {
  //     const { startTime } = this.parent;
  //     if (!startTime || !value) return true; // Allow if start time or end time is not selected
  //     const startTimeHour = parseInt(startTime.split(":")[0]);
  //     const startTimeMinute = parseInt(startTime.split(":")[1]);
  //     const endTimeHour = parseInt(value.split(":")[0]);
  //     const endTimeMinute = parseInt(value.split(":")[1]);
  //     if (endTimeHour < startTimeHour) return false;
  //     if (endTimeHour === startTimeHour && endTimeMinute <= startTimeMinute)
  //       return false;
  //     return true;
  //   }),
  jatha_id: Yup.object()
    .nullable()
    .required("Please select jatha or parchaarak"),

  /*
    parchaarak_id: Yup.object()
      .nullable()
      .when("jatha_id", {
        is: (jathaId) => !jathaId,
        then: Yup.object()
          .nullable()
          .required("Please select any one: jatha or parchaarak"),
        otherwise: Yup.object().nullable(),
      }),
    */
  gurudwara_id: Yup.object().nullable().required("Please select gurdwara"),
  // reason: Yup.string().required("Reason is required"),
  reason: Yup.string(),
  image: validateImage,
  // image: Yup.mixed()
  //   .nullable()
  //   .test("is-valid-type", "Not a valid image type", (value) =>
  //     isValidFileType(value && value?.name?.toLowerCase(), "image")
  //   ),
});

export const deleteEventInitialValues = {
  reason: "",
};

export const deleteEventValidationSchema = Yup.object().shape({
  reason: Yup.string().required("Reason is required"),
});
