import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import { getNodeApi, postNodeApi } from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";
import {
  validateImage,
  validateName,
  validatePdf,
} from "../../../Utils/validation";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const SiteSettings = () => {
  const { hasPermission, role } = usePermission();
  const [siteSettingsData, setSiteSettingsData] = useState();
  const [liveKirtanData, setliveKirtanData] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  const [filePdfError, setPdfFileError] = useState("");
  const { setLoading } = useLoader();
  // const Url = /^(https?:\/\/)?([a-z0-9.-]+)\.com$/;
  const Url =
    /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;
  const UrlLive = /^(ftp|http|https):\/\/[^ "]+$/;

  const editSiteSettingsInitialValues = {
    facebook_url: siteSettingsData?.facebook_url || "",
    instagram_url: siteSettingsData?.instagram_url || "",
    youtube_url: siteSettingsData?.youtube_url || "",
    twitter_url: siteSettingsData?.twitter_url || "",
    contact_email: siteSettingsData?.contact_email || "",
    contact_phone: siteSettingsData?.contact_phone || "",
    search_radius: siteSettingsData?.search_radius || "",
    about_us_pdf: siteSettingsData?.about_us_pdf || "",
  };

  const editLiveKirtanInitialValues = {
    name: liveKirtanData?.name || "",
    url: liveKirtanData?.url || "",
    image: liveKirtanData?.image || "",
    kirtan_duties_english: liveKirtanData?.kirtan_duties_english || "",
    kirtan_duties_punjabi: liveKirtanData?.kirtan_duties_punjabi || "",
  };

  const editLiveKirtanValidationSchema = Yup.object().shape({
    name: validateName,
    url: Yup.string()
      .required("Live Kirtan Detail URL is required")
      .matches(UrlLive, "Please enter valid url"),
    kirtan_duties_english: Yup.string()
      .required("Please enter kirtan duties english url")
      .matches(UrlLive, "Please enter valid url"),
    image: validateImage,
    kirtan_duties_punjabi: Yup.string()
      .required("Please enter kirtan duties punjabi url")
      .matches(UrlLive, "Please enter valid url"),
  });

  const editSiteSettingsValidationSchema = Yup.object().shape({
    facebook_url: Yup.string()
      .required("Please enter facebook url")
      .matches(Url, "Please enter valid url"),
    instagram_url: Yup.string()
      .required("Please enter instagram url")
      .matches(Url, "Please enter valid url"),
    youtube_url: Yup.string()
      .required("Please enter youtube url")
      .matches(Url, "Please enter valid url"),
    twitter_url: Yup.string()
      .required("Please enter twitter url")
      .matches(Url, "Please enter valid url"),
    contact_email: Yup.string().required("Please enter contact email"),
    contact_phone: Yup.string().required("Please enter contact phone"),
    search_radius: Yup.string().required("Please enter search radius"),
    about_us_pdf: validatePdf,
  });

  const url = `${process.env.REACT_APP_API_IMAGE_URL}${siteSettingsData?.about_us_pdf}`;
  const parts = url.split("/");
  const fileName = parts[parts.length - 1];

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return;

    if (!file.type.startsWith("image/")) {
      e.currentTarget.value = null;
      setSelectedImage("");
      setFileError("Only image files are allowed.");
      setFieldValue("image", ""); // Reset image field in form values
      return;
    }

    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };
  const handlePdfFileChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return;

    if (file.type !== "application/pdf") {
      e.currentTarget.value = null;
      setFieldValue("about_us_pdf", null);
      setPdfFileError("Only PDF files are allowed.");
      return;
    }
    setPdfFileError("");
    setFieldValue("about_us_pdf", file);
  };

  const getSiteSettings = async () => {
    setLoading(true);
    await getNodeApi("settings/")
      .then((res) => {
        setLoading(false);
        setSiteSettingsData(res?.data);
      })
      .catch((err) => {
        setLoading(false);
        throw err;
      });
  };
  useEffect(() => {
    getSiteSettings();
  }, []);

  useEffect(() => {
    const getLiveKirtanDetail = async () => {
      setLoading(true);
      await getNodeApi("live-kirtan-details/")
        .then((res) => {
          setLoading(false);
          setliveKirtanData(res?.data);
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    };
    getLiveKirtanDetail();
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Site Settings</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Site Settings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Site Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Site Settings</h4>
                  <Formik
                    enableReinitialize
                    initialValues={editSiteSettingsInitialValues}
                    validationSchema={editSiteSettingsValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);

                      const pdfFile = values?.about_us_pdf;
                      let pdf = values.about_us_pdf;
                      if (typeof pdfFile === "object") {
                        const formData = new FormData();
                        formData.append("image", pdfFile);
                        formData.append("module", "ABOUT_US");
                        try {
                          const res = await getImageUrl(formData);
                          pdf = res.data.data[0].s3_key;
                        } catch (error) {
                          console.log("Error uploading PDF:", error);
                          setLoading(false);
                          return;
                        }
                      }
                      const postData = {
                        facebook_url: values?.facebook_url,
                        instagram_url: values?.instagram_url,
                        youtube_url: values?.youtube_url,
                        twitter_url: values?.twitter_url,
                        contact_email: values?.contact_email,
                        about_us_pdf: pdf,
                        contact_phone: values?.contact_phone.toString(),
                        search_radius: values?.search_radius.toString(),
                      };
                      await postNodeApi("settings", postData)
                        .then((res) => {
                          setLoading(false);
                          SuccessToast(
                            res?.message ||
                              "Site settings updated successfully."
                          );
                          setSubmitting(false);
                          getSiteSettings();
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting, setFieldValue } =
                        formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Facebook URL
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.facebook_url && touched.facebook_url
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="facebook_url"
                                  placeholder="Enter facebook url"
                                />
                                <ErrorMessage
                                  name="facebook_url"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Instagram URL
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.instagram_url &&
                                    touched.instagram_url
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="instagram_url"
                                  placeholder="Enter Instagram url"
                                />
                                <ErrorMessage
                                  name="instagram_url"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Youtube URL
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.youtube_url && touched.youtube_url
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="youtube_url"
                                  placeholder="Enter Youtube url"
                                />
                                <ErrorMessage
                                  name="youtube_url"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Twitter URL
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.twitter_url && touched.twitter_url
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="twitter_url"
                                  placeholder="Enter Twitter url"
                                />
                                <ErrorMessage
                                  name="twitter_url"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Contact Email
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.contact_email &&
                                    touched.contact_email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="contact_email"
                                  placeholder="Enter Contact Email"
                                />
                                <ErrorMessage
                                  name="contact_email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Contact Phone
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.contact_phone &&
                                    touched.contact_phone
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="contact_phone"
                                  placeholder="Enter Contact Phone"
                                />
                                <ErrorMessage
                                  name="contact_phone"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Search Radius
                                  <span className="text-danger">
                                    * (input in miles)
                                  </span>
                                </label>
                                <Field
                                  type="number"
                                  className={`form-control ${
                                    errors.search_radius &&
                                    touched.search_radius
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="search_radius"
                                  placeholder="Enter Search Radius"
                                />
                                <ErrorMessage
                                  name="search_radius"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  About Us Pdf
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="file"
                                  accept=".pdf"
                                  name="about_us_pdf"
                                  onChange={(e) =>
                                    handlePdfFileChange(e, setFieldValue)
                                  }
                                  className={`form-control ${
                                    filePdfError ? "input-error" : ""
                                  }`}
                                />
                                <div className="pt-3">
                                  <a href={url} target="_blank">
                                    <PictureAsPdfIcon />
                                    {fileName}
                                  </a>
                                </div>
                                {filePdfError && (
                                  <span className="error d-flex">
                                    {filePdfError}
                                  </span>
                                )}
                                <ErrorMessage 
                                  name="about_us_pdf"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                          </div>
                          {role === RolePermission.USERTYPE ? (
                            <div className="text-end mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Save Changes
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          ) : (
                            <>
                              {hasPermission(RolePermission.SETTINGADD) && (
                                <div className="text-end mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btnstl"
                                    disabled={isSubmitting}
                                  >
                                    Save Changes
                                    {isSubmitting && (
                                      <i className="fas fa-spinner fa-spin"></i>
                                    )}
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title">Live Kirtan Details</h4>
                      <Formik
                        enableReinitialize
                        initialValues={editLiveKirtanInitialValues}
                        validationSchema={editLiveKirtanValidationSchema}
                        validateOnChange
                        onSubmit={async (values, { setSubmitting }) => {
                          setLoading(true);
                          const logoFile = values?.image;

                          let img = values.image;

                          if (typeof logoFile === "object") {
                            const formData = new FormData();
                            formData.append("image", logoFile);
                            formData.append("module", "LIVE_KIRTAN");
                            try {
                              const res = await getImageUrl(formData);
                              img = res.data.data[0].s3_key;
                            } catch (error) {
                              console.log("Error uploading image:", error);
                            }
                          }
                          const postData = {
                            name: values?.name,
                            url: values?.url,
                            image: typeof logoFile == "object" ? img : logoFile,
                            kirtan_duties_english:
                              values?.kirtan_duties_english,
                            kirtan_duties_punjabi:
                              values?.kirtan_duties_punjabi,
                          };
                          await postNodeApi("live-kirtan-details", postData)
                            .then((res) => {
                              setLoading(false);
                              SuccessToast(
                                res?.message ||
                                  "live kirtan details updated successfully."
                              );
                              setSubmitting(false);
                            })
                            .catch((err) => {
                              setLoading(false);
                              if (
                                typeof err?.response?.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err?.response?.message ||
                                    "something went wrong"
                                );
                              } else {
                                ErrorToast(
                                  err?.message || "something went wrong"
                                );
                              }
                              setSubmitting(false);
                            });
                        }}
                      >
                        {(formik) => {
                          const {
                            errors,
                            touched,
                            isSubmitting,
                            setFieldValue,
                            values,
                          } = formik;
                          return (
                            <Form>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Name{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.name && touched.name
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="name"
                                      placeholder="Enter News Title"
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Kirtan Duties Punjabi
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.kirtan_duties_punjabi &&
                                        touched.kirtan_duties_punjabi
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="kirtan_duties_punjabi"
                                      placeholder="Enter kirtan duties punjabi url"
                                    />
                                    <ErrorMessage
                                      name="kirtan_duties_punjabi"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>URL</label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.url && touched.url
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="url"
                                      placeholder="Enter News URL"
                                    />
                                    <ErrorMessage
                                      name="url"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Kirtan Duties English
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.kirtan_duties_english &&
                                        touched.kirtan_duties_english
                                          ? "input-error"
                                          : null
                                      }`}
                                      name="kirtan_duties_english"
                                      placeholder="Enter kirtan duties punjabi url"
                                    />
                                    <ErrorMessage
                                      name="kirtan_duties_english"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Upload Image
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="file"
                                      name="image"
                                      onChange={(e) =>
                                        handleImageChange(e, setFieldValue)
                                      }
                                      className={`form-control`}
                                    />
                                    <img
                                      src={
                                        typeof values.image == "object"
                                          ? selectedImage
                                          : `${process.env.REACT_APP_API_IMAGE_URL}${liveKirtanData?.image}`
                                      }
                                      alt="Live-Kirtan-img"
                                      style={{ width: "auto", height: "80px" }}
                                      className="mx-5 px-5 mt-2"
                                    />
                                    {fileError && ( 
                                      <span className="error d-flex">
                                        {fileError}
                                      </span>
                                    )}
                                    <ErrorMessage 
                                      name="image"
                                      component="span"
                                      className="error d-flex"
                                    />
                                  </div>
                                </div>
                              </div>
                              {role === RolePermission.USERTYPE ? (
                                <div className="text-end mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btnstl"
                                    disabled={isSubmitting}
                                  >
                                    Save Changes
                                    {isSubmitting && (
                                      <i className="fas fa-spinner fa-spin"></i>
                                    )}
                                  </button>
                                </div>
                              ) : (
                                <>
                                  {hasPermission(
                                    RolePermission.LIVEKIRTANADD
                                  ) && (
                                    <div className="text-end mt-4">
                                      <button
                                        type="submit"
                                        className="btn btn-primary btnstl"
                                        disabled={isSubmitting}
                                      >
                                        Save Changes
                                        {isSubmitting && (
                                          <i className="fas fa-spinner fa-spin"></i>
                                        )}
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteSettings;
