export const userTypeOPtions = [
  { label: "Jatha", value: "jatha" },
  { label: "Giani", value: "giani" },
  { label: "Priest", value: "priest" },
];

export const RolePermission = {
  USERTYPE: "super_admin",
  DASHBOARD: "dashboard",
  ROLELIST: "role-list",
  ROLEADD: "role-add",
  ROLEEDIT: "role-edit",
  ROLEDELETE: "role-delete",
  USERLIST: "user-list",
  USERADD: "user-add",
  USEREDIT: "user-edit",
  USERDELETE: "user-delete",
  ADMINLIST: "admin-list",
  ADMINADD: "admin-add",
  ADMINEDIT: "admin-edit",
  ADMINDELETE: "admin-delete",
  APPUSERLIST: "app-user-list",
  APPUSERADD: "app-user-add",
  APPUSEREDIT: "app-user-edit",
  APPUSERDELETE: "app-user-delete",
  COUNTRYLIST: "country-list",
  COUNTRYADD: "country-add",
  COUNTRYEDIT: "country-edit",
  COUNTRYDELETE: "country-delete",
  STATELIST: "state-list",
  STATEADD: "state-add",
  STATEEDIT: "state-edit",
  STATEDELETE: "state-delete",
  CITYLIST: "city-list",
  CITYADD: "city-add",
  CITYEDIT: "city-edit",
  CITYDELETE: "city-delete",
  GURDWARALIST: "gurdwara-list",
  GURDWARAADD: "gurdwara-add",
  GURDWARAEDIT: "gurdwara-edit",
  GURDWARADELETE: "gurdwara-delete",
  EVENTLIST: "event-list",
  EVENTADD: "event-add",
  EVENTEDIT: "event-edit",
  EVENTDELETE: "event-delete",
  NEWSLIST: "news-list",
  NEWSADD: "news-add",
  NEWSEDIT: "news-edit",
  NEWSDELETE: "news-delete",
  RATINGLIST: "rating-list",
  RATINGSTATUSCHANGE: "rating-status-change",
  CONTENTVIEW: "content-view",
  CONTENTUPDATE: "content-update",
  SETTINGVIEW: "setting-view",
  SETTINGADD: "setting-add",
  ABOUTUSVIEWUPDATE: "about-us-view-update",
  TERMSOFUSEVIEWUPDATE: "terms-of-use-view-update",
  WHATISKIRTANVIEWUPDATE: "what-is-kirtan-view-update",
  SIKHISMBASICSVIEWUPDATE: "sikhism-basics-view-update",
  PRIVACYPOLICYVIEWUPDATE: "privacy-policy-view-update",
  LEGALVIEWUPDATE: "legal-view-update",
  ONBORDINGLIST: "onbording-list",
  ONBORDINGADD: "onbording-add",
  ONBORDINGEDIT: "onbording-edit",
  ONBORDINGDELETE: "onbording-delete",
  SPONSORLIST: "sponsor-list",
  SPONSORADD: "sponsor-add",
  SPONSOREDIT: "sponsor-edit",
  SPONSORDELETE: "sponsor-delete",
  SPONSORSTATUSCHANGE: "sponsor-status-change",
  HELPLIST: "help-list",
  HELPADD: "help-add",
  HELPEDIT: "help-edit",
  HELPDELETE: "help-delete",
  REQUESTGURDWARALIST: "request-gurdwara-list",
  ADDREQUESTGURDWARA: "add-request-gurdwara",
};
