import React from "react";
import { Route, Routes } from "react-router-dom";
import ResetPassword from "../Components/Auth/ResetPassword";
import Login from "../Components/Auth/Login";
import ForgotPassword from "../Components/Auth/ForgotPassword";
import GuestRoute from "./GuestRoute";
const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<GuestRoute Component={Login} />} />
        <Route exact path="/forgot-password" element={<GuestRoute Component={ForgotPassword} />} />
        <Route exact path="/reset-password/:token" element={<GuestRoute Component={ResetPassword} />} />
      </Routes>
    </>
  );
};
export default AuthRoutes;
