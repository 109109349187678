import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import { editEventValidationSchema } from "./constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { customSelectStyles } from "../../../../Utils/CSS/select";
import SwitchToggle from "../../../../Utils/SwitchToggle";
import { getImageUrl } from "../../../../Services/Api/fileUpload";
import { getNodeApi, putNodeApi } from "../../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../../Hooks/LoaderContext";
import { format } from "date-fns";
import { jssPreset } from "@material-ui/core";
import moment from "moment-timezone";

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  // const [cityList, setCityList] = useState([]);
  const [countryId, setCountryId] = useState("");
  // const [stateId, setStateId] = useState();
  // const [cityId, setCityId] = useState();
  const [gurudwaraList, setGurudwaraList] = useState([]);
  const [jathaList, setJathaList] = useState([]);
  // const [parchaarakList, setParchaarakList] = useState([]);
  const [eventData, setEventData] = useState([]);
  // const [editCityId, setEditCityId] = useState("");
  // const [editStateId, setEditStateId] = useState("");
  const [editCountryId, setEditCountryId] = useState("");
  // const [startDate, setStartDate] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  // const [date, setDate] = useState();
  // const [startTime, setStartTime] = useState();
  // const [endTime, setEndTime] = useState();
  const { setLoading } = useLoader();
  const [gurdwaraCountryId, setGurdwaraCountryId] = useState();
  const [gurdwaraStateId, setGurdwaraStateId] = useState();
  // const [value, setFieldValue] = useFormikContext();

  // const filterPassedTime = (time) => {
  //   const currentDate = new Date();
  //   const selectedDate = new Date(time);
  //   return currentDate.getTime() < selectedDate.getTime();
  // };

  // const filterEndTime = (time) => {
  //   const currentDate = start_time;
  //   const selectedDate = new Date(time);

  //   return currentDate.getTime() < selectedDate.getTime();
  // };

  // const filterEndTime = (startTime, endTime) => {
  //   // Convert start time and end time to Date objects
  //   const startDateTime = new Date(startTime);
  //   const endDateTime = new Date(endTime);

  //   // Ensure that the start time is before the end time
  //   return startDateTime.getTime() <= endDateTime.getTime();
  // };
  //  const formik=useFormikContext()

  const filterEndTime = (startTime, endTime) => {
    // Convert start time and end time to Date objects
    const startDateTime = new Date(startTime);
    const endDateTime = new Date(endTime);

    // Ensure that the current time being evaluated is after or equal to the start time
    return (time) => {
      const currentTime = new Date(time);
      return currentTime >= startDateTime;
    };
  };
  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return;

    if (!file.type.startsWith("image/")) {
      e.currentTarget.value = null;
      setSelectedImage("");
      setFileError("Only image files are allowed.");
      setFieldValue("image", "");
      return;
    }

    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };

  // const dateOnly = eventData?.event?.date
  //   ? eventData.event.date.split("T")[0]
  //   : "";

  // const [year, month, day] = dateOnly.split("-").map(Number);


  const formattedDate = eventData?.event?.date
    ? new Date(eventData?.event?.date)
    : new Date();

  const editEventInitialValues = {
    name: eventData?.event?.name || "",
    event_type: eventData?.event?.event_type || "",
    description: eventData?.event?.description || "",
    event_location: eventData?.event?.event_location || "",
    // date: dateOnly ? new Date(dateOnly) : new Date(),
    date: formattedDate || new Date(),
    //   startTime: eventData.startTime
    //   ? new Date(eventData.startTime)
    //   : new Date(),
    // endTime: eventData.endTime ? new Date(eventData.endTime) : new Date(),
    // start_time: moment(utcStartDateString).format("YYYY-MM-DDTHH:mm:ssZ") || "",
    // end_time: moment(utcEndDateString).format("YYYY-MM-DDTHH:mm:ssZ") || "",
    image: eventData?.event?.image || "",
    jatha_id: eventData?.event?.jatha
      ? {
          label: eventData?.event?.jatha?.full_name  || "",
          value: eventData?.event?.jatha?._id || "",
        }
      : "",
    /*
    parchaarak_id: eventData?.event?.parchaarak
      ? {
          label:
            eventData?.event?.parchaarak?.first_name +
              " " +
              eventData?.event?.parchaarak?.last_name || "",
          value: eventData?.event?.parchaarak?._id || "",
        }
      : "",
    */
    gurudwara_id: eventData?.event?.gurudwara
      ? {
          label: eventData?.event?.gurudwara?.name || "",
          value: eventData?.event?.gurudwara?.id || "",
        }
      : "",
    country: eventData?.event?.jatha?.country_id
    ? {
        label: eventData?.event?.jatha?.country_id?.name  || "",
        value: eventData?.event?.jatha?.country_id?._id || "",
      }
    : "",
    state: eventData?.event?.gurudwara
      ? {
          label: eventData?.event?.gurudwara?.state?.name || "",
          value: eventData?.event?.gurudwara?.state?._id || "",
        }
      : "",
    /*
    city: eventData?.event?.gurudwara_id
      ? {
          label: eventData?.event?.gurudwara_id?.city?.name || "",
          value: eventData?.event?.gurudwara_id?.city?._id || "",
        }
      : "",
    */
    country_gurdwara: eventData?.event?.gurudwara?.country
    ? {
        label: eventData?.event?.gurudwara?.country?.name || "",
        value: eventData?.event?.gurudwara?.country?.id || "",
      }
    : "",
    state_gurdwara: eventData?.event?.gurudwara?.state
    ? {
        label: eventData?.event?.gurudwara?.state?.name || "",
        value: eventData?.event?.gurudwara?.state?.id || "",
      }
    : "",
    reason: eventData?.event?.reason || "",
    is_active: eventData?.event?.is_active || false,
    start_time: (eventData?.event?.start_time) ? moment(eventData?.event?.start_time, 'hh:mm A').toDate() : null,
    end_time: (eventData?.event?.end_time) ? moment(eventData?.event?.end_time, 'hh:mm A').toDate() : null,
    // start_time: eventData?.event?.start_time || "",
    // end_time: moment(utcEndDateString).format("YYYY-MM-DDTHH:mm:ssZ") || "",
  };

  useEffect(() => {
    const getEventById = async () => {
      setLoading(true);
      await getNodeApi(`event/${id}`)
        .then((res) => {
          setLoading(false);
          let data = res?.data;

          setEventData(data);
          setGurdwaraCountryId(data?.event?.gurudwara?.country_id);
          setGurdwaraStateId(data?.event?.gurudwara?.state_id);
          setEditCountryId(data?.event?.gurudwara?.country_id);
          setCountryId(data?.event?.jatha?.country_id?.id);
        })
        .catch((err) => {
          throw err;
        });
    };
    getEventById();
  }, [id]);

  useEffect(() => {
    const getCountry = async () => {
      try {
        setLoading(true);
        const res = await getNodeApi("../general/get-all-countries?type=event");
        setLoading(false);
        const countryArr = res?.data?.countries || [];
        if (countryArr.length > 0) {
          const formattedCountries = countryArr.map((value) => ({
            label: value?.name,
            value: value?._id,
          }));
          setCountryList(formattedCountries);
        }
      } catch (error) {
        console.log("Error", error);
      }
    };
    getCountry();
  }, []);

  useEffect(() => {
    const getState = async () => {
      setLoading(true);
      await getNodeApi(
        `../general/get-all-states-by-country/${gurdwaraCountryId}`
      ).then((res) => {
        setLoading(false);
        let stateArr = res?.data?.states || [];
        if (stateArr.length > 0) {
          stateArr = stateArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
            };
          });
          setStateList(stateArr);
        } else {
          // ErrorToast("No Results.");
          setStateList([]);
        }
      });
    };
    if (gurdwaraCountryId) {
      console.log('gurdwaraCountryId', gurdwaraCountryId);
      getState(gurdwaraCountryId);
    }
  }, [gurdwaraCountryId]);

  useEffect(() => {
    const getGurdwara = async () => {
      setLoading(true);
      await getNodeApi(
        `../general/get-all-gurdwara-by-state/${gurdwaraStateId}`
      ).then((res) => {
        setLoading(false);
        let gurdwaraArr = res?.data?.gurdwara || [];
        if (gurdwaraArr.length > 0) {
          gurdwaraArr = gurdwaraArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
            };
          });
          setGurudwaraList(gurdwaraArr);
        } else {
          // ErrorToast("No Results.");
          setGurudwaraList([]);
        }
      });
    };
    if (gurdwaraStateId) {
      getGurdwara(gurdwaraStateId);
    }
  }, [gurdwaraStateId]);

  useEffect(() => {
    const getJatha = async (countryId) => {
      setLoading(true);
      await getNodeApi(
        `general/get-country-wise-users/?country_id=${countryId}&user_type=jatha`
      ).then((res) => {
        setLoading(false);
        if (res?.data?.length > 0) {
          let jathaArr = res?.data;
          jathaArr = jathaArr.map((value) => {
            return {
              label: value.full_name,
              value: value.id,
            };
          });
          setJathaList(jathaArr);
        } else {
          // ErrorToast("No Results.");
          setJathaList([]);
        }
      });
    };
    if (countryId) {
      getJatha(countryId);
    }
  }, [countryId]);

  /* useEffect(() => {
    const GetCityListApi = async (id) => {
      try {
        setLoading(true);
        const res = await getNodeApi(
          `../general/get-all-cities-by-state/${id}`
        );
        setLoading(false);
        const cityArr = res?.data?.cities || [];
        if (cityArr.length > 0) {
          const formattedCities = cityArr.map((value) => ({
            label: value.name,
            value: value._id,
          }));
          setCityList(formattedCities);
        }
      } catch (error) {
        console.log("Error", error);
      }
    };

    if (stateId) {
      if (stateId != null) {
        GetCityListApi(stateId);
      }
    } else {
      if (editStateId) {
        GetCityListApi(editStateId);
      }
    }
  }, [stateId, editStateId]);*/

  /*
  useEffect(() => {
    const getParchaarak = async (countryId) => {
      setLoading(true);
      await getNodeApi(
        `general/get-country-wise-users/?country_id=${countryId}&user_type=parchaarak`
      ).then((res) => {
        setLoading(false);
        if (res?.data?.length > 0) {
          let parchaarakArr = res?.data;
          parchaarakArr = parchaarakArr.map((value) => {
            return {
              label: value?.full_name,
              value: value?.id,
            };
          });
          setParchaarakList(parchaarakArr);
        } else {
          // ErrorToast("No Results.");
          setParchaarakList([]);
        }
      });
    };

    if (countryId) {
      getParchaarak(countryId);
    }
    if (editCountryId) {
      getParchaarak(editCountryId);
    }
  }, [countryId, editCountryId]);
  */

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Edit Event</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Event</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/sub-admin">Event</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Event</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/event" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Event Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={editEventInitialValues}
                    validationSchema={editEventValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const logoFile = values?.image;

                      let img = values.image;

                      if (typeof logoFile === "object") {
                        const formData = new FormData();
                        formData.append("image", logoFile);
                        formData.append("module", "EVENT");
                        try {
                          const res = await getImageUrl(formData);
                          img = res.data.data[0].s3_key;
                        } catch (error) {
                          if (error) {
                            ErrorToast(
                              error?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast("something went wrong");
                          }
                        }
                      }
                      const formattedDate = format(values.date, "dd/MM/yyyy");
                    
                      const postData = {
                        id: id,
                        name: values.name,
                        description: values.description,
                        // event_location: values.event_location,
                        date: formattedDate,
                        start_time:  moment(values?.start_time).format("HH:mm"),
                        end_time: moment(values?.end_time).format("HH:mm"),
                        image: typeof logoFile == "object" ? img : logoFile,
                        jatha_id: values?.jatha_id?.value || null,
                        // parchaarak_id: values?.parchaarak_id?.value || null,
                        gurudwara_id: values?.gurudwara_id?.value,
                        reason: values?.reason,
                        is_active: true,
                      };
                      setLoading(true);
                      await putNodeApi("event", postData)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Event updated successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/event");
                          }
                        })
                        .catch((err) => {
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Event Title</label>
                                <Field
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder="Enter event title"
                                />
                              </div>
                              <div className="form-group">
                                <label>Event Info</label>
                                <Field
                                  as="textarea"
                                  className="form-control"
                                  name="description"
                                  placeholder="Enter description"
                                />
                              </div>
                              {/* <div className="form-group">
                                <label>Event Location</label>
                                <div className="inputWrap">
                                  <Field
                                    type="text"
                                    className={`form-control ${
                                      errors.event_location &&
                                      touched.event_location
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="event_location"
                                    placeholder="Enter event location"
                                  />
                                </div>
                                <ErrorMessage
                                  name="event_location"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                              <div className="form-group">
                                <label>
                                  Date
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  {/* <DatePicker
                                    className="form-control"
                                    selected={date}
                                    minDate={new Date()}
                                    selectsStart
                                    onChange={(date) => {
                                      setDate(date);
                                      setFieldValue("date", date);
                                    }}
                                    dateFormat="d MMM, yyyy"
                                  /> */}
                                  <DatePicker
                                    className="form-control"
                                    selected={values.date} // Use values.date as the selected date
                                    onChange={(date) =>
                                      setFieldValue("date", date)
                                    }
                                    dateFormat="d MMM, yyyy"
                                  />
                                </div>
                                <ErrorMessage
                                  name="date"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="row">
                               
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Start Time
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="inputWrap">
                                     
                                      <Field name="start_time">
                                        {({ field, form }) => (
                                          <DatePicker
                                            {...field}
                                            className="form-control"
                                            selected={
                                              (field.value &&
                                                new Date(field.value)) ||
                                              null
                                            }
                                            onChange={(val) =>
                                              form.setFieldValue(
                                                field.name,
                                                val
                                              )
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                          />
                                        )}
                                      </Field>
                                    </div>
                                    <ErrorMessage
                                      name="start_time"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      End Time
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="inputWrap">
                                      
                                      <Field name="end_time">
                                        {({ field, form }) => (
                                          <DatePicker
                                            {...field}
                                            className="form-control"
                                            selected={
                                              (field.value &&
                                                new Date(field.value)) ||
                                              null
                                            }
                                            onChange={(val) =>
                                              form.setFieldValue(
                                                field.name,
                                                val
                                              )
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            minTime={moment(
                                              values.start_time
                                            ).toDate()} 
                                            maxTime={moment()
                                              .set({ hour: 23, minute: 45 })
                                              .toDate()}
                                              excludeTimes={[values.start_time]}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                    <ErrorMessage
                                      name="end_time"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Upload Image</label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                <img
                                  src={
                                    typeof values.image == "object"
                                      ? selectedImage
                                      : `${process.env.REACT_APP_API_IMAGE_URL}${eventData?.event?.image}`
                                  }
                                  alt="EventImage"
                                  style={{ width: "auto", height: "80px" }}
                                  className="mx-5 px-5 mt-2"
                                />
                                {fileError && ( 
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage 
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>

                              <div className="form-group">
                                <label>
                                  Reason
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.reason && touched.reason
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="reason"
                                  placeholder="Enter reason"
                                />
                                <ErrorMessage
                                  name="reason"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <section
                                className=" p-2 rounded mb-2"
                                style={{
                                  border: "solid #F2F2F2 2px",
                                  marginTop: "30px",
                                }}
                              >
                                <p className="mt-2 mb-2 fw-bold">
                                  Choose Jatha or Parchaarak by country
                                </p>
                                <div className="form-group">
                                  <label>Country</label>
                                  <Select
                                    options={countryList}
                                    onChange={(e) => {
                                      setFieldValue("country", e);
                                      setFieldValue("jatha_id", "");
                                      setCountryId(e?.value);
                                    }}
                                    value={values?.country}
                                    styles={customSelectStyles}
                                    name="country"
                                    placeholder="Select country"
                                  />
                                </div>
                                {/* <div className="form-group">
                                <label>City</label>
                                <Select
                                  options={cityList}
                                  onChange={(e) => {
                                    setFieldValue("city", e);
                                    setCityId(e.value);
                                  }}
                                  value={values.city}
                                  // isDisabled={!values.state || !stateId}
                                  styles={customSelectStyles}
                                  name="city"
                                  placeholder="Select city"
                                />
                                <ErrorMessage
                                  name="city"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                                <div className="form-group">
                                  <label>
                                    Jatha or Parchaarak
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={jathaList}
                                    onChange={(e) => {
                                      setFieldValue("jatha_id", e);
                                    }}
                                    value={values?.jatha_id}
                                    name="jatha_id"
                                    placeholder="Select jatha"
                                    styles={customSelectStyles}
                                    // isDisabled={
                                    //   !values.country ||
                                    //   (!countryId && !editCountryId)
                                    // }
                                  />
                                  <ErrorMessage
                                    name="jatha_id"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </section>
                              {/* <div className="form-group">
                                <label>
                                  Parchaarak
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={parchaarakList}
                                  onChange={(e) => {
                                    setFieldValue("parchaarak_id", e);
                                    setFieldValue("jatha_id", null);
                                  }}
                                  value={values?.parchaarak_id}
                                  name="parchaarak_id"
                                  placeholder="Select parchaarak"
                                  styles={customSelectStyles}
                                  // isDisabled={
                                  //   !values.country ||
                                  //   (!countryId && !editCountryId)
                                  // }
                                />
                                <ErrorMessage
                                  name="parchaarak_id"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                              <section
                                className="p-2 rounded mb-2"
                                style={{
                                  border: "solid #F2F2F2 2px",
                                  marginTop: "23px",
                                }}
                              >
                                <p className="mt-2 mb-2 fw-bold">
                                  Choose Gurdwara by country and state
                                </p>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Country</label>
                                      <Select
                                        options={countryList}
                                        onChange={(e) => {
                                          setFieldValue("country_gurdwara", e);
                                          setGurdwaraCountryId(e?.value);
                                          setFieldValue("state_gurdwara", "");
                                          setFieldValue("gurudwara_id", "");

                                          // editEventInitialValues.state_gurdwara = "";
                                        }}
                                        value={values.country_gurdwara}
                                        styles={customSelectStyles}
                                        name="country_gurdwara"
                                        placeholder="Select country"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>State</label>
                                      <Select
                                        options={stateList}
                                        onChange={(e) => {
                                          setFieldValue("state_gurdwara", e);
                                          setGurdwaraStateId(e?.value);
                                          setFieldValue("gurudwara_id", "");

                                        }}
                                        value={values.state_gurdwara}
                                        // isDisabled={!values.country || !countryId}
                                        styles={customSelectStyles}
                                        name="state_gurdwara"
                                        placeholder="Select state"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>
                                    Gurdwara
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={gurudwaraList}
                                    onChange={(e) => {
                                      setFieldValue("gurudwara_id", e);
                                    }}
                                    value={values.gurudwara_id}
                                    styles={customSelectStyles}
                                    name="gurudwara_id"
                                    placeholder="Select gurdwara"
                                  />
                                  <ErrorMessage
                                    name="gurudwara_id"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </section>
                            </div>
                            <div className="col-md-6">
                              <SwitchToggle
                                setFieldValue={setFieldValue}
                                value={values}
                              />
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Event
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEvent;
