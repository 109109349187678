import { getNodeApi } from "../../../../Services/Api/appUtilsApi";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useLoader } from "../../../../Hooks/LoaderContext";
import moment from "moment";
import NameInitial from "../../../../Utils/NameInitial";

const EventDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState([]);
  const { setLoading } = useLoader();

  const GetEvent = async () => {
    setLoading(true);
    await getNodeApi(`event/${id}`)
      .then((res) => {
        setLoading(false);
        if (res) {
          setEvent(res?.data?.event);
        } else {
          setEvent([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setEvent([]);
      });
  };

  useEffect(() => {
    GetEvent();
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Event Details</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Event Details</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/event">Event</Link>
                  </li>
                  <li className="breadcrumb-item active">Event Details</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/event" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="container mt-3 px-5">
                  <div className="row row-cols-1 row-cols-md-2 row-cols-sm-2 gx-5">
                    <div className="col pb-4">
                      <label className="fs-4">Event Name</label>
                      <div>{event?.name || "Not provided"}</div>
                    </div>
                    <div className="col pb-4">
                      <label className="fs-4">Event Info</label>
                      <div>{event?.description || "Not provided"}</div>
                    </div>
                    <div className="col pb-4">
                      <label className="fs-4">Date</label>
                      <div>
                        {moment(event?.date).format("D-MMM-yy") ||
                          "Not provided"}
                      </div>
                    </div>
                    <div className="col pb-4">
                      <label className="fs-4">Start Time</label>
                      <div>{event?.start_time || "Not provided"}</div>
                    </div>
                    <div className="col pb-4">
                      <label className="fs-4">End Time</label>
                      <div>{event?.end_time || "Not provided"}</div>
                    </div>
                    {/* <div className="col pb-4">
                      <label className="fs-4">Event Location</label>
                      <div>{event?.event_location || "Not provided"}</div>
                    </div> */}
                    <div className="col pb-4">
                      <label className="fs-4 pb-2">Event Image</label>
                      <div>
                        {event?.image ? (
                          <img
                            src={`${process.env.REACT_APP_API_IMAGE_URL}${event?.image}`}
                            alt="event_image"
                            className="rounded-circle"
                            width="150px"
                            height="auto"
                          />
                        ) : (
                          <NameInitial
                            style={{ width: "150px", height: "150px" }}
                            name={event?.jatha?.full_name}
                            type="event"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col pb-4">
                      <label className="fs-4">Gurudwara</label>
                      <p>
                        Name: &nbsp; {event?.gurudwara?.name || "Not provided"}
                      </p>
                      <p>
                        Address: &nbsp;
                        {event?.gurudwara?.address?.address || "Not provided"}
                      </p>
                      <div className="pb-2">
                        <p>
                          City:&nbsp;
                          {event?.gurudwara?.address?.city || "Not provided"}
                        </p>
                        <p>
                          State:&nbsp;
                          {event?.gurudwara?.address?.state || "Not provided"}
                        </p>
                        <p>
                          Country:&nbsp;
                          {event?.gurudwara?.address?.country || "Not provided"}
                        </p>
                      </div>
                      <div>
                        <label>Gurdwara Image:</label> <br />
                        {event?.gurudwara?.image ? (
                          <img
                            src={`${process.env.REACT_APP_API_IMAGE_URL}${event?.gurudwara?.image}`}
                            alt="gurudwara_image"
                            className="rounded-circle"
                            width="150px"
                            height="auto"
                          />
                        ) : (
                          <NameInitial
                            style={{ width: "150px", height: "150px" }}
                            name={event?.gurudwara?.name}
                            type="event"
                          />
                        )}
                      </div>
                    </div>
                   
                    <div className="col pb-4">
                      <label className="fs-4">Jatha or Parchaarak</label>
                      <div className="pb-2">
                        Name:&nbsp;
                        {`${ event?.jatha?.full_name }` || "Not provided"}
                      </div>
                      <div className="pb-2">
                        Contact Number: &nbsp;
                        {event?.jatha?.contact_number || "Not provided"}
                      </div>
                      <div className="pb-2">
                        Email: &nbsp;
                        {event?.jatha?.email || "Not provided"}
                      </div>
                      <div className="pb-2">
                        Whatsapp Contact Number: &nbsp;
                        {event?.jatha?.whatsapp_contact_number ||
                          "Not provided"}
                      </div>
                      <div className="pb-2">
                        Facebook Url:  &nbsp;
                        {event?.jatha?.facebook_profile ? (
                          <a
                            href={event?.jatha?.facebook_profile}
                            target="_blank"
                          >
                            {event?.jatha?.facebook_profile}
                          </a>
                        ) : (
                          <span>Not provided</span>
                        )}
                      </div>
                      <div className="pb-2">
                        Instagram Url:  &nbsp;
                        {event?.jatha?.instagram_profile ? (
                          <a
                            target="_blank"
                            href={event?.jatha?.instagram_profile}
                          >
                            {event?.jatha?.instagram_profile}
                          </a>
                        ) : (
                          <span>Not Provided</span>
                        )}
                      </div>
                      <div className="pb-2">
                        Youtube Url:  &nbsp;
                        {event?.jatha?.youtube_profile ? (
                          <a
                            target="_blank"
                            href={event?.jatha?.youtube_profile}
                          >
                            {event?.jatha?.youtube_profile}
                          </a>
                        ) : (
                          <span>Not Provided</span>
                        )}
                      </div>
                      <div className="pb-2">
                        Other Information:&nbsp;
                        {event?.jatha?.other_information || "Not provided"}
                      </div>
                      <div className="pb-2">
                        <label className="pb-2">Profile Picture:</label> <br />
                        {event?.jatha?.profile_picture ? (
                          <img
                            src={`${process.env.REACT_APP_API_IMAGE_URL}${event?.jatha?.profile_picture}`}
                            alt="profile_picture"
                            className="rounded-circle"
                            width="150px"
                            height="auto"
                          />
                        ) : (
                          <NameInitial
                            style={{ width: "150px", height: "150px" }}
                            name={event?.jatha?.full_name}
                            type="event"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDetails;
