import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import { deleteNodeApi, getNodeApi } from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";
import NameInitial from "../../../Utils/NameInitial";

const OtherAppsList = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [search, setSearch] = useState("");
  const [otherAppsList, setOtherAppsList] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  const GetOtherAppsList = async () => {
    setLoading(true);
    await getNodeApi(`otherapp?offset=${page}&limit=${limit}&search=${search}`)
      .then((res) => {
        setLoading(false);
        if (res?.totalRecords > 0) {
          setOtherAppsList(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setOtherAppsList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setOtherAppsList([]);
        setTotalRecords(0);
        throw err;
      });
  };

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearch(searchValue);
      } else {
        setSearch("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearch(searchValue);
    } else {
      setSearch("");
    }
  }; */

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetOtherAppsList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetOtherAppsList(parseInt(event.target.value, 10), 0, "");
  };

  const deleteOtherAppById = (id) => {
    Confirmation("Want to delete other app?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`otherapp/`, { id })
          .then((res) => {
            SuccessToast(res?.message || "Sponsor Deleted.");
            GetOtherAppsList(limit, page, "");
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetOtherAppsList(limit, page, search);
  }, [limit, page, search]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Other Apps</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Other Apps</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Other Apps List</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/add-otherapps"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-plus" /> Add Other Apps
                </Link>
              </div>
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search Other Apps"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>App Icon</th>
                          <th>Name</th>
                          <th>App Store Url</th>
                          <th>Play Store Url</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {otherAppsList.map((otherApps, index) => (
                              <tr key={index}>
                                <td>
                                  {otherApps?.image ? (
                                    <img
                                      src={otherApps?.image}
                                      alt="img"
                                      className="list-image-news"
                                    />
                                  ) : (
                                    <NameInitial
                                      name={otherApps?.name}
                                      type="event"
                                    />
                                  )}
                                </td>
                                <td>{otherApps?.name || "-"}</td>
                                <td className="new-url-table">
                                  {otherApps?.appstore_url ? (
                                    <a
                                      href={otherApps?.appstore_url}
                                      target="_blank"
                                    >
                                      {otherApps?.appstore_url}
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="new-url-table">
                                  {otherApps?.playstore_url ? (
                                    <a
                                      href={otherApps?.playstore_url}
                                      target="_blank"
                                    >
                                      {otherApps?.playstore_url}
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate(
                                        `/admin/edit-otherapps/${otherApps?._id}`
                                      )
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() =>
                                      deleteOtherAppById(otherApps?._id)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                            {otherAppsList.length === 0 && (
                              <tr>
                                <td align="center" colSpan={5}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherAppsList;
