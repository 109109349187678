import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { customSelectStyles } from "../../../Utils/CSS/select";
import SwitchToggle from "../../../Utils/SwitchToggle";
import usePermission from "../../../Hooks/useHasPermisson";
import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import { validateFirstName, validateLastName } from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";

const EditUser = () => {
  const { id } = useParams();
  const { token } = usePermission();
  const [user, setUser] = useState();
  const [roleList, setRoleList] = useState([]);
  const { setLoading } = useLoader();

  const navigate = useNavigate();
  let role = "";

  if (!user?.roles || user?.roles.length === 0) {
    role = null;
  } else {
    role = {
      label: user?.roles[0]?.role?.name || null,
      value: user?.roles[0]?.role?._id || null,
    };
  }

  const editUserItialValues = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    email: user?.email || "",
    roles: role,
    is_active: user?.is_active || false,
  };

  const editUserValidationSchema = Yup.object().shape({
    first_name: validateFirstName,
    last_name: validateLastName,
    roles: Yup.object().nullable().required("Please select user role"),
  });

  useEffect(() => {
    const getRoles = async () => {
      setLoading(true);
      await getNodeApi("../general/get-all-roles").then((res) => {
        setLoading(false);
        let roleArr = res?.data?.roles;
        roleArr = roleArr.map((value) => {
          return {
            label: value.name,
            value: value._id,
          };
        });
        setRoleList(roleArr);
      });
    };
    getRoles();

    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const getUserById = async () => {
      setLoading(true);
      await getNodeApi(`users/${id}`)
        .then((res) => {
          setLoading(false);
          setUser(res?.data);
        })
        .catch((err) => {
          throw err;
        });
    };
    getUserById(id);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Edit SubAdmin</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Sub Admin</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/sub-admin">Sub Admin</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/sub-admin" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Sub Admin Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={editUserItialValues}
                    validationSchema={editUserValidationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const postObj = {
                        id: id,
                        first_name: values?.first_name,
                        last_name: values?.last_name,
                        roles: [values?.roles?.value],
                        is_active: values?.is_active,
                      };
                      await putNodeApi("users/", postObj)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(res?.message || "User updated.");
                            setSubmitting(false);
                            navigate("/admin/sub-admin");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  First Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.first_name && touched.first_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="first_name"
                                  placeholder="Enter first name"
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.last_name && touched.last_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="last_name"
                                  placeholder="Enter last name"
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="email"
                                  name="email"
                                  onChange={handleChange}
                                  placeholder="Enter email"
                                  disabled
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Role
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={roleList}
                                  onChange={(e) => {
                                    setFieldValue("roles", e);
                                  }}
                                  value={values?.roles}
                                  name="roles"
                                  placeholder="Select role"
                                  styles={customSelectStyles}
                                />
                                <ErrorMessage
                                  name="roles"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <SwitchToggle
                              setFieldValue={setFieldValue}
                              value={values}
                            />
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
