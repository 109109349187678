import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import { Formik, Form, Field, ErrorMessage } from "formik";
import SwitchToggle from "../../../Utils/SwitchToggle";
import React, { useState, useEffect } from "react";
import Editor from "../../../Utils/CkEditor";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  validateImage,
  validateName,
  validatedescription,
} from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";

const EditNews = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [editorData, setEditorData] = useState("");
  const [newsById, setNewsById] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  const { setLoading } = useLoader();

  const Url =
    /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

  const handleEditorDataChange = (newData) => {
    setEditorData(newData);
  };

  const signInSchema = Yup.object().shape({
    name: validateName,
    description: Yup.string().nullable(),
    url: Yup.string()
      .matches(Url, "Please enter valid url"),
    // image: Yup.string().required("Please select image"),
    image: validateImage,
    expiry_date: Yup.date().required().label('Expiry date'),
    news_for: Yup.array()
      .min(1, "Please select at-least one user")
      .required("Please select at-least one user"),
  });

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return;

    if (!file.type.startsWith("image/")) {
      e.currentTarget.value = null;
      setSelectedImage("");
      setFileError("Only image files are allowed.");
      setFieldValue("image", ""); // Reset image field in form values
      return;
    }

    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };

  const initialValues = {
    name: newsById?.name || "",
    description: newsById?.description || "",
    url: newsById?.url || "",
    image: newsById?.image || "",
    is_active: newsById?.is_active,
    expiry_date: newsById?.expiry_date,
    news_for: newsById?.news_for || [],
  };

  const typeOfUser = [
    { label: "User", value: "general" },
    { label: "Jatha or Parchaarak", value: "jatha" },
    // { label: "Parchaarak", value: "parchaarak" },
  ];

  const getNewsBulletinsById = async () => {
    setLoading(true);
    await getNodeApi(`news/${id}`)
      .then((res) => {
        setLoading(false);
        setNewsById(res?.data);
        setEditorData(res?.data?.description);
      })
      .catch((err) => {
        setLoading(false);
        setNewsById([]);
        console.log("Error", err);
      });
  };

  useEffect(() => {
    getNewsBulletinsById();
  }, []);

  useEffect(() => {}, [setSelectedImage]);
  const handleEditSubmitForm = async (values, { setSubmitting }) => {
    setLoading(true);
    const logoFile = values?.image;

    let img = values.image;

    if (typeof logoFile === "object") {
      const formData = new FormData();
      formData.append("image", logoFile);
      formData.append("module", "NEWS");
      try {
        const res = await getImageUrl(formData);
        img = res.data.data[0].s3_key;
      } catch (error) {
        if (error) {
          ErrorToast(error?.message || "something went wrong");
        } else {
          ErrorToast("something went wrong");
        }
      }
    }

    const postData = {
      id: id,
      name: values?.name,
      description: editorData,
      url: values?.url,
      image: typeof logoFile == "object" ? img : logoFile,
      // image:values?.img,
      news_for: values?.news_for,
      expiry_date: values?.expiry_date,
      is_active: values?.is_active,
    };
    await putNodeApi("news/", postData)
      .then((res) => {
        setLoading(false);
        if (res?.status === 0) {
          ErrorToast(res?.message || "something went wrong");
        } else {
          SuccessToast(res?.message || "News edited successfully.");
          setSubmitting(false);
          navigate("/admin/news-bulletins");
        }
      })
      .catch((err) => {
        setLoading(false);
        if (typeof err?.response?.message !== "undefined") {
          ErrorToast(err?.response?.message || "something went wrong");
        } else {
          ErrorToast(err?.message || "something went wrong");
        }
        setSubmitting(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Edit News</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit News</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/news-bulletins">News Bulletin</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit News</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/news-bulletins"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">News Info</h4>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={handleEditSubmitForm}
                  >
                    {(formik) => {
                      const {
                        isSubmitting,
                        setFieldValue,
                        values,
                        errors,
                        touched,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          errors.name && touched.name
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="name"
                                        placeholder="Enter News Title"
                                      />
                                      <ErrorMessage
                                        name="name"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>URL</label>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          errors.url && touched.url
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="url"
                                        placeholder="Enter News URL"
                                      />
                                      <ErrorMessage
                                        name="url"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <label>
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                </label>

                                <Editor
                                  data={editorData}
                                  onDataChange={handleEditorDataChange}
                                  name="description"
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Logo</label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                <img
                                  src={
                                    typeof values.image == "object"
                                      ? selectedImage
                                      : `${process.env.REACT_APP_API_IMAGE_URL}${newsById?.image}`
                                  }
                                  alt="logo"
                                  className="news-img-space"
                                />
                                {fileError && (
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Expiry Date{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <DatePicker
                                  autoComplete="off"
                                  className={`form-control ${
                                    errors.expiry_date && touched.expiry_date
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="expiry_date"
                                  onChange={(date) =>
                                    setFieldValue("expiry_date", date)
                                  }
                                  minDate={new Date()}
                                  value={moment(values.expiry_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                />
                                <ErrorMessage
                                  name="expiry_date"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="card-header">
                            <lable>
                              Notification Send To{" "}
                              <span className="text-danger">*</span>
                            </lable>
                          </div>
                          <div className="row mx-3 my-4">
                            {typeOfUser.map((resource, index) => (
                              <div className="col-md-3" key={index}>
                                <div className="form-group">
                                  <label className="custom_check">
                                    <input
                                      type="checkbox"
                                      className="form-control"
                                      name="news_for"
                                      defaultChecked={newsById.news_for?.includes(
                                        resource?.value
                                      )}
                                      value={resource?.value}
                                      onClick={(e) => {
                                        if (e.target.checked === true) {
                                          const pushArray = values?.news_for;
                                          pushArray.push(e.target.value);
                                          setFieldValue("news_for", pushArray);
                                        } else if (e.target.checked === false) {
                                          const filterAraay =
                                            values?.news_for.filter((item) => {
                                              return item !== e.target.value;
                                            });
                                          setFieldValue(
                                            "news_for",
                                            filterAraay
                                          );
                                        }
                                      }}
                                    />
                                    <span className="checkmark" />
                                    <label>{resource?.label}</label>
                                  </label>
                                </div>
                              </div>
                            ))}
                            <ErrorMessage
                              name="news_for"
                              component="span"
                              className="error"
                            />
                          </div>
                          <SwitchToggle
                            setFieldValue={setFieldValue}
                            value={values}
                          />
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit News
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNews;
