import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Editor = ({ data, onDataChange }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={data}
      onChange={(event, editor) => {
        const newData = editor.getData();
        onDataChange(newData);
      }}
      config={{
        toolbar: ['undo', 'redo','|','paragraph','Heading','|','bold', 'italic','|', 'bulletedList', 'numberedList', 'outdent', 'indent',  ],
      }}
    />
  );
};

export default Editor;
