import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import { getNodeApi, putNodeApi } from "../../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../../Hooks/LoaderContext";
import { getImageUrl } from "../../../../Services/Api/fileUpload";
import {
  validateImage,
  validatedescription,
  validateTitle,
} from "../../../../Utils/validation";
import SwitchToggle from "../../../../Utils/SwitchToggle";

const EditOnbording = () => {
  const { id } = useParams();
  const [onbordingData, setOnbordingData] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  const { setLoading } = useLoader();
  const navigate = useNavigate();

  const EditOnbordingItialValues = {
    title: onbordingData?.title || "",
    description: onbordingData?.description || "",
    image: onbordingData?.image || "",
    is_active: onbordingData?.is_active || false,
  };

  const EditOnbordingValidationSchema = Yup.object().shape({
    title: validateTitle,
    description: validatedescription,
    // image: Yup.string().nullable().required("Please upload image "),
    image: validateImage,
  });

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return;

    if (!file.type.startsWith("image/")) {
      e.currentTarget.value = null;
      setSelectedImage("");
      setFileError("Only image files are allowed.");
      setFieldValue("image", ""); // Reset image field in form values
      return;
    }

    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };
  useEffect(() => {}, [setSelectedImage]);

  useEffect(() => {
    const getOnbordingById = async () => {
      setLoading(true);
      await getNodeApi(`onboardings/${id}`)
        .then((res) => {
          setLoading(false);
          setOnbordingData(res?.data);
        })
        .catch((err) => {
          throw err;
        });
    };
    getOnbordingById(id);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Onbording</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Onbording</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/onbording-list">Onbording</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Onbording</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/onbording-list"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Onbording Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={EditOnbordingItialValues}
                    validationSchema={EditOnbordingValidationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const logoFile = values?.image;
                      /*let img = "";
                      if (typeof logoFile == "object") {
                        const formData = new FormData();
                        formData.append("image", logoFile);
                        formData.append("module", "ONBOARDING");
                        await getImageUrl(formData).then((res) => {
                          img = res.data.data[0].s3_key;
                        });
                      }*/

                      let img = values.image;

                      if (typeof logoFile === "object") {
                        const formData = new FormData();
                        formData.append("image", logoFile);
                        formData.append("module", "ONBOARDING");
                        try {
                          const res = await getImageUrl(formData);
                          img = res.data.data[0].s3_key;
                        } catch (error) {
                          if (error) {
                            ErrorToast(
                              error?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast("something went wrong");
                          }
                        }
                      }

                      const postObj = {
                        id: id,
                        title: values?.title,
                        description: values?.description,
                        image: typeof logoFile == "object" ? img : logoFile,
                        is_active: values?.is_active,
                      };
                      await putNodeApi("onboardings/", postObj)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Onbording updated successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/onbording-list");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Title
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.title && touched.title
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="title"
                                  placeholder="Enter title"
                                />
                                <ErrorMessage
                                  name="title"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Description
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  className={`form-control ${
                                    errors.description && touched.description
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="description"
                                  placeholder="Enter description"
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              
                              <div className="form-group">
                                <label>
                                  Upload Image
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                <img
                                  src={
                                    typeof values.image == "object"
                                      ? selectedImage
                                      : `${process.env.REACT_APP_API_IMAGE_URL}${onbordingData?.image}`
                                  }
                                  alt="OnbordingImage"
                                  style={{ width: "auto", height: "80px" }}
                                  className="mx-5 px-5 mt-2"
                                />
                                {fileError && ( 
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage 
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                            <SwitchToggle
                            setFieldValue={setFieldValue}
                            value={values}
                          />
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Onbording
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOnbording;
