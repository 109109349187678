import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { getNodeApi, postNodeApi } from "../../../Services/Api/appUtilsApi";
import {
  validateAddress,
  validateCountryId,
  validateImage,
  validateLat,
  validateLong,
  validateName,
  validateStateId,
} from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import AddressCommon from "../AddressCommon";
import Select from "react-select";
import { customSelectStyles } from "../../../Utils/CSS/select";

const AddGurdwara = () => {
  // const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const formRef = useRef();
  const { setLoading } = useLoader();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState(""); // State to manage file error message
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryId, setCountryId] = useState();
  const queryParams = new URLSearchParams(window.location.search)

  
  const req_name = queryParams.get("name")
  const req_id = queryParams.get("id")
  const req_user_id = queryParams.get("user_id")
  const req_address = queryParams.get("address")
  /*const validFileExtensions = {
    image: ["jpg", "gif", "png", "jpeg", "svg", "webp"],
  };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };*/

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return; // Return if no file is selected

    if (!file.type.startsWith("image/")) {
      // Clear the file input
      e.currentTarget.value = null;
      // Clear the image preview
      setSelectedImage("");
      // Display an error message to the user
      setFileError("Only image files are allowed.");
      return;
    }

    // If an image file is selected, update the image preview and clear any previous error message
    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };

  const initialValues = {
    name: req_name || "",
    telephone: "",
    description: "",
    address: req_address || "",
    country_id: "",
    state_id: "",
    latitude: "",
    longitude: "",
    image: "",
    is_active: true,
  };

  const signInSchema = Yup.object().shape({
    name: validateName,
    // telephone: Yup.string().required("Telephone is required"),
    telephone: Yup.string().matches(
      /[0-9]/,
      "Telephone must be a numbers only."
    ),
    address: Yup.string().nullable().required("Address is required"),
    country_id: validateCountryId,
    state_id: validateStateId,
    latitude:validateLat,
    longitude: validateLong,
    image: validateImage,
  });

  const getCountry = async () => {
    setLoading(true);
    await getNodeApi("../general/get-all-countries?type=event").then((res) => {
      setLoading(false);
      let allcountry = res?.data?.countries;
      allcountry = allcountry.map((value) => {
        return {
          label: value.name,
          value: value._id,
        };
      });
      setCountryList(allcountry);
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    const getState = async () => {
      setLoading(true);
      await getNodeApi(
        `../general/get-all-states-by-country/${countryId}`
      ).then((res) => {
        setLoading(false);
        let stateArr = res?.data?.states || [];
        if (stateArr.length > 0) {
          stateArr = stateArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
            };
          });
          setStateList(stateArr);
        } else {
          // ErrorToast("No Results.");
          setStateList([]);
        }
      });
    };
    if (countryId) {
      getState(countryId);
    }
  }, [countryId]);

  return (
    <>
      <Helmet>
        <title>Kirtan4u | Add Gurdwara</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add Gurdwara</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/gurdwara">Gurdwara</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Gurdwara</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/gurdwara" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const logoFile = values?.image;
                      const formData = new FormData();
                      formData.append("image", logoFile);
                      formData.append("module", "GURDWARA");
                      let img = "";
                      if (values?.image) {
                        await getImageUrl(formData).then((res) => {
                          img = res.data.data[0].s3_key;
                        });
                      }

                      let address = {
                        address: values?.address,
                        state: values?.state_id?.label,
                        country: values?.country_id?.label,
                        latitude: values.latitude,
                        longitude: values.longitude,
                      }

                      const data = {
                        name: values?.name,
                        description: values?.description,
                        address: address,
                        telephone: values?.telephone.toString(),
                        image: img,
                        is_active: values?.is_active,
                        id:req_id || "",
                        user_id:req_user_id || "",
                      };

                      await postNodeApi("gurudwara", data)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Gurdwara created successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/gurdwara");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err.response.message !== "undefined") {
                            ErrorToast(
                              err.response.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Description</label>
                                <Field
                                  as="textarea"
                                  className={`form-control ${
                                    errors.description && touched.description
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="description"
                                  placeholder="Enter description"
                                />
                              </div>
                              <div className="form-group">
                                <label>Telephone</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.telephone && touched.telephone
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="telephone"
                                  placeholder="Enter telephone"
                                />
                                <ErrorMessage
                                  name="telephone"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Upload Image</label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                {selectedImage && (
                                  <img
                                    src={selectedImage}
                                    alt="GurudwaraImage"
                                    style={{ width: "auto", height: "80px" }}
                                    className="mx-5 px-5 mt-2"
                                  />
                                )}
                                {fileError && (
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                              <SwitchToggle
                            setFieldValue={setFieldValue}
                            value={values}
                          />
                            </div>
                            <div className="col-md-6">
                              {/* <AddressCommon
                                isrequired
                                setFieldValue={setFieldValue}
                                className={`form-control ${
                                  errors.address && touched.address
                                    ? "input-error"
                                    : null
                                }`}
                                name="address"
                                values={values}
                                onChange={(e) => {
                                  setFieldValue("address", e);
                                }}
                              />
                              <ErrorMessage
                                name="address"
                                component="span"
                                className="error"
                              /> */}
                              <div className="form-group">
                                <label>Address{" "}
                                  <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.address && touched.address
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="address"
                                  placeholder="Enter Address"
                                />
                                <ErrorMessage
                                  name="address"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Country{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={countryList}
                                  onChange={(e) => {
                                    setFieldValue("country_id", e);
                                    setCountryId(e?.value);
                                  }}
                                  value={values.country_id}
                                  name="country_id"
                                  placeholder="Select Country Name"
                                  styles={customSelectStyles}
                                />
                                <ErrorMessage
                                  name="country_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  State{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={stateList}
                                  onChange={(e) => {
                                    setFieldValue("state_id", e);
                                  }}
                                  value={values.state_id}
                                  name="state_id"
                                  placeholder="Select State Name"
                                  styles={customSelectStyles}
                                />
                                <ErrorMessage
                                  name="state_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Longitude{" "}
                                  <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.longitude && touched.longitude
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="longitude"
                                  placeholder="Enter Longitude"
                                />
                                <ErrorMessage
                                  name="longitude"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Latitude{" "}
                                  <span className="text-danger">*</span></label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.latitude && touched.latitude
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="latitude"
                                  placeholder="Enter Latitude"
                                />
                                <ErrorMessage
                                  name="latitude"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Gurdwara
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGurdwara;
