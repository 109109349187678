import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import SideMenu from "./SideMenu";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
// import { fileUploadToBucket } from "../../../Services/Api/fileUpload";
import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import {
  validateAddress,
  validateEmail,
  validateFirstName,
  validateLastName,
} from "../../../Utils/validation";
import { useLoader } from "../../../Hooks/LoaderContext";
import AddressCommon from "../AddressCommon";

const Settings = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const { setLoading } = useLoader();

  const initialValues = {
    email: profileData?.email || "",
    first_name: profileData?.first_name || "",
    last_name: profileData?.last_name || "",
    address: profileData?.address || "",
  };

  const signInSchema = Yup.object().shape({
    email: validateEmail,
    first_name: validateFirstName,
    last_name: validateLastName,
    address: validateAddress,
  });

  const getProfileData = async () => {
    setLoading(true);
    getNodeApi("auth/get-profile")
      .then((res) => {
        setLoading(false);
        let data = res?.data || {};
        setProfileData(data);
      })
      .catch((err) => {
        setLoading(false);
        throw err;
        // if (typeof err.response.data.message !== 'undefined') {
        //   ErrorToast(err.response.data.message || 'Server Error!!');
        // } else {
        //   ErrorToast(err?.message || 'Server Error!!');
        // }
      });
  };

  // const uploadProfile = async (e) => {
  //   setLoader(true);
  // };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Kirtan4U | Admin settings</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="page-title">Settings</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile Settings</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-4">
              <SideMenu />
            </div>
            <div className="col-xl-9 col-md-8">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Basic information</h5>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const data = {
                        first_name: values?.first_name,
                        last_name: values?.last_name,
                        address: values?.address,
                      };
                      await putNodeApi("auth/update-profile", data)
                        .then((res) => {
                          setLoading(false);
                          SuccessToast(
                            res?.message || "Profile data has been update."
                          );
                          setSubmitting(false);
                          getNodeApi("auth/get-profile")
                            .then((res) => {
                              setLoading(false);
                              setProfileData(res.data);
                              localStorage.setItem(
                                "userData",
                                JSON.stringify(res.data)
                              );
                              // navigate("/admin/profile");
                            })
                            .catch((err) => {
                              setLoading(false);
                              if (
                                typeof err?.response?.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err?.response?.message ||
                                    "something went wrong"
                                );
                              } else {
                                ErrorToast(
                                  err?.message || "something went wrong"
                                );
                              }
                            });
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          {/* <div className="row form-group">
                            <label
                              htmlFor="name"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Profile Image
                            </label>
                            <div className="col-sm-9">
                              <div className="d-flex align-items-center responsive-icon-center">
                                <label
                                  className="avatar avatar-xxl profile-cover-avatar m-0 "
                                  htmlFor="edit_img"
                                >
                                  <img
                                    // id="avatarImg"
                                    className="avatar-img center-pro"
                                    src={
                                      profileData?.profile_picture ||
                                      "/inner-assets/img/profiles/default_image.jpg"
                                    }
                                    alt="Profile-Imagie"
                                  />
                                  <input
                                    type="file"
                                    id="edit_img"
                                    onChange={(e) => uploadProfile(e)}
                                  />
                                  <span className="avatar-edit">
                                    {loader && (
                                      <i className="fas fa-spinner fa-spin"></i>
                                    )}
                                    {!loader && (
                                      <i
                                        data-feather="edit-2"
                                        className="avatar-uploader-icon fa fa-pencil-alt shadow-soft"
                                      />
                                    )}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div> */}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  First Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.first_name && touched.first_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="first_name"
                                  placeholder="Enter name"
                                  name="first_name"
                                  onChange={handleChange}
                                  value={values?.first_name}
                                />
                                <ErrorMessage
                                  name="first_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Last Name
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.last_name && touched.last_name
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="last_name"
                                  placeholder="Enter Last Name"
                                  name="name"
                                  onChange={handleChange}
                                  value={values?.last_name}
                                />
                                <ErrorMessage
                                  name="last_name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  id="email"
                                  placeholder="Enter email"
                                  name="email"
                                  onChange={handleChange}
                                  value={values?.email}
                                  disabled
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <AddressCommon
                                isrequired
                                setFieldValue={setFieldValue}
                                values={values}
                                onChange={(e) => {
                                  setFieldValue("address", e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Save Changes&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
