import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../../Utils";
import { ErrorToast } from "../../../../Utils/SweetAlert";
import StatusButton from "../../../../Utils/StatusButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import usePermission from "../../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../../Utils/constance/options";
import { getNodeApi, putNodeApi } from "../../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../../Hooks/LoaderContext";

const RatingReview = () => {
  const { id } = useParams();
  const { hasPermission, role } = usePermission();
  let RatingRevieId = "";
  if (id) {
    RatingRevieId = id;
  }
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [ratingList, setRatingList] = useState([]);
  const [searchRating, setSearchRating] = useState("");
  const [eventName, setEventName] = useState("");
  const [show, setShow] = useState(false);
  const [selectedReview, setSelectedReview] = useState("");
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null)

  const openModal = (review) => {
    setSelectedReview(review);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedReview("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // const getRatingReviewList = async (limit, page, search) => {
  //   setIsLoading(true);
  //   await GetRatingReviewList({ limit, page, search })
  //     .then((res) => {
  //       setIsLoading(false);
  //       if (res?.data?.totalRecords > 0) {
  //         setRatingList(res?.data?.data);
  //         setTotalRecords(res?.data?.totalRecords);
  //         setEventName("");
  //       } else {
  //         setRatingList([]);
  //         setTotalRecords(0);
  //         // ErrorToast("No Results.");
  //       }
  //     })
  //     .catch((err) => {
  //       setRatingList([]);
  //       setTotalRecords(0);
  //       setIsLoading(false);
  //       if (typeof err.response.data.message !== "undefined") {
  //         ErrorToast(err.response.data.message || "Server Error!!");
  //       } else {
  //         ErrorToast(err?.message || "Server Error!!");
  //       }
  //     });
  // };

  const getRatingReviewList = async () => {
    setLoading(true);
    await getNodeApi(
      `ratings?offset=${page}&limit=${limit}&search=${searchRating}`
    )
      .then((res) => {
        setLoading(false);
        if (res) {
          setRatingList(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setRatingList([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error", error);
      });
  };

  const getratingreviewlist = async (id) => {
    setLoading(true);
    await getNodeApi(`ratings/${id}`)
      .then((res) => {
        setLoading(false);
        let reviewdata = res?.data;
        setRatingList(reviewdata);
      })
      .catch((err) => {
        setLoading(false);
        setRatingList([]);
        setTotalRecords(0);
        if (typeof err?.response?.message !== "undefined") {
          ErrorToast(err?.response?.message || "something went wrong");
        } else {
          ErrorToast(err?.message || "something went wrong");
        }
      });
  };

  useEffect(() => {
    const getEventById = async (RatingRevieId) => {
      setLoading(true);
      await getNodeApi(`event/${RatingRevieId}`)
        .then((res) => {
          setLoading(false);
          let data = res?.data;
          setEventName(data?.name);
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    };
    getEventById(RatingRevieId);
  }, [RatingRevieId]);

 /* const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchRating(searchValue);
    } else {
      setSearchRating("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
  
    clearTimeout(timer);
  
    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchRating(searchValue);
      } else {
        setSearchRating("");
      }
    }, 500);
    
    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getRatingReviewList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getRatingReviewList(parseInt(event.target.value, 10), 0, "");
  };

  const handleRatingStatusChange = async (postObj) => {
    try {
      setLoading(true);
      const res = await putNodeApi("ratings/change-status", postObj);
      getRatingReviewList(limit, page, searchRating);
      setLoading(false);
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    if ((RatingRevieId = id)) {
      getratingreviewlist(RatingRevieId);
    } else {
      getRatingReviewList(limit, page, searchRating);
    }
  }, [limit, page, searchRating, RatingRevieId]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Rating & Review</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  {" "}
                  {eventName
                    ? `Rating & Review For ${eventName} Event`
                    : "Rating & Review"}
                </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Rating & Review</li>
                </ul>
              </div>
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search Rating"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Rating</th>
                          <th>Rating Description</th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.RATINGSTATUSCHANGE
                              ) && <th>Status</th>}
                            </>
                          )}
                          {!eventName && (
                            <>
                              <th>Event Name</th>
                              <th>Event Location</th>
                            </>
                          )}
                          <th>User Name</th>
                          <th>User Email</th>
                          <th>created On</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {ratingList.map((rating, index) => (
                              <tr key={index}>
                                <td>
                                  {Array.from({
                                    length: 5 || 0,
                                  }).map((_, index) => (
                                    <React.Fragment key={index}>
                                      {index < rating.stars ? (
                                        <StarIcon style={{ fill: "#7638ff" }} />
                                      ) : (
                                        <StarBorderOutlinedIcon
                                          style={{ fill: "#7638ff" }}
                                        />
                                      )}
                                    </React.Fragment>
                                  ))}
                                </td>
                                <td>
                                  {rating?.review &&
                                  rating.review.length > 50 ? (
                                    <>
                                      <span>
                                        {truncateText(rating.review, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() => openModal(rating.review)}
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    rating?.review || "-"
                                  )}
                                </td>
                                {role == RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="rating"
                                    role={rating}
                                    changeActive={handleRatingStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(
                                      RolePermission.RATINGSTATUSCHANGE
                                    ) && (
                                      <StatusButton
                                        module="rating"
                                        role={rating}
                                        changeActive={handleRatingStatusChange}
                                      />
                                    )}
                                  </>
                                )}
                                {!eventName && (
                                  <>
                                    <td>{rating?.event?.name || "-"}</td>
                                    <td>
                                      {rating?.event?.event_location || "-"}
                                    </td>
                                  </>
                                )}
                                <td>
                                  {`${rating?.user?.first_name} ${rating?.user?.last_name}` ||
                                    "-"}
                                </td>
                                <td>{rating?.user?.email || "-"}</td>
                                <td>
                                  {moment(rating.createdAt).format(
                                    "D-MMM-yy hh:mm A"
                                  ) || "-"}
                                </td>
                              </tr>
                            ))}
                            {ratingList.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>

                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>Rating Description</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{selectedReview}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Pagination
                      totalItem={totalRecords}
                      itemsPerPage={limit}
                      page={page}
                      handleChangePage={handlePageChange}
                      handleChangeRowsPerPage={handleLimitChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingReview;
