import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import { Pagination } from "../../../Utils";
import StatusButton from "../../../Utils/StatusButton";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InfoIcon from "@mui/icons-material/Info";
import { useLoader } from "../../../Hooks/LoaderContext";

const HelpList = () => {
  /**
   * useState...
   */
  const { hasPermission, role } = usePermission();
  const [helpData, setHelpData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchHelp, setSearchHelp] = useState("");
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  console.log("help data: ", helpData);

  const openAnswerModal = (data) => {
    setSelectedAnswer(data.answer);
    setModalTitle(data.question);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedAnswer("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const navigate = useNavigate();
  /**
   * get Help data from api...
   */
  const getHelp = async () => {
    setLoading(true);
    await getNodeApi(`help?offset=${page}&limit=${limit}&search=${searchHelp}`)
      .then((res) => {
        setLoading(false);
        if (res) {
          setHelpData(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setHelpData([]);
          setTotalRecords(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        setHelpData([]);
        setTotalRecords(0);
      });
  };

  useEffect(() => {
    getHelp(limit, page, searchHelp);
  }, [limit, page, searchHelp]);

  const deleteHelp = (id) => {
    Confirmation("Want to delete help?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`help/`, { id })
          .then((res) => {
            SuccessToast(res?.data?.message || "Help Deleted.");
            getHelp(limit, page, "");
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getHelp(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getHelp(parseInt(event.target.value, 10), 0, "");
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchHelp(searchValue);
    } else {
      setSearchHelp("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchHelp(searchValue);
      } else {
        setSearchHelp("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const handleStatusChange = async (postObj) => {
    try {
      const res = await putNodeApi("help/change-status", postObj);
      getHelp(limit, page);
      return res;
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <Helmet>
        <title>Kirtan4u | Help List</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Help</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Help</li>
                </ul>
              </div>
              {role === RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link to="/admin/add-help" className="btn btn-primary me-1">
                    <i className="fas fa-plus" /> Add Help
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.HELPADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-help"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add Help
                      </Link>
                    </div>
                  )}
                </>
              )}
              <div className="form-custom">
                <input
                  type="text"
                  className="form-control"
                  id="role_search"
                  placeholder="Search Help"
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Question</th>
                          <th>Answer</th>
                          {role === RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.HELPEDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}

                          {role === RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.HELPEDIT ||
                                  hasPermission(RolePermission.HELPDELETE)
                              ) && <th>Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {helpData.map((data) => (
                              <tr key={data.id}>
                                <td>{data.question}</td>
                                <td>
                                  {data?.answer && data?.answer?.length > 60 ? (
                                    <>
                                      <span>
                                        {truncateText(data.answer, 30)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() => openAnswerModal(data)}
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    data?.answer || "-"
                                  )}
                                </td>

                                {role === RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="Help"
                                    role={data}
                                    changeActive={handleStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(RolePermission.HELPEDIT) && (
                                      <StatusButton
                                        module="Help"
                                        role={data}
                                        changeActive={handleStatusChange}
                                      />
                                    )}
                                  </>
                                )}

                                <td>
                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-help/${data?._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.HELPEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-help/${data?._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() => {
                                        deleteHelp(data?._id);
                                      }}
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.HELPDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() => {
                                            deleteHelp(data?._id);
                                          }}
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {helpData.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>{selectedAnswer}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpList;
