import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import StatusButton from "../../../Utils/StatusButton";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import NameInitial from "../../../Utils/NameInitial";

const JathasGianiPriest = () => {
  const { hasPermission, role } = usePermission();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchAppUser, setSearchAppUser] = useState("");
  const [appUserListPagination, setappUserListPagination] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);
  const [selectedInformation, setSelectedInformation] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);

  const openInformationModal = (information) => {
    setSelectedInformation(information);
    setModalTitle(information.full_name);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedInformation("");
    setShow(false);
  };

  const TextArea = async () =>
    await Swal.fire({
      input: "textarea",
      name: "reason",
      inputLabel: "Are You Sure Want To Reject?",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      confirmButtonText: "Reject",
      denyButtonText: "Close",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#d33",
      showCancelButton: false,
      allowOutsideClick: true,
      returnInputValueOnDeny: false,
      reverseButtons: true,
      preConfirm: (msg) => {
        if (!msg) {
          Swal.showValidationMessage(`Please enter reason`);
        }
      },
    });

  const GetAppUserList = async (limit, page, search, user_type) => {
    setLoading(true);
    await getNodeApi(
      `app-users?offset=${page}&limit=${limit}&search=${search}&user_type=${user_type}`
    )
      .then((res) => {
        setLoading(false);
        if (res?.totalRecords > 0) {
          setappUserListPagination(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setappUserListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setLoading(false);
        setappUserListPagination([]);
        setTotalRecords(0);
        throw err;
      });
  };

  /* const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchAppUser(searchValue);
    } else {
      setSearchAppUser("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchAppUser(searchValue);
      } else {
        setSearchAppUser("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await GetAppUserList(limit, pageNew, searchAppUser, "jatha");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await GetAppUserList(parseInt(event.target.value, 10), 0);
  };

  const deleteUserById = (userId) => {
    Confirmation("Want to delete user?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`app-users/`, { id: userId })
          .then((res) => {
            SuccessToast(res?.message || "User Deleted.");
            GetAppUserList(limit, page, searchAppUser, "jatha");
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  const handleUserStatusChange = async (postObj) => {
    try {
      const res = await putNodeApi("app-users/change-status", postObj);
      GetAppUserList(limit, page, searchAppUser, "jatha");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleApprove = (appuser) => {
    Confirmation("Want To Approve!").then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          id: appuser?._id,
          status: 1,
          reason: null,
        };
        try {
          const res = await putNodeApi("app-users/approve-reject", postObj);
          SuccessToast(res?.message || "Approved");
          GetAppUserList(limit, page, searchAppUser, "jatha");
        } catch (err) {
          if (typeof err?.response?.message !== "undefined") {
            ErrorToast(err?.response?.message || "something went wrong");
          } else {
            ErrorToast(err?.message || "something went wrong");
          }
        }
      }
    });
  };

  const handleReject = (appuser) => {
    TextArea().then(async (rejectConfirm) => {
      if (rejectConfirm.isConfirmed) {
        let reason = rejectConfirm.value;
        let data = {
          id: appuser?._id,
          status: 2,
          reason: reason,
        };
        setLoading(true);
        await putNodeApi("app-users/approve-reject", data)
          .then((res) => {
            setLoading(false);
            SuccessToast(res?.message || "Rejected");
            GetAppUserList(limit, page, searchAppUser, "jatha");
          })
          .catch((err) => {
            setLoading(false);
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetAppUserList(limit, page, searchAppUser, "jatha");
  }, [limit, page, searchAppUser, "jatha"]);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Jatha or Parchaarak List</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Jatha or Parchaarak</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Jatha or Parchaarak
                  </li>
                </ul>
              </div>
              {role === RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link to="/admin/add-jathas" className="btn btn-primary me-1">
                    <i className="fas fa-plus" /> Add
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.APPUSERADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-jathas"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="form-custom">
              <input
                type="text"
                className="form-control"
                id="role_search"
                placeholder="Search User"
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Approve/Reject</th>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Country Name</th>
                          {/* <th>Address</th> */}
                          {role === RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.APPUSEREDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}

                          {role === RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.APPUSEREDIT ||
                                  hasPermission(RolePermission.APPUSERDELETE)
                              ) && <th>Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {appUserListPagination.map((appuser, index) => (
                              <tr key={index}>
                                <td>
                                  {appuser.status === 0 && (
                                    <>
                                      <button
                                        className="btn btn-sm btn-block btn-outline-success me-2"
                                        onClick={() => handleApprove(appuser)}
                                      >
                                        Approve
                                      </button>
                                      <button
                                        className="btn btn-sm btn-block btn-outline-danger"
                                        onClick={() => handleReject(appuser)}
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                                  {appuser.status === 1 && (
                                    <span className="badge badge-pill bg-success-light">
                                      Approved
                                    </span>
                                  )}
                                  {appuser.status === 2 && (
                                    <span>
                                      <span className="badge badge-pill bg-danger-light">
                                        Rejected
                                      </span>
                                      <Tooltip
                                        title={appuser.reason}
                                        className="btn btn-sm btn-white text-success me-2"
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </span>
                                  )}
                                  <button
                                    style={{ width: "40%" }}
                                    className="btn btn-sm btn-block btn-outline-success me-2 ms-2"
                                    onClick={() =>
                                      openInformationModal(appuser)
                                    }
                                  >
                                    View Details
                                  </button>
                                </td>
                                <td>{appuser?.full_name || "-"}</td>
                                {/* <td>
                                  {appuser?.first_name +
                                    " " +
                                    appuser?.last_name || "-"}
                                </td> */}
                                <td>{appuser?.email || "-"}</td>
                                <td>{appuser?.country?.name || "-"}</td>
                                {/* <td>
                                  {(appuser?.address &&
                                    `${appuser?.address?.address},${appuser?.address?.city},${appuser?.address?.state},${appuser?.address?.country},${appuser?.address?.zipcode}`) ||
                                    "-"}
                                </td> */}
                                {role === RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="appuser"
                                    role={appuser}
                                    changeActive={handleUserStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(
                                      RolePermission.APPUSEREDIT
                                    ) && (
                                      <StatusButton
                                        module="appuser"
                                        role={appuser}
                                        changeActive={handleUserStatusChange}
                                      />
                                    )}
                                  </>
                                )}

                                <td>
                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-jathas/${appuser._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.APPUSEREDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-jathas/${appuser._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() =>
                                        deleteUserById(appuser?._id)
                                      }
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.APPUSERDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() =>
                                            deleteUserById(appuser?._id)
                                          }
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {appUserListPagination.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal centered size="xl" show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>
                          Jatha or Parchaarak Information <p>({modalTitle})</p>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="row ">
                          <div className="mb-4 mt-2 d-flex justify-content-center align-items-center flex-column">
                            <>
                              {selectedInformation?.profile_picture ? (
                                <img
                                  src={`${process.env.REACT_APP_API_IMAGE_URL}${selectedInformation?.profile_picture}`}
                                  alt="profile_picture"
                                  className="rounded-circle"
                                  width="150px"
                                  height="150px"
                                />
                              ) : (
                                <div>
                                  <NameInitial
                                    style={{ width: "150px", height: "150px" }}
                                    name={selectedInformation?.full_name}
                                    type="event"
                                  />
                                </div>
                              )}
                              <div className="mt-2 fs-4">
                                {selectedInformation?.full_name || "-"}
                              </div>
                            </>
                          </div>
                          <div className="col-md-6 d-flex justify-content-center">
                            <div>
                              <div className="mb-4">
                                <label>Official Name</label>
                                <p>
                                  {selectedInformation?.official_name ||
                                    "Not Provided"}
                                </p>
                              </div>
                              <div className="mb-4">
                                <label>Contact Number</label>
                                <p>
                                  {selectedInformation?.contact_number ||
                                    "Not Provided"}
                                </p>
                              </div>
                              <div className="mb-4">
                                <label>Whatsapp Contact Number</label>
                                <p>
                                  {selectedInformation?.whatsapp_contact_number ||
                                    "Not Provided"}
                                </p>
                              </div>
                              <div className="mb-4">
                                <label>Other Information</label>
                                <p>
                                  {selectedInformation?.other_information ||
                                    "Not Provided"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex justify-content-center">
                            <div>
                            <div className="mb-4">
                                <label>Facebook Url</label>
                                <p>
                                  {selectedInformation?.facebook_profile ? (
                                    <a
                                      href={
                                        selectedInformation?.facebook_profile
                                      }
                                      target="_blank"
                                    >
                                      {selectedInformation?.facebook_profile}
                                    </a>
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                </p>
                              </div>
                              <div className="mb-4">
                                <label>Instagram Url</label>
                                <p>
                                  {selectedInformation?.instagram_profile ? (
                                    <a
                                      href={
                                        selectedInformation?.instagram_profile
                                      }
                                      target="_blank"
                                    >
                                      {selectedInformation?.instagram_profile}
                                    </a>
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                </p>
                              </div>
                              <div className="mb-4">
                                <label>Youtube Url</label>
                                <p>
                                  {selectedInformation?.youtube_profile ? (
                                    <a
                                      href={
                                        selectedInformation?.youtube_profile
                                      }
                                      target="_blank"
                                    >
                                      {selectedInformation?.youtube_profile}
                                    </a>
                                  ) : (
                                    <span>Not Provided</span>
                                  )}
                                </p>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JathasGianiPriest;
