import { postNodeApi } from "../../../Services/Api/appUtilsApi";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { useLoader } from "../../../Hooks/LoaderContext";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import {
  validateImage,
  validateUrl,
  validatedescription,
  validateTitle,
  validateordernumber,
  validateReuiredImage,
} from "../../../Utils/validation";

const AddSponser = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  const { setLoading } = useLoader();

  const AddSponserInitialValues = {
    title: "",
    description: "",
    image: "",
    website: "",
    order_number: ""
  };

  const AddSponserValidationSchema = Yup.object().shape({
    title: validateTitle,
    description: validatedescription,
    // image: Yup.string().nullable().required("Please upload image"),
    image: validateReuiredImage,
    website: validateUrl,
    order_number: validateordernumber
  });

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return; // Return if no file is selected

    if (!file.type.startsWith("image/")) {
      // Clear the file input
      e.currentTarget.value = null;
      // Clear the image preview
      setSelectedImage("");
      // Display an error message to the user
      setFileError("Only image files are allowed.");
      return;
    }

    // If an image file is selected, update the image preview and clear any previous error message
    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Sponsor</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Sponsor</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/sponser-list">Sponsor</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Sponsor</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/sponser-list" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Sponsor Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={AddSponserInitialValues}
                    validationSchema={AddSponserValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const logoFile = values?.image;
                      const formData = new FormData();
                      formData.append("image", logoFile);
                      formData.append("module", "SPONSOR");
                      let img = "";
                      if (values.image) {
                        await getImageUrl(formData).then((res) => {
                          img = res.data.data[0].s3_key;
                        });
                      }
                      const postData = {
                        title: values?.title,
                        description: values?.description,
                        order_number: values?.order_number,
                        image: img,
                        website: values?.website,
                      };
                      await postNodeApi("sponsors/", postData)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Sponser created successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/sponser-list");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting, setFieldValue } =
                        formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Title
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.title && touched.title
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="title"
                                  placeholder="Enter title"
                                />
                                <ErrorMessage
                                  name="title"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Description
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  className={`form-control ${
                                    errors.description && touched.description
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="description"
                                  placeholder="Enter description"
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Order Number
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.order_number && touched.order_number
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="order_number"
                                  placeholder="Enter Order Number"
                                />
                                <ErrorMessage
                                  name="order_number"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Website
                                  <span className="text-danger"></span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.website && touched.website
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="website"
                                  placeholder="Enter Website"
                                />
                                <ErrorMessage
                                  name="website"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Upload Image
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                {selectedImage && (
                                  <img
                                    src={selectedImage}
                                    alt="onbording-img"
                                    style={{ width: "auto", height: "80px" }}
                                    className="mx-5 px-5 mt-2"
                                  />
                                )}
                                {fileError && (
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Sponsor
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSponser;
