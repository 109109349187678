import Api from "../../Utils/Axios";

export const LoginService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/auth/login", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const ForgotPasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("auth/forgot-password", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const ResetPasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("auth/reset-password", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const getProfile = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("auth/get-profile"));
    } catch (error) {
      reject(error);
    }
  });
};
export const updateProfile = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put("auth/update-profile", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const ChangePasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("auth/change-password", data));
    } catch (error) {
      reject(error);
    }
  });
};
