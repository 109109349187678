import * as Yup from "yup";

export const validateName = Yup.string().required("Name is required");

export const validateTitle = Yup.string().required("Please enter title");

export const validateCountryCode = Yup.string()
  .required("Country code is required")
  .test("Number", "Country code must be number", (value) => /[0-9]/.test(value))
  .matches(/^[0-9]{1,4}$/, "Country code not more than 4 character");

export const validateDialCode = Yup.string()
  .required("Dial code is required")
  .matches(/^\+\d{1,4}$/, "Invalid dial code");

export const validateCurrency = Yup.string().required("Currency is required");

export const validateCountryId = Yup.object()
  .nullable()
  .required("Please select country");

export const validateFirstName = Yup.string().required(
  "First name is required"
);

export const validateLastName = Yup.string().required("Last name is required");

export const validateEmail = Yup.string().email().required("Email is required");

export const validateStateId = Yup.object()
  .nullable()
  .required("Please select state");

export const validateCityId = Yup.object()
  .nullable()
  .required("Please select city");

export const validatePincode = Yup.string()
  .required("Please enter pincode")
  .matches(/^[a-zA-Z0-9]{4,10}$/, "Pincode must be 4 to 10 character");

export const validateAddress = Yup.object()
  .nullable()
  .required("Address is required");

export const validatePassword = Yup.string()
  .required("Password is required")
  .test(
    "uppderCase",
    "Must contain at least one uppercase character",
    (value) => /[A-Z]/.test(value)
  )
  .test("lowerCase", "Must contain at least one lowercase character", (value) =>
    /[a-z]/.test(value)
  )
  .test("oneNumber", "Must contain at least one number", (value) =>
    /[0-9]/.test(value)
  )
  .test("oneSpecial", "Must contain at least one special character", (value) =>
    /[!@#%&]/.test(value)
  );

export const validateOptionalPassword = Yup.string()
  .test(
    "uppderCase",
    "Must contain at least one uppercase character",
    (value) => (value !== undefined) ? /[A-Z]/.test(value) : true
  )
  .test(
    "lowerCase",
    "Must contain at least one lowercase character",
    (value) => (value !== undefined) ? /[a-z]/.test(value) : true
  )
  .test(
    "oneNumber",
    "Must contain at least one number",
    (value) => (value !== undefined) ? /[0-9]/.test(value) : true
  )
  .test(
    "oneSpecial",
    "Must contain at least one special character",
    (value) => (value !== undefined) ? /[!@#%&]/.test(value) : true
  );

export const validatedescription = Yup.string().required(
  "Description is required"
);

export const validateUrl = Yup.string().matches(
  /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,})(\/[-a-z\d%_.~+!@*'()$]*)*(\?[;&a-z\d%_.@~+=-]*)?(\#[-a-z\d_]*)?$/i,
  "Enter correct url!"
);

export const validateLat = Yup.string()
  .matches(/^(-?([0-8]?[0-9]\.\d+|90\.0+)|-?90\.0+)$/, "Enter Correct Latitude Value!")
  .required("Latitude is required");

export const validateLong = Yup.string()
  .matches(/^(-?((1[0-7]|[0-9])?\d(\.\d+)?|180(\.0+)?))$/, "Enter Correct Longitude Value!")
  .required("Longitude is required");

/* export const validateImage = Yup.mixed()
  .test("fileType", "Only image files are allowed", (value) => {
    if (value) return true;
    const allowedFormats = ["image/jpeg", "image/png", "image/gif"];

    if (value instanceof File) {
      return allowedFormats.includes(value?.type);
    } else {
      const extension = value?.split(".").pop().toLowerCase();
      return allowedFormats.includes(`image/${extension}`);
    }
  })
  .test("fileSize", "The image must be less than 5MB", (value) => {
    if (!value) return true;
    if (value instanceof File) {
      return value.size <= 5000000; // 5MB in bytes
    }
    return true;
  })
  .test(
    "resolution",
    "The image resolution must be less than or equal to 1000x1000 pixels",
    async (value) => {
      if (!value) return true;
      if (value instanceof File) {
        const file = value;
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        await new Promise((resolve) => {
          img.onload = resolve;
        });

        return img.width <= 1000 && img.height <= 1000;
      }
      return true;
    }
  ); */

export const validateImage = Yup.mixed()
  .test(
    "fileType",
    "Only image files are allowed",
    (value) => {
      if (!value) return true;
      const allowedFormats = ["image/jpg","image/jpeg", "image/png", "image/webp"];

      if (value instanceof File) {
        return allowedFormats.includes(value.type);
      } else {
        const extension = value.split(".").pop().toLowerCase();
        return allowedFormats.includes(`image/${extension}`);
      }
    }
  )

  export const validateReuiredImage = Yup.mixed()
  .test(
    "fileType",
    "Only image files are allowed",
    (value) => {
      if (!value) return true;
      const allowedFormats = ["image/jpg","image/jpeg", "image/png", "image/webp"];

      if (value instanceof File) {
        return allowedFormats.includes(value.type);
      } else {
        const extension = value.split(".").pop().toLowerCase();
        return allowedFormats.includes(`image/${extension}`);
      }
    }
  )
  .required("Image is required");
  
export const validatePdf = Yup.mixed()
  .required("Please select a PDF file")
  .test("fileType", "Invalid file type. Only PDF allowed", (value) => {
    return value && value.type === "application/pdf";
  });

export const validatecontact = Yup.string().required(
  "Contact number is required"
);

export const validateordernumber = Yup.number()
.typeError('Order number must be a number')
.required("Order number is required");
