import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Pagination } from "../../../Utils";
import usePermission from "../../../Hooks/useHasPermisson";
import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InfoIcon from "@mui/icons-material/Info";
import { useLoader } from "../../../Hooks/LoaderContext";

const ContactUsList = () => {
  /**
   * useState...
   */
  const { hasPermission, role } = usePermission();
  const [contactusData, setContactUsData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchContact, setSearchContact] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedMessage, setSelectedMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);

  const openSubjectModal = (subject) => {
    setSelectedSubject(subject);
    setModalTitle("ContactUs Subject");
    setShow(true);
  };

  const openMessageModal = (message) => {
    setSelectedMessage(message);
    setModalTitle("ContactUs Message");
    setShow(true);
  };

  const closeModal = () => {
    setSelectedSubject("");
    setSelectedMessage("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const navigate = useNavigate();
  /**
   * get contactus data from api...
   */
  const getContactUsList = async () => {
    setLoading(true);
    await getNodeApi(
      `general/get-contactus-list?offset=${page}&limit=${limit}&search=${searchContact}`
    )
      .then((res) => {
        setLoading(false);
        if (res) {
          setContactUsData(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setContactUsData([]);
          setTotalRecords(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        setContactUsData([]);
        setTotalRecords(0);
      });
  };

  useEffect(() => {
    getContactUsList(limit, page, searchContact);
  }, [limit, page, searchContact]);

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getContactUsList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getContactUsList(parseInt(event.target.value, 10), 0, "");
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchGurdwara(searchValue);
    } else {
      setSearchGurdwara("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchContact(searchValue);
      } else {
        setSearchContact("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  return (
    <>
      <Helmet>
        <title>Kirtan4u | Contact-Us List</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Contact Us</h3>
                {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Gurdwara</li>
                </ul> */}
              </div>

              <div className="form-custom">
                <input
                  type="text"
                  className="form-control"
                  id="role_search"
                  placeholder="Search Contact Us"
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Subject</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {contactusData.map((data) => (
                              <tr key={data.id}>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>
                                  {data?.subject &&
                                  data?.subject?.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(data.subject, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openSubjectModal(data.subject)
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    data?.subject || "-"
                                  )}
                                </td>
                                <td>
                                  {data?.message &&
                                  data?.message?.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(data?.message, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openMessageModal(data.message)
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    data?.message || "-"
                                  )}
                                </td>
                              </tr>
                            ))}
                            {contactusData.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {selectedSubject}
                        {selectedMessage}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsList;
