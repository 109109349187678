import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import Editor from "../../../../Utils/CkEditor";
import { getNodeApi, postNodeApi } from "../../../../Services/Api/appUtilsApi";
import {
  validateName,
  validatedescription,
} from "../../../../Utils/validation";
import { useLoader } from "../../../../Hooks/LoaderContext";

const KirtanPunjabi = () => {
  const [kirtanPunjabiData, setkirtanPunjabiData] = useState([]);
  const [editorData, setEditorData] = useState([]);
  const { setLoading } = useLoader();

  useEffect(() => {
    const getAboutUsData = async (type) => {
      setLoading(true);
      await getNodeApi("static-content/" + type)
        .then((res) => {
          setLoading(false);
          if (res?.status === 1) {
            setkirtanPunjabiData(res?.data);
            setEditorData(res?.data?.description);
          } else {
            setkirtanPunjabiData([]);
            setEditorData([]);
          }
        })
        .catch((err) => {
          throw err;
        });
    };
    getAboutUsData("what_is_kirtan_pu");
  }, []);

  const initialValues = {
    name: kirtanPunjabiData?.name || "",
    description: kirtanPunjabiData?.description || "",
  };

  const aboutUsValidationSchema = Yup.object().shape({
    name: validateName,
    description: validatedescription,
  });

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | What Is Kirtan Punjabi</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title"> What Is Kirtan Punjabi</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active"> What Is Kirtan Punjabi</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title"> What Is Kirtan Punjabi</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={aboutUsValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const postData = {
                        name: values?.name,
                        description: editorData,
                      };
                      await postNodeApi(
                        "static-content/what_is_kirtan_pu",
                        postData
                      )
                        .then((res) => {
                          setLoading(false);
                          SuccessToast(
                            res?.message ||
                              "Kirtan details updated successfully."
                          );
                          setSubmitting(false);
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting, setFieldValue } =
                        formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Name{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className={`form-control ${
                                          errors.name && touched.name
                                            ? "input-error"
                                            : null
                                        }`}
                                        name="name"
                                        placeholder="Enter what is kirtan detail"
                                      />
                                      <ErrorMessage
                                        name="name"
                                        component="span"
                                        className="error"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <label>
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Editor
                                  data={editorData}
                                  onDataChange={(newData) => {
                                    setFieldValue("description", newData);
                                    setEditorData(newData);
                                  }}
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Save Changes
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KirtanPunjabi;
