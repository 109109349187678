import { customSelectStyles } from "../../../Utils/CSS/select";
import { ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import Select from "react-select";

const SearchingComponent = ({ values, onChange }) => {
  const [searchData, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const axios = require("axios");

  useEffect(() => {
    const getValues = async () => {
      setIsLoading(true);
      await axios
        .get(
          "https://maps.flightmap.io/api/search?fm_token=ce4b73d0-be70-11ee-ae9c-751b59b00a5f&text=delhi&currentlatitude=20.593683&currentlongitude=78.962883&radius=500"
        )
        .then((res) => {
          setIsLoading(false);
          console.log("res: ", res?.data?.data);
          let serchArr = res?.data?.data;
          serchArr = serchArr.map((value) => {
            return {
              label: value.name,
              value: value.name,
              lat: value.lat,
              lng: value.lng,
            };
          });

          if (searchQuery.length >= 3) {
            setSearchData(serchArr);
          } else {
            setSearchData([]);
            setSearchQuery("");
          }
        });
    };
    if (searchQuery.length >= 3) {
      getValues();
    } else if (!searchQuery) {
      setSearchData([]);
    }
  }, [searchQuery]);

  const handleSearchInput = (newValue) => {
    setSearchQuery(newValue);
    if (newValue) {
      setSearchData([]);
    }
  };

  return (
    <>
      <div className="form-group">
        <label>
          Location <span className="text-danger">*</span>
        </label>
        <Select
          id="role_search"
          options={searchData}
          onChange={onChange}
          value={values.location}
          name="location"
          placeholder="Select location"
          styles={customSelectStyles}
          onInputChange={handleSearchInput}
          isLoading={searchQuery && isLoading}
        />
        <ErrorMessage name="location" component="span" className="error" />
      </div>
    </>
  );
};

export default SearchingComponent;
