
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const SuccessToast = (message) =>
  Toast.fire({
    icon: "success",
    title: message,
  });

export const ErrorToast = (message) =>
  Toast.fire({
    icon: "error",
    title: message,
  });

export const WarningToast = (message) =>
  Toast.fire({
    icon: "warning",
    title: message,
  });

export const InfoToast = (message) =>
  Toast.fire({
    icon: "info",
    title: message,
  });

export const Confirmation = (message) =>
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    text: message,
    buttons: true,
    showCancelButton: true,
    allowOutsideClick: false,
    confirmButtonText: "Yes",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    reverseButtons: true,
  });

export const TextArea = async ({denyButtonText}) =>
  await Swal.fire({
    input: "textarea",
    name: "reason",
    inputLabel: "Are You Sure Want To Delete Event?",
    inputPlaceholder: "Type your reason here...",
    inputAttributes: {
      "aria-label": "Type your message here",
    },
    confirmButtonText: "Delete",
    denyButtonText: denyButtonText,
    showDenyButton: true,
    confirmButtonColor: "#3085d6",
    denyButtonColor: "#d33",
    showCancelButton: false,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    preConfirm: (msg) => {
      if (!msg) {
        Swal.showValidationMessage(`Please enter reason`);
      }
    }
  });

export const TextAreaRefund = async ({ denyButtonText = "Cancel", amount }) =>
  Swal.fire({
    title: '<h4 class="my-2">Refund Payment</h4>',
    html: `<input type="number" name="refund_amount" id="refund_amount" min="1" max="${amount}" class="swal2-input" placeholder="Please enter amount" value=${amount}>
    <textarea name="refund_reason" id="refund_reason" class="swal2-input height-5em" placeholder="Please enter reason for refund"></textarea>`,
    confirmButtonText: 'Refund',
    denyButtonText: denyButtonText,
    confirmButtonColor: "#dc3741",
    denyButtonColor: "#3085d6",
    showCancelButton: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    focusConfirm: false,
    preConfirm: () => {
      const refund_amount = Swal.getPopup().querySelector('#refund_amount').value
      const refund_reason = Swal.getPopup().querySelector('#refund_reason').value
      if (!refund_amount && !refund_reason) {
        Swal.showValidationMessage(`Please enter amount and refund reason`);
      } else if (!refund_amount) {
        Swal.showValidationMessage(`Please enter amount`);
      } else if (!refund_reason) {
        Swal.showValidationMessage(`Please enter refund reason`);
      } else {
        if (refund_amount > amount) {
          Swal.showValidationMessage(`Refund amount is exceeded original payment amount`);
        }
      }
      return { amount: refund_amount, reason: refund_reason }
    }
  }).then((result) => {
    return result;
  })

export const ApproveComment = async ({ denyButtonText = "Cancel", amount }) =>
  Swal.fire({
    title: '<h4 class="my-2">Approve Credit Request</h4>',
    html: `<input type="number" name="approve_amount" id="approve_amount" min="1" max="${amount}" class="swal2-input" placeholder="Please enter amount" value=${amount}>
    <textarea name="approve_reason" id="approve_reason" class="swal2-input height-5em" placeholder="Type your Comment here.."></textarea>`,
    confirmButtonText: 'Approve',
    denyButtonText: denyButtonText,
    confirmButtonColor: "#15d57c",
    denyButtonColor: "#3085d6",
    showCancelButton: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    focusConfirm: false,
    preConfirm: () => {
      const approve_amount = Swal.getPopup().querySelector('#approve_amount').value
      const approve_reason = Swal.getPopup().querySelector('#approve_reason').value
      if (!approve_amount && !approve_reason) {
        Swal.showValidationMessage(`Please enter amount and approve reason`);
      } else if (!approve_amount) {
        Swal.showValidationMessage(`Please enter amount`);
      } else if (approve_amount <= 0) {
        Swal.showValidationMessage(`Amount must be grater than 0`);
      }
      else if (!approve_reason) {
        Swal.showValidationMessage(`Please enter approve reason`);
      }
      return { amount: approve_amount, reason: approve_reason }
    }
  }).then((result) => {
    return result;

  });

export const ServiceCharge = async ({ denyButtonText = "Cancel", service_charge, type }) =>
  Swal.fire({
    title: '<h4 class="my-2">Update Service Charge</h4>',
    html: `<select name="type" id="type" class="swal2-input">
    <option value="">Please select service charge type</option>
    <option value="percentage" ${type == 'percentage' ? 'selected' : ''}>Percentage</option>
    <option value="amount" ${type == 'amount' ? 'selected' : ''}>Amount</option>
  </select>
    <input type="number" name="service_charge" id="service_charge" min="1" class="swal2-input" placeholder="Please enter service charge" value=${service_charge}>`,
    confirmButtonText: 'Update Service Charge',
    denyButtonText: denyButtonText,
    confirmButtonColor: "#15d57c",
    denyButtonColor: "#3085d6",
    showCancelButton: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    focusConfirm: false,
    preConfirm: () => {
      const service_charge = Swal.getPopup().querySelector('#service_charge').value
      const type = Swal.getPopup().querySelector('#type').value
      if (!service_charge && !type) {
        Swal.showValidationMessage(`Service charge type and service charge is required`);
      } else if (!type) {
        Swal.showValidationMessage(`Please select service charge type`);
      } else if (!service_charge) {
        Swal.showValidationMessage(`Please enter service charge`);
      }
      return { service_charge: service_charge, type: type }
    }
  }).then((result) => {
    return result;
  })

export const AddFundPopup = async ({ denyButtonText = "Cancel", label }) => (
  Swal.fire({
    title: `<h4 class="my-2">${label}</h4>`,
    html: `<input type="number" name="fund_amount" id="fund_amount" min="1" class="swal2-input" placeholder="Please enter amount">
      <textarea name="notes" id="notes" class="swal2-input height-5em" placeholder="Please enter notes"></textarea>`,
    confirmButtonText: label,
    denyButtonText: denyButtonText,
    confirmButtonColor: "#15d57c",
    denyButtonColor: "#3085d6",
    showCancelButton: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    focusConfirm: false,
    preConfirm: () => {
      const fund_amount = Swal.getPopup().querySelector('#fund_amount').value
      const notes = Swal.getPopup().querySelector('#notes').value
      if (!fund_amount && !notes) {
        Swal.showValidationMessage(`Please enter amount and notes`);
      } else if (!fund_amount) {
        Swal.showValidationMessage(`Please enter amount`);
      } else if (!notes) {
        Swal.showValidationMessage(`Please enter notes`);
      }

      return { amount: fund_amount, notes: notes }
    }
  }).then((result) => {
    return result;
  })
);

export const RejectTextArea = async ({ denyButtonText = "Cancel" }) =>
  await Swal.fire({
    input: "textarea",
    name: "reason",
    inputLabel: "Reject reason",
    inputPlaceholder: "Type your reason here...",
    inputAttributes: {
      "aria-label": "Type your message here",
    },
    confirmButtonText: "Reject",
    denyButtonText: denyButtonText,
    showDenyButton: true,
    confirmButtonColor: "#dc3741",
    denyButtonColor: "#3085d6",
    showCancelButton: false,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    preConfirm: (msg) => {
      if (!msg) {
        Swal.showValidationMessage(`Please enter reason`);
      }
    }
  });

  export const CloseCredit = async ({ denyButtonText = "Cancel", amount, currency }) =>
  Swal.fire({
    title: '<h4 class="my-2">Close Credit Request</h4>',
    html: `<div>Amount: <span class="currency-type-text"><b>${currency}</b> </span>${amount}</div>
    <textarea name="approve_reason" id="approve_reason" class="swal2-input height-5em" placeholder="Type your reason here.."></textarea>`,
    confirmButtonText: 'Close',
    denyButtonText: denyButtonText,
    confirmButtonColor: "#15d57c",
    denyButtonColor: "#3085d6",
    showCancelButton: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    reverseButtons: true,
    focusConfirm: false,
    preConfirm: () => {
      const approve_reason = Swal.getPopup().querySelector('#approve_reason').value
      if (!approve_reason) {
        Swal.showValidationMessage(`Please enter close credit reason`);
      }
      return { amount: amount, reason: approve_reason }
    }
  }).then((result) => {
    return result;

  });