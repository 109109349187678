import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../Utils/SweetAlert";
import { Pagination } from "../../../Utils";
import StatusButton from "../../../Utils/StatusButton";
import usePermission from "../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../Utils/constance/options";
import {
  deleteNodeApi,
  getNodeApi,
  putNodeApi,
} from "../../../Services/Api/appUtilsApi";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InfoIcon from "@mui/icons-material/Info";
import { useLoader } from "../../../Hooks/LoaderContext";
import NameInitial from "../../../Utils/NameInitial";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { customFilterStyle } from "../../../Utils/CSS/filter";

const GurdwaraList = () => {
  /**
   * useState...
   */
  const { hasPermission, role } = usePermission();
  const [gurdwaraData, setGurdwaraData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchGurdwara, setSearchGurdwara] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [show, setShow] = useState(false);
  const { isLoading, setLoading } = useLoader();
  const [timer, setTimer] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [countryId, setCountryId] = useState("");

  const openDescriptionModal = (data) => {
    setSelectedDescription(data.description);
    setModalTitle(data.name);
    setShow(true);
  };

  const openAddressModal = (data) => {
    setSelectedAddress(
      `${data?.address?.address},${data?.address?.city},${data?.address?.state},${data?.address?.country},${data?.address?.zipcode}`
    );
    setModalTitle(data.name);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedDescription("");
    setSelectedAddress("");
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const navigate = useNavigate();
  /**
   * get Gurdwara data from api...
   */
  const getGurdwara = async () => {
    setLoading(true);
    await getNodeApi(
      `gurudwara?offset=${page}&limit=${limit}&search=${searchGurdwara}&country_id=${countryId}&state_id=${stateId}`
    )
      .then((res) => {
        setLoading(false);
        if (res) {
          setGurdwaraData(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setGurdwaraData([]);
          setTotalRecords(0);
        }
      })
      .catch((error) => {
        setLoading(false);
        setGurdwaraData([]);
        setTotalRecords(0);
      });
  };

  const getCountry = async () => {
    setLoading(true);
    await getNodeApi("../general/get-all-countries?type=event").then((res) => {
      setLoading(false);
      let countryArr = res?.data?.countries || [];
      if (countryArr.length > 0) {
        countryArr = countryArr.map((value) => {
          return {
            label: value.name,
            value: value._id,
          };
        });
        countryArr.unshift({ label: "All", value: "" });
        setCountryList(countryArr);
      } else {
        // ErrorToast("No Results.");
        setCountryList([]);
      }
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    const getState = async () => {
      setLoading(true);
      await getNodeApi(
        `../general/get-all-states-by-country/${countryId}`
      ).then((res) => {
        setLoading(false);
        let stateArr = res?.data?.states || [];
        if (stateArr.length > 0) {
          stateArr = stateArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
            };
          });
          stateArr.unshift({ label: "All", value: "" });
          setStateList(stateArr);
        } else {
          // ErrorToast("No Results.");
          setStateList([]);
        }
      });
    };
    if (countryId) {
      getState(countryId);
    }
  }, [countryId]);

  useEffect(() => {
    getGurdwara(limit, page, searchGurdwara, countryId, stateId);
  }, [limit, page, searchGurdwara, countryId, stateId]);

  const deleteGurdwara = (id) => {
    Confirmation("Want to delete gurdwara?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await deleteNodeApi(`gurudwara/`, { id })
          .then((res) => {
            if(res?.status){
              SuccessToast(res?.message || "Gurdwara Deleted.");
              getGurdwara(limit, page, "");
            }else{
              ErrorToast(res?.message );
            }
          })
          .catch((err) => {
            if (typeof err?.response?.message !== "undefined") {
              ErrorToast(err?.response?.message || "something went wrong");
            } else {
              ErrorToast(err?.message || "something went wrong");
            }
          });
      }
    });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    await getGurdwara(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    await getGurdwara(parseInt(event.target.value, 10), 0, "");
  };

  /*const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2) {
      setSearchGurdwara(searchValue);
    } else {
      setSearchGurdwara("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchGurdwara(searchValue);
      } else {
        setSearchGurdwara("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const handleGurdwaraStatusChange = async (postObj) => {
    try {
      const res = await putNodeApi("gurudwara/change-status", postObj);
      getGurdwara(limit, page);
      return res;
    } catch (err) {
      throw err;
    }
  };

  return (
    <>
      <Helmet>
        <title>Kirtan4u | Gurdwara List</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Gurdwara</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Gurdwara</li>
                </ul>
              </div>
              {role === RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link
                    to="/admin/add-gurdwara"
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Add Gurdwara
                  </Link>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.GURDWARAADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-gurdwara"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add Gurdwara
                      </Link>
                    </div>
                  )}
                </>
              )}

              {/* <div className="form-custom">
                <input
                  type="text"
                  className="form-control"
                  id="role_search"
                  placeholder="Search Gurdwara"
                  onChange={handleSearchInputChange}
                />
              </div> */}
            </div>
            <div className="col-auto">
              <Formik
                initialValues={{
                  countryId: "",
                  stateId: "",
                }}
                validationSchema={Yup.object().shape({
                  stateId: Yup.string(),
                  countryId: Yup.string(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-custom">
                      <div className="me-2 mt-2" style={{ width: "20%" }}>
                        <Select
                          styles={customFilterStyle}
                          options={countryList}
                          name="countryId"
                          placeholder="Select country"
                          onChange={(selectedOption) => {
                            setCountryId(selectedOption?.value || "");
                          }}
                        />
                      </div>
                      <div className="me-2 mt-2" style={{ width: "20%" }}>
                        <Select
                          styles={customFilterStyle}
                          options={stateList}
                          name="stateId"
                          placeholder="Select state"
                          onChange={(selectedOption) => {
                            setStateId(selectedOption?.value || "");
                          }}
                        />
                      </div>
                      <div className="form-custom">
                        <input
                          type="text"
                          className="form-control"
                          id="role_search"
                          placeholder="Search Gurdwara"
                          onChange={handleSearchInputChange}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          <th>Telephone</th>
                          <th>Description</th>
                          <th>Address</th>
                          <th>Image</th>
                          {role === RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.GURDWARAEDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}

                          {role === RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.GURDWARAEDIT ||
                                  hasPermission(RolePermission.GURDWARADELETE)
                              ) && <th>Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {gurdwaraData.map((data) => (
                              <tr key={data.id}>
                                <td>{data.name}</td>
                                <td>{data.telephone}</td>
                                <td>
                                  {data?.description &&
                                  data?.description?.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(data.description, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openDescriptionModal(data)
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    data?.description || "-"
                                  )}
                                </td>
                                <td>
                                  {data?.address &&
                                  data?.address?.address?.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(
                                          data?.address?.address,
                                          20
                                        )}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() => openAddressModal(data)}
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    `${data?.address?.address},${data?.address?.city},${data?.address?.state},${data?.address?.country},${data?.address?.zipcode}` ||
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {data.image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_IMAGE_URL}${data?.image}`}
                                      alt="img"
                                      className="list-image-news"
                                    />
                                  ) : (
                                    <NameInitial
                                      name={data.name}
                                      type="event"
                                    />
                                  )}
                                </td>

                                {role === RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="Gurudwara"
                                    role={data}
                                    changeActive={handleGurdwaraStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(
                                      RolePermission.GURDWARAEDIT
                                    ) && (
                                      <StatusButton
                                        module="Gurudwara"
                                        role={data}
                                        changeActive={
                                          handleGurdwaraStatusChange
                                        }
                                      />
                                    )}
                                  </>
                                )}

                                <td>
                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-gurdwara/${data?._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.GURDWARAEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-gurdwara/${data?._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role === RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() => {
                                        deleteGurdwara(data?._id);
                                      }}
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.GURDWARADELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() => {
                                            deleteGurdwara(data?._id);
                                          }}
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {gurdwaraData.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {selectedDescription}
                        {selectedAddress}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GurdwaraList;
