import React from "react";
import PropTypes from "prop-types";
import { getEventImage } from "../Services/Api/helper";

function NameInitial({ name, type, className, style }) {
  switch (type) {
    case "event": {
      return (
        <span
          /*style={{
            backgroundColor: "#a2f1f7",
            color: "#7638FF",
            border: "1px solid #362ead",
            fontSize: "20px",
            width: "50px",
            height: "50px",
          }}*/
          className={`d-flex align-items-center justify-content-center rounded-circle image-profile-blank default-image ${className}`}
          style={style}
        >
          {getEventImage(name)}
        </span>
      );
    }

    default: {
      return (
        <span
          /* style={{
            backgroundColor: "#a2f1f7",
            color: "#7638FF",
            border: "1px solid #362ead",
            fontSize: "20px",
            width: "50px",
            height: "50px",
          }}*/
          className={`d-flex align-items-center justify-content-center rounded-circle image-profile-blank default-image ${className}`}
          style={style}
        >
          {getEventImage(name)}
        </span>
      );
    }
  }
}

NameInitial.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
};

NameInitial.defaultProps = {
  name: "LI",
  type: "event",
};

export default NameInitial;
