import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Logout } from "../../routes/AdminRoutes";
import PersonIcon from "@mui/icons-material/Person";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import MosqueIcon from "@mui/icons-material/Mosque";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import AppsIcon from "@mui/icons-material/Apps";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import usePermission from "../../Hooks/useHasPermisson";
import { RolePermission } from "../../Utils/constance/options";

const AdminSidebar = ({ setOpenSidebar }) => {
  const location = useLocation();
  let path = location.pathname;
  const { hasPermission, role } = usePermission();
  // const [hideShow, setHideShow] = useState("none");
  const [userManagementHideShow, setUserManagementHideShow] = useState("none");
  const [customerHideShow, setCustomerHideShow] = useState("none");
  const [arrowCustomerWallet, setArrowCustomerWallet] = useState("menu-arrow");
  const [customerWalletHideShow, setCustomerWalletHideShow] = useState("none");
  const [country, setCountry] = useState("none");
  const [appuser, setAppUser] = useState("none");
  const [event, setEvent] = useState("none");
  const [gurdwara, setGurdwara] = useState("none");
  const [aboutus, setAboutUs] = useState("none");

  const [arrowUserManagement, setArrowUserManagement] = useState("menu-arrow");
  const [arrowCountry, setArrowCountry] = useState("menu-arrow");
  const [arrowAppUser, setArrowAppUser] = useState("menu-arrow");
  const [arrowEvent, setArrowEvent] = useState("menu-arrow");
  const [arrowGurdwara, setArrowGurdwara] = useState("menu-arrow");
  const [arrowAboutUs, setArrowAboutUs] = useState("menu-arrow");
  const [display, setDisplay] = useState("d-block");
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setArrowCustomerWallet(
      customerWalletHideShow == "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
    setArrowUserManagement(
      userManagementHideShow == "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
    setArrowCountry(
      country == "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
    setArrowAppUser(
      appuser == "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
    setArrowEvent(
      event === "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
    setArrowGurdwara(
      gurdwara === "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
    setArrowAboutUs(
      aboutus === "block" ? "menu-arrow menu-arrow-down" : "menu-arrow"
    );
  });

  useEffect(() => {
    if (path == "/admin/sub-admin" || path == "/admin/role") {
      setUserManagementHideShow("block");
    }

    if (
      path == "/admin/country" ||
      path == "/admin/state" ||
      path == "/admin/city"
    ) {
      setCountry("block");
    }

    if (path == "/admin/jathas") {
      setAppUser("block");
    }

    if (path == "/admin/event" || path == "/admin/rating-review") {
      setEvent("block");
    }

    if (path == "/admin/gurdwara" || path == "/admin/requested-gurdwara") {
      setGurdwara("block");
    }

    if (
      path == "/admin/about-us" ||
      path == "/admin/terms-of-use" ||
      path == "/admin/what-is-kirtan" ||
      path == "/admin/sikhism-basics" ||
      path == "/admin/onbording-list" ||
      path == "/admin/legal" ||
      path == "/admin/privacy-policy" ||
      path == "/admin/help-list"
    ) {
      setAboutUs("block");
    }
  }, []);

  useEffect(() => {
    if (setOpenSidebar) {
      if (path == "/admin/sub-admin" || path == "/admin/role") {
        setUserManagementHideShow("block");
      }
    }
  }, []);

  const menuclick = (value) => {
    setCustomerHideShow("none");
    // setHideShow("none");
    setCustomerWalletHideShow("none");
    setUserManagementHideShow("none");
    setCountry("none");
    setAppUser("none");
    setEvent("none");
    setAboutUs("none");

    if (value == "customer") {
      setCustomerHideShow("block");
    }
    if (value == "usermanagement") {
      setUserManagementHideShow("block");
    }
    if (value == "country") {
      setCountry("block");
    }
    if (value == "appuser") {
      setAppUser("block");
    }
    if (value == "event") {
      setEvent("block");
    }
    if (value == "gurdwara") {
      setGurdwara("block");
    }
    if (value == "aboutus") {
      setAboutUs("block");
    }
  };

  return (
    <div
      className={`sidebar ${display}`}
      id="sidebar"
      style={
        setOpenSidebar == true
          ? { marginLeft: "0px" }
          : { marginLeft: "-225px" }
      }
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Main</span>
            </li>

            <li onClick={() => menuclick("dashboard")}>
              <NavLink
                to="/admin/dashboard"
                onClick={() => {
                  if (width <= 500) {
                    setDisplay("d-none");
                  }
                }}
              >
                <i data-feather="home" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-home"
                >
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                  <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                <span>Dashboard</span>
              </NavLink>
            </li>

            {role == RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setUserManagementHideShow(
                          userManagementHideShow === "none" ? "block" : "none"
                        );
                      }}
                    >
                      <i data-feather="clipboard" />
                      <i className="fas fa-users"></i>
                      <span>Sub Admin</span>{" "}
                      <span className={arrowUserManagement} />
                    </div>
                  </Link>
                  <ul style={{ display: userManagementHideShow }}>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("usermanagement");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/sub-admin"
                      >
                        Sub Admin
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("usermanagement");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/role"
                      >
                        Role Management
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                {(hasPermission(RolePermission.USERLIST) ||
                  hasPermission(RolePermission.ROLELIST)) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setUserManagementHideShow(
                            userManagementHideShow === "none" ? "block" : "none"
                          );
                        }}
                      >
                        <i data-feather="clipboard" />
                        <i className="fas fa-users"></i>
                        <span>Sub Admin</span>{" "}
                        <span className={arrowUserManagement} />
                      </div>
                    </Link>
                    <ul style={{ display: userManagementHideShow }}>
                      {hasPermission(RolePermission.USERLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("usermanagement");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/sub-admin"
                          >
                            Sub Admin
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.ROLELIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("usermanagement");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/role"
                          >
                            Role Management
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setAppUser(appuser === "none" ? "block" : "none");
                      }}
                    >
                      <i data-feather="clipboard" />
                      <GroupAddIcon />
                      <span>App User</span> <span className={arrowAppUser} />
                    </div>
                  </Link>
                  <ul style={{ display: appuser }}>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("appuser");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/jathas"
                      >
                        Jatha or Parchaarak
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        onClick={() => {
                          menuclick("appuser");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/parchaarak"
                      >
                        Parchaarak
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
              </>
            ) : (
              <>
                {hasPermission(RolePermission.APPUSERLIST) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setAppUser(appuser === "none" ? "block" : "none");
                        }}
                      >
                        <i data-feather="clipboard" />
                        <GroupAddIcon />
                        <span>App User</span> <span className={arrowAppUser} />
                      </div>
                    </Link>
                    <ul style={{ display: appuser }}>
                      {hasPermission(RolePermission.APPUSERLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("appuser");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/jathas"
                          >
                            Jatha or Parchaarak
                          </NavLink>
                        </li>
                      )}
                      {/* {hasPermission(RolePermission.APPUSERLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("appuser");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/parchaarak"
                          >
                            Parchaarak
                          </NavLink>
                        </li>
                      )} */}
                    </ul>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <>
                <li
                  onClick={() => {
                    menuclick("normaluser");
                    if (width <= 500) {
                      setDisplay("d-none");
                    }
                  }}
                >
                  <NavLink to="/admin/normal-user">
                    <i data-feather="user" />
                    <PersonIcon />
                    <span>Normal User</span>
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                {hasPermission(RolePermission.APPUSERLIST) && (
                  <li
                    onClick={() => {
                      menuclick("normaluser");
                      if (width <= 500) {
                        setDisplay("d-none");
                      }
                    }}
                  >
                    <NavLink to="/admin/normal-user">
                      <i data-feather="user" />
                      <PersonIcon />
                      <span>Normal User</span>
                    </NavLink>
                  </li>
                )}
              </>
            )}

            {role === RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setGurdwara(gurdwara === "none" ? "block" : "none");
                      }}
                    >
                      <i data-feather="pie-chart" />
                      <MosqueIcon />
                      <span> Gurdwara </span> <span className={arrowGurdwara} />
                    </div>
                  </Link>
                  <ul style={{ display: gurdwara }}>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("gurdwara");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/gurdwara"
                      >
                        Gurdwara
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("gurdwara");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/requested-gurdwara"
                      >
                        Requested Gurdwara
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                {(hasPermission(RolePermission.GURDWARALIST) ||
                  hasPermission(RolePermission.REQUESTGURDWARALIST)) && (
                    <li className="submenu">
                      <Link to="#">
                        <div
                          onClick={() => {
                            setGurdwara(gurdwara === "none" ? "block" : "none");
                          }}
                        >
                          <i data-feather="pie-chart" />
                          <MosqueIcon />
                          <span>Gurdwara</span> <span className={arrowGurdwara} />
                        </div>
                      </Link>
                      <ul style={{ display: gurdwara }}>
                        {hasPermission(RolePermission.GURDWARALIST) && (
                          <li>
                            <NavLink
                              onClick={() => {
                                menuclick("gurdwara");
                                if (width <= 500) {
                                  setDisplay("d-none");
                                }
                              }}
                              to="/admin/gurdwara"
                            >
                              Gurdwara
                            </NavLink>
                          </li>
                        )}
                        {hasPermission(RolePermission.REQUESTGURDWARALIST) && (
                          <li>
                            <NavLink
                              onClick={() => {
                                menuclick("gurdwara");
                                if (width <= 500) {
                                  setDisplay("d-none");
                                }
                              }}
                              to="/admin/requested-gurdwara"
                            >
                              Requested Gurdwara
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
              </>
            )}

            {role === RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setEvent(event === "none" ? "block" : "none");
                      }}
                    >
                      <i data-feather="pie-chart" />
                      <EventAvailableIcon />
                      <span> Event </span> <span className={arrowEvent} />
                    </div>
                  </Link>
                  <ul style={{ display: event }}>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("event");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/event"
                      >
                        Event
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        onClick={() => {
                          menuclick("event");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/rating-review"
                      >
                        Rating & Review
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
              </>
            ) : (
              <>
                {(hasPermission(RolePermission.EVENTLIST) ||
                  hasPermission(RolePermission.RATINGLIST)) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setEvent(event === "none" ? "block" : "none");
                        }}
                      >
                        <i data-feather="pie-chart" />
                        <EventAvailableIcon />
                        <span>Event</span> <span className={arrowEvent} />
                      </div>
                    </Link>
                    <ul style={{ display: event }}>
                      {hasPermission(RolePermission.EVENTLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("event");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/event"
                          >
                            Event
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.RATINGLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("event");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/rating-review"
                          >
                            Rating & Review
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <li
                onClick={() => {
                  menuclick("newsbulletins");
                  if (width <= 500) {
                    setDisplay("d-none");
                  }
                }}
              >
                <NavLink to="/admin/news-bulletins">
                  <i data-feather="news-bulletins" />
                  <AnnouncementIcon />
                  <span>News Bulletins</span>
                </NavLink>
              </li>
            ) : (
              <>
                {hasPermission(RolePermission.NEWSLIST) && (
                  <li
                    onClick={() => {
                      menuclick("newsbulletins");
                      if (width <= 500) {
                        setDisplay("d-none");
                      }
                    }}
                  >
                    <NavLink to="/admin/news-bulletins">
                      <i data-feather="news-bulletins" />
                      <AnnouncementIcon />
                      <span>News Bulletins</span>
                    </NavLink>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <li
                onClick={() => {
                  menuclick("site-settings");
                  if (width <= 500) {
                    setDisplay("d-none");
                  }
                }}
              >
                <NavLink to="/admin/site-settings">
                  <i data-feather="user" />
                  <ManageAccountsIcon />
                  <span>Site Settings</span>
                </NavLink>
              </li>
            ) : (
              <>
                {hasPermission(RolePermission.SETTINGVIEW) && (
                  <li
                    onClick={() => {
                      menuclick("site-settings");
                      if (width <= 500) {
                        setDisplay("d-none");
                      }
                    }}
                  >
                    <NavLink to="/admin/site-settings">
                      <i data-feather="user" />
                      <ManageAccountsIcon />
                      <span>Site Settings</span>
                    </NavLink>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setCountry(country === "none" ? "block" : "none");
                      }}
                    >
                      <i data-feather="pie-chart" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-pie-chart"
                      >
                        <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                        <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                      </svg>
                      <span> Static Data</span>{" "}
                      <span className={arrowCountry} />
                    </div>
                  </Link>
                  <ul style={{ display: country }}>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("country");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/country"
                      >
                        Country
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("country");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/state"
                      >
                        State
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        onClick={() => {
                          menuclick("country");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/city"
                      >
                        City
                      </NavLink>
                    </li> */}
                  </ul>
                </li>
              </>
            ) : (
              <>
                {(hasPermission(RolePermission.COUNTRYLIST) ||
                  hasPermission(RolePermission.STATELIST) ||
                  hasPermission(RolePermission.CITYLIST)) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setCountry(country === "none" ? "block" : "none");
                        }}
                      >
                        <i data-feather="pie-chart" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-pie-chart"
                        >
                          <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                          <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                        </svg>
                        <span> Static Data</span>{" "}
                        <span className={arrowCountry} />
                      </div>
                    </Link>
                    <ul style={{ display: country }}>
                      {hasPermission(RolePermission.COUNTRYLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("country");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/country"
                          >
                            Country
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.STATELIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("country");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/state"
                          >
                            State
                          </NavLink>
                        </li>
                      )}
                      {/* {hasPermission(RolePermission.STATELIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("country");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/city"
                          >
                            City
                          </NavLink>
                        </li>
                      )} */}
                    </ul>
                  </li>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <>
                <li className="submenu">
                  <Link to="#">
                    <div
                      onClick={() => {
                        setAboutUs(aboutus === "none" ? "block" : "none");
                      }}
                    >
                      <i data-feather="pie-chart" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-pie-chart"
                      >
                        <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                        <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                      </svg>
                      <span>Static Content </span>{" "}
                      <span className={arrowAboutUs} />
                    </div>
                  </Link>
                  <ul style={{ display: aboutus }}>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/about-us"
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/about-us-punjabi"
                      >
                        About Us (Punjabi)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/terms-of-use"
                      >
                        Terms Of Use
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/what-is-kirtan"
                      >
                        What Is Kirtan
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/what-is-kirtan-punjabi"
                      >
                        What Is Kirtan (Punjabi)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/sikhism-basics"
                      >
                        Sikhism Basics
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/sikhism-basics-punjabi"
                      >
                        Sikhism Basics (Punjabi)
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/onbording-list"
                      >
                        Onbording
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/legal"
                      >
                        Legal
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/privacy-policy"
                      >
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={() => {
                          menuclick("aboutus");
                          if (width <= 500) {
                            setDisplay("d-none");
                          }
                        }}
                        to="/admin/help-list"
                      >
                        Help
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                {(hasPermission(RolePermission.ABOUTUSVIEWUPDATE) ||
                  hasPermission(RolePermission.ABOUTUSPUNJABIVIEWUPDATE) ||
                  hasPermission(RolePermission.TERMSOFUSEVIEWUPDATE) ||
                  hasPermission(RolePermission.WHATISKIRTANVIEWUPDATE) ||
                  hasPermission(RolePermission.WHATISKIRTANPUNJABIVIEWUPDATE) ||
                  hasPermission(RolePermission.SIKHISMBASICSVIEWUPDATE) ||
                  hasPermission(RolePermission.SIKHISMBASICSPUNJABIVIEWUPDATE) ||
                  hasPermission(RolePermission.ONBORDINGBASICSVIEWUPDATE) ||
                  hasPermission(RolePermission.ONBORDINGLIST) ||
                  hasPermission(RolePermission.PRIVACYPOLICYVIEWUPDATE) ||
                  hasPermission(RolePermission.HELPLIST)) && (
                  <li className="submenu">
                    <Link to="#">
                      <div
                        onClick={() => {
                          setAboutUs(aboutus === "none" ? "block" : "none");
                        }}
                      >
                        <i data-feather="pie-chart" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-pie-chart"
                        >
                          <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                          <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                        </svg>
                        <span>Static Content</span>{" "}
                        <span className={arrowAboutUs} />
                      </div>
                    </Link>
                    <ul style={{ display: aboutus }}>
                      {hasPermission(RolePermission.ABOUTUSVIEWUPDATE) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/about-us"
                          >
                            About Us
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.ABOUTUSPUNJABIVIEWUPDATE) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/about-us-punjabi"
                          >
                            About Us (Punjabi)
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.TERMSOFUSEVIEWUPDATE) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/terms-of-use"
                          >
                            Terms Of Use
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.WHATISKIRTANVIEWUPDATE) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/what-is-kirtan"
                          >
                            What Is kirtan
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.WHATISKIRTANPUNJABIVIEWUPDATE) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/what-is-kirtan-punjabi"
                          >
                            What Is kirtan (Punjabi)
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(
                        RolePermission.SIKHISMBASICSVIEWUPDATE
                      ) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/sikhism-basics"
                          >
                            Sikhism Basics
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(
                        RolePermission.SIKHISMBASICSPUNJABIVIEWUPDATE
                      ) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/sikhism-basics-punjabi"
                          >
                            Sikhism Basics (Punjabi)
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.ONBORDINGLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/onbording-list"
                          >
                            Onbording
                          </NavLink>
                        </li>
                      )}
                      {/* {hasPermission(RolePermission.LEGALVIEWUPDATE) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/legal"
                          >
                            Legal
                          </NavLink>
                        </li>
                      )} */}
                      {hasPermission(
                        RolePermission.PRIVACYPOLICYVIEWUPDATE
                      ) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/privacy-policy"
                          >
                            Privacy Policy
                          </NavLink>
                        </li>
                      )}
                      {hasPermission(RolePermission.HELPLIST) && (
                        <li>
                          <NavLink
                            onClick={() => {
                              menuclick("aboutus");
                              if (width <= 500) {
                                setDisplay("d-none");
                              }
                            }}
                            to="/admin/help-list"
                          >
                            Help
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </>
            )}

            <li
              onClick={() => {
                menuclick("sponsor");
                if (width <= 500) {
                  setDisplay("d-none");
                }
              }}
            >
              <NavLink to="/admin/sponser-list">
                <i data-feather="user" />
                <PersonIcon />
                <span>Sponsor</span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                menuclick("donation");
                if (width <= 500) {
                  setDisplay("d-none");
                }
              }}
            >
              <NavLink to="/admin/donation-list">
                <i data-feather="donation" />
                <VolunteerActivismIcon />
                <span>Donation</span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                menuclick("contactus");
                if (width <= 500) {
                  setDisplay("d-none");
                }
              }}
            >
              <NavLink to="/admin/contact-us-list">
                <i data-feather="contact-us" />
                <ContactPhoneIcon />
                <span>Contact Us</span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                menuclick("otherapps");
                if (width <= 500) {
                  setDisplay("d-none");
                }
              }}
            >
              <NavLink to="/admin/otherapps-list">
                <i data-feather="otherapps" />
                <AppsIcon />
                <span>Other Apps</span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                menuclick("profile");
                if (width <= 500) {
                  setDisplay("d-none");
                }
              }}
            >
              <NavLink to="/admin/profile">
                <i data-feather="user" />
                <ManageAccountsIcon />
                <span>Profile</span>
              </NavLink>
            </li>

            <li
              onClick={() => {
                menuclick("logout");
                if (width <= 500) {
                  setDisplay("d-none");
                }
              }}
            >
              <NavLink onClick={Logout} to="/admin/logout">
                <i data-feather="log-out" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AdminSidebar;
