import React from "react";
import { Confirmation, SuccessToast, ErrorToast } from "./SweetAlert";

const StatusButton = ({ role, changeActive, module }) => {
  const handleStatusChange = (statusText) => {
    Confirmation(statusText).then(async (result) => {
      if (result.isConfirmed) {
        const postObj = {
          id: role._id,
          is_active: !role.is_active,
        };
        try {
          const res = await changeActive(postObj);
          // SuccessToast(res?.data.message || "Status Updated");
          SuccessToast(res.message || "Status Updated");
        } catch (err) {
          if (typeof err.response.data.message !== "undefined") {
            // ErrorToast(err.response.data.message || "Server Error!!");
            ErrorToast(err.response.message || "Server Error!!");
          } else {
            ErrorToast(err?.message || "Server Error!!");
          }
        }
      }
    });
  };

  return (
    <td>
      {role?.is_active === false && (
        <button
          className="btn btn-sm btn-block btn-outline-danger"
          onClick={() => handleStatusChange("Want to activate?")}
        >
          {module === "rating" ? "Not Show" : "Inactive"}
        </button>
      )}
      {role?.is_active === true && (
        <button
          className="btn btn-sm btn-block btn-outline-success"
          onClick={() => handleStatusChange("Want to deactivate?")}
        >
          {module === "rating" ? "Show" : "Active"}
        </button>
      )}
    </td>
  );
};

export default StatusButton;
