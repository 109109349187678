import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";
import { getImageUrl } from "../../../Services/Api/fileUpload";
import {
  validateImage,
  validateUrl,
  validatedescription,
  validateTitle,
  validateordernumber,
  validateReuiredImage,
} from "../../../Utils/validation";

const EditSponser = () => {
  const { id } = useParams();
  const [sponserData, setSponserData] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  const { setLoading } = useLoader();
  const navigate = useNavigate();

  const EditSponserInitialValues = {
    title: sponserData?.title || "",
    description: sponserData?.description || "",
    image: sponserData?.image || "",
    order_number: sponserData?.order_number || "",
    // image:"",
    website: sponserData?.website || "",
  };

  const EditSponserValidationSchema = Yup.object().shape({
    title: validateTitle,
    description: validatedescription,
    // image: Yup.string().nullable().required("Please upload image "),
    image: validateReuiredImage,
    website: validateUrl,
    order_number: validateordernumber
  });

  useEffect(() => {
    const getSponserById = async () => {
      setLoading(true);
      await getNodeApi(`sponsors/${id}`)
        .then((res) => {
          setLoading(false);
          setSponserData(res?.data);
        })
        .catch((err) => {
          throw err;
        });
    };
    getSponserById(id);
  }, [id]);

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return;

    if (!file.type.startsWith("image/")) {
      e.currentTarget.value = null;
      setSelectedImage("");
      setFileError("Only image files are allowed.");
      setFieldValue("image", ""); // Reset image field in form values
      return;
    }

    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Sponser</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Sponser</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/sponser-list">Sponser</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Sponser</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/sponser-list" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Sponser Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={EditSponserInitialValues}
                    validationSchema={EditSponserValidationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);
                      const logoFile = values?.image;

                      let img = values.image;

                      if (typeof logoFile === "object") {
                        const formData = new FormData();
                        formData.append("image", logoFile);
                        formData.append("module", "SPONSOR");
                        try {
                          const res = await getImageUrl(formData);
                          img = res.data.data[0].s3_key;
                        } catch (error) {
                          if (error) {
                            ErrorToast(
                              error?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast("something went wrong");
                          }
                        }
                      }

                      const postObj = {
                        id: id,
                        title: values?.title,
                        description: values?.description,
                        order_number: values?.order_number,
                        image: typeof logoFile == "object" ? img : logoFile,
                        website: values?.website,
                      };
                      await putNodeApi("sponsors/", postObj)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Sponser updated successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/sponser-list");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      values,
                      isSubmitting,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Title
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.title && touched.title
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="title"
                                  placeholder="Enter title"
                                />
                                <ErrorMessage
                                  name="title"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Description
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                 as="textarea"
                                  className={`form-control ${
                                    errors.description && touched.description
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="description"
                                  placeholder="Enter description"
                                />
                                <ErrorMessage
                                  name="description"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Order Number
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.order_number && touched.order_number
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="order_number"
                                  placeholder="Enter Order Number"
                                />
                                <ErrorMessage
                                  name="order_number"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Website
                                  <span className="text-danger"></span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.website && touched.website
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="website"
                                  placeholder="Enter Website"
                                />
                                <ErrorMessage
                                  name="website"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Upload Image
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                <img
                                  src={
                                    typeof values.image == "object"
                                      ? selectedImage
                                      : `${process.env.REACT_APP_API_IMAGE_URL}${sponserData?.image}`
                                  }
                                  alt="Sponser-img"
                                  style={{ width: "auto", height: "80px" }}
                                  className="mx-5 px-5 mt-2"
                                />
                                {fileError && (
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Sponser
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSponser;
