import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import { addEventInitialValues, addEventValidationSchema } from "./constant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { customSelectStyles } from "../../../../Utils/CSS/select";
import SwitchToggle from "../../../../Utils/SwitchToggle";
import { getImageUrl } from "../../../../Services/Api/fileUpload";
import { getNodeApi, postNodeApi } from "../../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../../Hooks/LoaderContext";
import { format } from "date-fns";
import moment from "moment";

const AddEvent = () => {
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  // const [cityList, setCityList] = useState([]);
  const [countryId, setCountryId] = useState();
  // const [stateId, setStateId] = useState();
  // const [cityId, setCityId] = useState();
  const [gurudwaraList, setGurudwaraList] = useState([]);
  const [jathaList, setJathaList] = useState([]);
  // const [parchaarakList, setParchaarakList] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [fileError, setFileError] = useState("");
  const { setLoading } = useLoader();
  const [gurdwaraCountryId, setGurdwaraCountryId] = useState();
  const [gurdwaraStateId, setGurdwaraStateId] = useState();

  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];

    if (!file) return; // Return if no file is selected

    if (!file.type.startsWith("image/")) {
      // Clear the file input
      e.currentTarget.value = null;
      // Clear the image preview
      setSelectedImage("");
      // Display an error message to the user
      setFileError("Only image files are allowed.");
      return;
    }

    // If an image file is selected, update the image preview and clear any previous error message
    setSelectedImage(URL.createObjectURL(file));
    setFieldValue("image", file);
    setFileError("");
  };

  const getCountry = async () => {
    setLoading(true);
    await getNodeApi("../general/get-all-countries?type=event").then((res) => {
      setLoading(false);
      let countryArr = res?.data?.countries || [];
      if (countryArr.length > 0) {
        countryArr = countryArr.map((value) => {
          return {
            label: value.name,
            value: value._id,
          };
        });
        setCountryList(countryArr);
      } else {
        // ErrorToast("No Results.");
        setCountryList([]);
      }
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    const getState = async () => {
      setLoading(true);
      await getNodeApi(
        `../general/get-all-states-by-country/${gurdwaraCountryId}`
      ).then((res) => {
        setLoading(false);
        let stateArr = res?.data?.states || [];
        if (stateArr.length > 0) {
          stateArr = stateArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
            };
          });
          setStateList(stateArr);
        } else {
          // ErrorToast("No Results.");
          setStateList([]);
        }
      });
    };
    if (gurdwaraCountryId) {
      getState(gurdwaraCountryId);
    }
  }, [gurdwaraCountryId]);

  /*
  useEffect(() => {
    const getCity = async (stateId) => {
      setLoading(true);
      await getNodeApi(`../general/get-all-cities-by-state/${stateId}`).then(
        (res) => {
          setLoading(false);
          let cityArr = res?.data?.cities || [];
          if (cityArr.length > 0) {
            cityArr = cityArr.map((value) => {
              return {
                label: value.name,
                value: value._id,
              };
            });
            setCityList(cityArr);
          } else {
            // ErrorToast("No Results.");
            setCityList([]);
          }
        }
      );
    };
    if (stateId) {
      getCity(stateId);
    }
  }, [stateId]);
  */

  useEffect(() => {
    const getGurudwara = async (gurdwaraStateId) => {
      setLoading(true);
      await getNodeApi(`gurudwara/get-by-state-id/${gurdwaraStateId}`).then(
        (res) => {
          setLoading(false);
          if (res?.data?.length > 0) {
            let gurudwaraArr = res?.data;
            gurudwaraArr = gurudwaraArr.map((value) => {
              return {
                label: value.name,
                value: value._id,
              };
            });
            setGurudwaraList(gurudwaraArr);
          } else {
            // ErrorToast("No Results.");
            setGurudwaraList([]);
          }
        }
      );
    };
    if (gurdwaraStateId) {
      getGurudwara(gurdwaraStateId);
    }
  }, [gurdwaraStateId]);

  useEffect(() => {
    const getJatha = async (countryId) => {
      setLoading(true);
      await getNodeApi(
        `general/get-country-wise-users/?country_id=${countryId}&user_type=jatha`
      ).then((res) => {
        setLoading(false);
        if (res?.data?.length > 0) {
          let jathaArr = res?.data;
          jathaArr = jathaArr.map((value) => {
            return {
              label: value.full_name,
              value: value.id,
            };
          });
          setJathaList(jathaArr);
        } else {
          // ErrorToast("No Results.");
          setJathaList([]);
        }
      });
    };
    if (countryId) {
      getJatha(countryId);
    }
  }, [countryId]);

  const filterEndTime = (start_time, end_time) => {
    // Convert start time and end time to Date objects
    const startDateTime = new Date(start_time);
    const endDateTime = new Date(end_time);

    // Ensure that the start time is before the end time
    return startDateTime.getTime() < endDateTime.getTime();
  };

  /*
   useEffect(() => {
    const getParchaarak = async (countryId) => {
      setLoading(true);
      await getNodeApi(
        `general/get-country-wise-users/?country_id=${countryId}&user_type=parchaarak`
      ).then((res) => {
        setLoading(false);
        if (res?.data?.length > 0) {
          let parchaarakArr = res?.data;
          parchaarakArr = parchaarakArr.map((value) => {
            return {
              label: value.full_name,
              value: value.id,
            };
          });
          setParchaarakList(parchaarakArr);
        } else {
          // ErrorToast("No Results.");
          setParchaarakList([]);
        }
      });
    };
    if (countryId) {
      getParchaarak(countryId);
    }
  }, [countryId]);
  */

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Add Event</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Event</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/event">Event</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Event</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/event" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Event Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={addEventInitialValues}
                    validationSchema={addEventValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, setError }) => {
                      setLoading(true);
                      const logoFile = values?.image;
                      const formData = new FormData();
                      formData.append("image", logoFile);
                      formData.append("module", "EVENT");
                      let img = "";
                      if (values.image) {
                        await getImageUrl(formData).then((res) => {
                          img = res.data.data[0].s3_key;
                        });
                      }
                      const formattedDate = format(values.date, "dd/MM/yyyy");

                      const postData = {
                        name: values.name,
                        // event_type: values.event_type,
                        description: values.description,
                        // event_location: values.event_location,
                        // date: values.date,
                        date: formattedDate,
                        start_time: moment(values?.start_time).format("HH:mm"),
                        end_time: moment(values?.end_time).format("HH:mm"),
                        // start_time: formattedStartTime,
                        // end_time: formattedEndTime,
                        jatha_id: values?.jatha_id?.value || null,
                        // parchaarak_id: values?.parchaarak_id?.value || null,
                        gurudwara_id: values.gurudwara_id,
                        image: img,
                        is_active: true,
                      };
                      await postNodeApi("event", postData)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Event created successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/event");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Event Title</label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter event name"
                                />
                              </div>
                              <div className="form-group">
                                <label>Event Info</label>
                                <Field
                                  as="textarea"
                                  className="form-control"
                                  name="description"
                                  placeholder="Enter description"
                                />
                              </div>

                              {/* <div className="form-group">
                                <label>Event Location</label>
                                <div className="inputWrap">
                                  <Field
                                    type="text"
                                    className={`form-control ${
                                      errors.event_location &&
                                      touched.event_location
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="event_location"
                                    placeholder="Enter event location"
                                  />
                                </div>
                                <ErrorMessage
                                  name="event_location"
                                  component="span"
                                  className="error"
                                />
                              </div> */}
                              <div className="form-group">
                                <label>
                                  Date
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <DatePicker
                                    className="form-control"
                                    selected={formik.values.date}
                                    minDate={new Date()}
                                    selectsStart
                                    onChange={(date) =>
                                      formik.setFieldValue("date", date)
                                    }
                                    dateFormat="d MMM, yyyy"
                                    placeholderText="Select date"
                                  />
                                </div>
                                <ErrorMessage
                                  name="date"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      Start Time
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="inputWrap">
                                      <Field name="start_time">
                                        {({ field, form }) => (
                                          <DatePicker
                                            {...field}
                                            className="form-control"
                                            selected={
                                              (field.value &&
                                                new Date(field.value)) ||
                                              null
                                            }
                                            onChange={(val) =>
                                              form.setFieldValue(
                                                field.name,
                                                val
                                              )
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select Start Time"
                                          />
                                        )}
                                      </Field>
                                      {/* <DatePicker
                                        className="form-control"
                                        selected={formik.values.startTime}
                                        selectsStart
                                        onChange={(time) =>
                                          formik.setFieldValue(
                                            "startTime",
                                            time
                                          )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        dateFormat="h:mm aa"
                                        placeholderText="Select Start Time"
                                      /> */}
                                    </div>
                                    <ErrorMessage
                                      name="start_time"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      End Time
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="inputWrap">
                                      <Field name="end_time">
                                        {({ field, form }) => (
                                          <DatePicker
                                            className="form-control"
                                            selected={
                                              (field.value &&
                                                new Date(field.value)) ||
                                              null
                                            }
                                            onChange={(val) =>
                                              form.setFieldValue(
                                                field.name,
                                                val
                                              )
                                            }
                                            minTime={moment(
                                              values.start_time
                                            ).toDate()}
                                            maxTime={moment()
                                              .set({ hour: 23, minute: 45 })
                                              .toDate()}
                                            selectsEnd
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select End Time"
                                            excludeTimes={[values.start_time]}
                                          />
                                        )}
                                      </Field>

                                      {/* <Field name="end_time">
                                        {({ field, form }) => (
                                          <DatePicker
                                            className="form-control"
                                            // selected={formik.values.endTime}
                                            selected={
                                              (field.value &&
                                                new Date(field.value)) ||
                                              null
                                            }
                                            onChange={(val) =>
                                              form.setFieldValue(
                                                field.name,
                                                val
                                              )
                                            }
                                            minTime={moment(
                                              values.start_time
                                            ).toDate()}
                                            maxTime={moment()
                                              .set({ hour: 23, minute: 45 })
                                              .toDate()}
                                            selectsEnd
                                            // minDate={formik.values.startTime}
                                            // onChange={(time) =>
                                            //   formik.setFieldValue("endTime", time)
                                            // }
                                            // filterTime={(time) =>
                                            //   filterEndTime(
                                            //     formik.values.startTime,
                                            //     time
                                            //   )
                                            // }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeCaption="Time"
                                            // dateFormat="hh:mm aa"
                                            dateFormat="h:mm aa"
                                            placeholderText="Select End Time"
                                          />
                                        )}
                                      </Field> */}
                                      {/* <DatePicker
                                        className="form-control"
                                        selected={formik.values.endTime}
                                        selectsEnd
                                        minDate={formik.values.startTime}
                                        onChange={(time) =>
                                          formik.setFieldValue("endTime", time)
                                        }
                                        filterTime={(time) =>
                                          filterEndTime(
                                            formik.values.startTime,
                                            time
                                          )
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        // dateFormat="hh:mm aa"
                                        dateFormat=" h:mm aa"
                                        placeholderText="Select End Time"
                                      />  */}
                                    </div>
                                    <ErrorMessage
                                      name="end_time"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="form-group">
                                <label>Upload Image</label>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleImageChange(e, setFieldValue)
                                  }
                                  className={`form-control`}
                                />
                                {selectedImage && (
                                  <img
                                    src={selectedImage}
                                    alt="GurudwaraImage"
                                    style={{ width: "auto", height: "80px" }}
                                    className="mx-5 px-5 mt-2"
                                  />
                                )}
                                {fileError && (
                                  <span className="error d-flex">
                                    {fileError}
                                  </span>
                                )}
                                <ErrorMessage
                                  name="image"
                                  component="span"
                                  className="error d-flex"
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <section
                                className="p-2 rounded"
                                style={{
                                  border: "solid #F2F2F2 2px",
                                  marginTop: "30px",
                                }}
                              >
                                <p className="mt-2 mb-2 fw-bold">
                                  Choose Jatha or Parchaarak by country
                                </p>
                                <div className="form-group">
                                  <label>Country</label>
                                  <Select
                                    options={countryList}
                                    onChange={(e) => {
                                      setFieldValue("country", e);
                                      setCountryId(e?.value);
                                    }}
                                    value={values.country}
                                    styles={customSelectStyles}
                                    name="country"
                                    placeholder="Select country"
                                  />
                                </div>

                                <div className="form-group">
                                  <label>
                                    Jatha or Parchaarak
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={jathaList}
                                    onChange={(e) => {
                                      setFieldValue("jatha_id", e);
                                    }}
                                    value={values?.jatha_id}
                                    // isDisabled={!values.country || !countryId}
                                    styles={customSelectStyles}
                                    name="jatha_id"
                                    placeholder="Select jatha or parchaarak"
                                  />
                                  <ErrorMessage
                                    name="jatha_id"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                                {/* <div className="text-center">
                                  <p>OR</p>
                                </div>
                                <div className="form-group">
                                  <label>
                                    Parchaarak
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={parchaarakList}
                                    onChange={(e) => {
                                      setFieldValue("jatha_id", null);
                                      setFieldValue("parchaarak_id", e);
                                    }}
                                    value={values?.parchaarak_id}
                                    // isDisabled={!values.country || !countryId}
                                    styles={customSelectStyles}
                                    name="parchaarak_id"
                                    placeholder="Select parchaarak"
                                  />
                                  <ErrorMessage
                                    name="parchaarak_id"
                                    component="span"
                                    className="error"
                                  />
                                </div> */}
                              </section>
                              <section
                                className="p-2 rounded"
                                style={{
                                  border: "solid #F2F2F2 2px",
                                  marginTop: "23px",
                                }}
                              >
                                <p className="mt-2 mb-2 fw-bold">
                                  Choose Gurdwara by country and state
                                </p>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Country</label>
                                      <Select
                                        options={countryList}
                                        onChange={(e) => {
                                          setFieldValue("country_gurdwara", e);
                                          setGurdwaraCountryId(e?.value);
                                        }}
                                        value={values.country_gurdwara}
                                        styles={customSelectStyles}
                                        name="country_gurdwara"
                                        placeholder="Select country"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>State</label>
                                      <Select
                                        options={stateList}
                                        onChange={(e) => {
                                          setFieldValue("state_gurdwara", e);
                                          setGurdwaraStateId(e?.value);
                                        }}
                                        value={values.state_gurdwara}
                                        // isDisabled={!values.country || !countryId}
                                        styles={customSelectStyles}
                                        name="state_gurdwara"
                                        placeholder="Select state"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>
                                    Gurdwara
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    options={gurudwaraList}
                                    onChange={(e) => {
                                      setFieldValue("gurudwara_id", e.value);
                                    }}
                                    // isDisabled={!values.state || !stateId}
                                    styles={customSelectStyles}
                                    name="gurudwara_id"
                                    placeholder="Select gurdwara"
                                  />
                                  <ErrorMessage
                                    name="gurudwara_id"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </section>
                            </div>
                            <div className="col-md-6">
                              <SwitchToggle
                                setFieldValue={setFieldValue}
                                value={values}
                              />
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Event
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEvent;
