import { Route, Routes, useNavigate } from "react-router-dom";
import {
  Customer,
  EditCustomer,
  AddCustomer,
} from "../Components/Admin/Customer";
import { AdminLayout } from "../Components/Layout";
import PrivateRoute from "./PrivateRoute";
import {
  AddRoleManagement,
  EditRolePermission,
  Role,
} from "../Components/Admin/RoleManagement";

import { AddUser, EditUser, User } from "../Components/Admin/UserManagement";
import { Dashboard } from "../Components/Admin/Dashboard";
import { Profile } from "../Components/Admin/Profile";
import { ChangePassword, Settings } from "../Components/Admin/settings";
import {
  ListNews,
  AddNews,
  EditNews,
  NewsDetails,
} from "../Components/Admin/NewsBulletins";
// import { CustomerAmountDueReport, CustomerTransactionReport, MerchantTransactionReport, ViewEarningReport } from "../Components/Admin/Reports";
import {
  EditJathasGianiPriest,
  JathasGianiPriest,
} from "../Components/Admin/jathas-giani-priest";
import AddJathasGianiPriest from "../Components/Admin/jathas-giani-priest/AddJathas";
import { Confirmation, ErrorToast } from "../Utils/SweetAlert";

import {
  GurdwaraList,
  AddGurdwara,
  EditGurdwara,
} from "../Components/Admin/Gurdwara";

import { AddEvent, EventList } from "../Components/Admin/EventMangement/Event";
import EditEvent from "../Components/Admin/EventMangement/Event/EditEvent";
// import { ParchaarakList } from "../Components/Admin/Parchaarak";
// import AddParchaarak from "../Components/Admin/Parchaarak/AddParchaarak";
// import EditParchaarak from "../Components/Admin/Parchaarak/EditParchaarak";
import { NormalUserList } from "../Components/Admin/NormalUser";
import AddNormalUser from "../Components/Admin/NormalUser/AddNormalUser";
import EditNormalUser from "../Components/Admin/NormalUser/EditNormalUser";
import { SiteSettings } from "../Components/Admin/SiteSettings";
import { useEffect } from "react";
import usePermission from "../Hooks/useHasPermisson";
import SearchingComponent from "../Components/Admin/SearchingComponent";
import RatingReview from "../Components/Admin/EventMangement/RatingReviev/RatingReview";
import {
  AddCountry,
  CountryList,
  EditCountry,
} from "../Components/Admin/StaticData/Country";
import {
  StateList,
  AddState,
  EditState,
} from "../Components/Admin/StaticData/State";
import {
  CityList,
  AddCity,
  EditCity,
} from "../Components/Admin/StaticData/City";
import {
  AddSponser,
  EditSponser,
  SponserList,
} from "../Components/Admin/Sponser";
import { DonationList } from "../Components/Admin/Donation";
import {
  AddOtherApps,
  EditOtherApps,
  OtherAppsList,
} from "../Components/Admin/OtherApps";
import {
  OnbordingList,
  AddOnbording,
  EditOnbording,
} from "../Components/Admin/StaticContent/Onbording";
import AboutUs from "../Components/Admin/StaticContent/About Us/About";
import TermsUse from "../Components/Admin/StaticContent/TermOfUse/TermsUse";
import Kirtan from "../Components/Admin/StaticContent/Kirtan/Kirtan";
import SikhismBasics from "../Components/Admin/StaticContent/SikhismBasics/SikhismBasics";
import PrivacyPolicy from "../Components/Admin/StaticContent/PrivacyPolicy/PrivacyPolicy";
import { ContactUsList, FeedBackList } from "../Components/Admin/ContactUs";
import { Legal } from "../Components/Admin/Legal";
import EventDetails from "../Components/Admin/EventMangement/Event/EventDetails";
import { AboutUsPunjabi } from "../Components/Admin/StaticContent/About Us Punjabi";
import { KirtanPunjabi } from "../Components/Admin/StaticContent/Kirtan Punjabi";
import {SikhismBasicsPunjabi} from "../Components/Admin/StaticContent/SikhismBasicsPunjabi";
import { HelpAdd, HelpEdit, HelpList } from "../Components/Admin/Help";
import { RequestedGurdwaraList } from "../Components/Admin/RequestedGurdwara";


export const Logout = (event) => {
  event.preventDefault();
  Confirmation("Are You Sure To Logout").then(async (result) => {
    if (result.isConfirmed) {
      try {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("adminPermission");
        localStorage.removeItem("userType");
        window.location.assign("/");
      } catch (err) {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      }
    }
  });
};

const AdminRoutes = () => {
  const navigate = useNavigate();
  const { token } = usePermission();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <AdminLayout />
      <Routes>
        <Route
          exact
          path="/"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          exact
          path="/sub-admin"
          element={<PrivateRoute Component={User} />}
        />
        <Route
          exact
          path="/add-subadmin"
          element={<PrivateRoute Component={AddUser} />}
        />
        <Route
          exact
          path="/edit-subadmin/:id"
          element={<PrivateRoute Component={EditUser} />}
        />
        <Route exact path="/role" element={<PrivateRoute Component={Role} />} />
        <Route
          exact
          path="/add-role-management"
          element={<PrivateRoute Component={AddRoleManagement} />}
        />
        <Route
          exact
          path="/edit-role-permission/:id"
          element={<PrivateRoute Component={EditRolePermission} />}
        />
        <Route
          exact
          path="/customer"
          element={<PrivateRoute Component={Customer} />}
        />
        <Route
          exact
          path="/add-customer"
          element={<PrivateRoute Component={AddCustomer} />}
        />
        <Route
          exact
          path="/edit-customer"
          element={<PrivateRoute Component={EditCustomer} />}
        />
        <Route
          exact
          path="/profile"
          element={<PrivateRoute Component={Settings} />}
        />
        {/* <Route
          exact
          path="/setting"
          element={<PrivateRoute Component={Settings} />}
        /> */}
        <Route
          exact
          path="/setting/change-password"
          element={<PrivateRoute Component={ChangePassword} />}
        />

        {/* Routes for news bulletins */}
        <Route
          exact
          path="/news-bulletins"
          element={<PrivateRoute Component={ListNews} />}
        />
        <Route
          exact
          path="/add-news"
          element={<PrivateRoute Component={AddNews} />}
        />
        <Route
          exact
          path="/edit-news/:id"
          element={<PrivateRoute Component={EditNews} />}
        />

        <Route
          exact
          path="/details-news/:id"
          element={<PrivateRoute Component={NewsDetails} />}
        />
        {/* Routes for city */}

        {/* <Route
          exact
          path="/city"
          element={<PrivateRoute Component={CityList} />}
        />

        <Route
          exact
          path="/add-city"
          element={<PrivateRoute Component={AddCity} />}
        />

        <Route
          exact
          path="/edit-city/:id"
          element={<PrivateRoute Component={EditCity} />}
        /> */}
        <Route
          exact
          path="/country"
          element={<PrivateRoute Component={CountryList} />}
        />
        <Route
          exact
          path="/add-country"
          element={<PrivateRoute Component={AddCountry} />}
        />
        <Route
          exact
          path="/edit-country/:id"
          element={<PrivateRoute Component={EditCountry} />}
        />
        <Route
          exact
          path="/state"
          element={<PrivateRoute Component={StateList} />}
        />
        <Route
          exact
          path="/add-state"
          element={<PrivateRoute Component={AddState} />}
        />
        <Route
          exact
          path="/edit-state/:id"
          element={<PrivateRoute Component={EditState} />}
        />
        <Route
          exact
          path="/jathas"
          element={<PrivateRoute Component={JathasGianiPriest} />}
        />
        <Route
          exact
          path="/add-jathas"
          element={<PrivateRoute Component={AddJathasGianiPriest} />}
        />
        <Route
          exact
          path="/edit-jathas/:id"
          element={<PrivateRoute Component={EditJathasGianiPriest} />}
        />
        {/* <Route
          exact
          path="/parchaarak"
          element={<PrivateRoute Component={ParchaarakList} />}
        />
        <Route
          exact
          path="/add-parchaarak"
          element={<PrivateRoute Component={AddParchaarak} />}
        />
        <Route
          exact
          path="/edit-parchaarak/:id"
          element={<PrivateRoute Component={EditParchaarak} />}
        /> */}
        <Route
          exact
          path="/normal-user"
          element={<PrivateRoute Component={NormalUserList} />}
        />
        <Route
          exact
          path="/add-normaluser"
          element={<PrivateRoute Component={AddNormalUser} />}
        />
        <Route
          exact
          path="/edit-normaluser/:id"
          element={<PrivateRoute Component={EditNormalUser} />}
        />
        <Route
          exact
          path="/about-us"
          element={<PrivateRoute Component={AboutUs} />}
        />
          <Route
          exact
          path="/about-us-punjabi"
          element={<PrivateRoute Component={AboutUsPunjabi} />}
        />
        <Route
          exact
          path="/terms-of-use"
          element={<PrivateRoute Component={TermsUse} />}
        />
        <Route
          exact
          path="/what-is-kirtan"
          element={<PrivateRoute Component={Kirtan} />}
        />
         <Route
          exact
          path="/what-is-kirtan-punjabi"
          element={<PrivateRoute Component={KirtanPunjabi} />}
        />
        <Route
          exact
          path="/sikhism-basics"
          element={<PrivateRoute Component={SikhismBasics} />}
        />
        <Route
          exact
          path="/sikhism-basics-punjabi"
          element={<PrivateRoute Component={SikhismBasicsPunjabi} />}
        />
        {/* <Route
          exact
          path="/legal"
          element={<PrivateRoute Component={Legal} />}
        /> */}
        <Route
          exact
          path="/privacy-policy"
          element={<PrivateRoute Component={PrivacyPolicy} />}
        />
        {/* 21-12-23 end */}
        {/* Gurdwara... */}
        <Route
          exact
          path="/requested-gurdwara"
          element={<PrivateRoute Component={RequestedGurdwaraList} />}
        />
        <Route
          exact
          path="/gurdwara"
          element={<PrivateRoute Component={GurdwaraList} />}
        />
        <Route
          exact
          path="/add-gurdwara"
          element={<PrivateRoute Component={AddGurdwara} />}
        />
        <Route
          exact
          path="/edit-gurdwara/:id"
          element={<PrivateRoute Component={EditGurdwara} />}
        />

        {/* Routes for Event */}
        <Route
          exact
          path="/event"
          element={<PrivateRoute Component={EventList} />}
        />

        <Route
          exact
          path="/add-event"
          element={<PrivateRoute Component={AddEvent} />}
        />

        <Route
          exact
          path="/edit-event/:id"
          element={<PrivateRoute Component={EditEvent} />}
        />

        <Route
          exact
          path="/event-detail/:id"
          element={<PrivateRoute Component={EventDetails} />}
        />

        <Route
          exact
          path="/rating-review/:id"
          element={<PrivateRoute Component={RatingReview} />}
        />

        <Route
          exact
          path="/rating-review"
          element={<PrivateRoute Component={RatingReview} />}
        />

        <Route
          exact
          path="/site-settings"
          element={<PrivateRoute Component={SiteSettings} />}
        />

        <Route
          exact
          path="/searching-component"
          element={<PrivateRoute Component={SearchingComponent} />}
        />

        {/* Onbording Routes */}
        <Route
          exact
          path="/onbording-list"
          element={<PrivateRoute Component={OnbordingList} />}
        />

        <Route
          exact
          path="/add-onbording"
          element={<PrivateRoute Component={AddOnbording} />}
        />

        <Route
          exact
          path="/edit-onbording/:id"
          element={<PrivateRoute Component={EditOnbording} />}
        />

        {/* Sponser Routes */}
        <Route
          exact
          path="/sponser-list"
          element={<PrivateRoute Component={SponserList} />}
        />

        <Route
          exact
          path="/add-sponser"
          element={<PrivateRoute Component={AddSponser} />}
        />

        <Route
          exact
          path="/edit-sponser/:id"
          element={<PrivateRoute Component={EditSponser} />}
        />

        {/* Donation Routes */}
        <Route
          exact
          path="/donation-list"
          element={<PrivateRoute Component={DonationList} />}
        />

        {/* Other Apps Routes */}
        <Route
          exact
          path="/otherapps-list"
          element={<PrivateRoute Component={OtherAppsList} />}
        />

        <Route
          exact
          path="/add-otherapps"
          element={<PrivateRoute Component={AddOtherApps} />}
        />

        <Route
          exact
          path="/edit-otherapps/:id"
          element={<PrivateRoute Component={EditOtherApps} />}
        />

        {/*feed back Routes*/}
        <Route
          exact
          path="/contact-us-list"
          element={<PrivateRoute Component={ContactUsList} />}
        />

        {/* Help */}
        <Route
          exact
          path="/help-list"
          element={<PrivateRoute Component={HelpList} />}
        />
        <Route
          exact
          path="/add-help"
          element={<PrivateRoute Component={HelpAdd} />}
        />
        <Route
          exact
          path="/edit-help/:id"
          element={<PrivateRoute Component={HelpEdit} />}
        />

        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
};
export default AdminRoutes;
