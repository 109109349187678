import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../../Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
// import 'moment-timezone';
import moment from "moment-timezone";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
  TextArea,
} from "../../../../Utils/SweetAlert";
import StatusButton from "../../../../Utils/StatusButton";
// import moment from "moment";
import usePermission from "../../../../Hooks/useHasPermisson";
import { RolePermission } from "../../../../Utils/constance/options";
import NameInitial from "../../../../Utils/NameInitial";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InfoIcon from "@mui/icons-material/Info";
import {
  deleteNodeApi,
  getNodeApi,
  postNodeApi,
  putNodeApi,
} from "../../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../../Hooks/LoaderContext";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { customFilterStyle } from "../../../../Utils/CSS/filter";

const EventList = () => {
  const { hasPermission, role } = usePermission();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchEvent, setSearchEvent] = useState("");
  const [show, setShow] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [eventListData, setEventListData] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setLoading } = useLoader();
  const [showImportModal, setShowImportModal] = useState(false);
  const [timer, setTimer] = useState(null);
  const [jathaList, setJathaList] = useState();
  const [evnettypeList, setEvnettypeList] = useState([
    {
      label: "Up Comming",
      value: 1,
    },
    {
      label: "Old",
      value: 0,
    }
  ]);
  const [jathaId, setJathaId] = useState("");
  const [evnetTypeId, setevnetTypeId] = useState({
    label: "Up Comming",
    value: 1,
  });
  const [stateList, setStateList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [countryId, setCountryId] = useState("");
  // const { values, setFieldValue } = useFormikContext();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [sortObj, setSortObj] = useState({ sortBy: "events.date", sortMode: "asc" });
  const fileValidation = Yup.object().shape({
    file: Yup.mixed()
      .required("You need to provide a file")
      .test(
        "fileType",
        "Only the following formats are accepted: .csv or .xlsx",
        (value) => {
          if (!value) return true;
          const acceptedFormats = [
            "text/csv",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ];
          return acceptedFormats.includes(value.type);
        }
      ),
  });

  const openDescriptionModal = (event) => {
    setSelectedDescription(event.description);
    setModalTitle(event.name);
    setShow(true);
  };

  /*const openLocationModal = (location) => {
    setSelectedLocation(location);
    setModalTitle("Event Location");
    setShow(true);
  };*/

  const closeModal = () => {
    setSelectedDescription("");
    setSelectedLocation("");
    setShowImportModal(false);
    setShow(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const GetEventList = async () => {
    setLoading(true);
    await getNodeApi(
      `event/?offset=${page}&limit=${limit}&search=${searchEvent}&jathaId=${jathaId}&evnetTypeId=${evnetTypeId?.value}&country_id=${countryId}&state_id=${stateId}&sortKey=${sortObj.sortBy}&sortMode=${sortObj.sortMode}`
    )
      .then((res) => {
        setLoading(false);
        if (res) {
          setLoading(false);
          setEventListData(res?.data);
          setTotalRecords(res?.totalRecords);
        } else {
          setEventListData([]);
          setTotalRecords(0);
          ErrorToast("No Results.");
        }
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  };

  const getCountry = async () => {
    setLoading(true);
    await getNodeApi("../general/get-all-countries").then((res) => {
      setLoading(false);
      let countryArr = res?.data?.countries || [];
      if (countryArr.length > 0) {
        countryArr = countryArr.map((value) => {
          return {
            label: value.name,
            value: value._id,
          };
        });
        countryArr.unshift({ label: "All", value: "" });
        setCountryList(countryArr);
      } else {
        // ErrorToast("No Results.");
        countryArr.unshift({ label: "All", value: "" });
        setCountryList([]);
      }
    });
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    const GetJathaList = async () => {
      setLoading(true);
      await getNodeApi(`../general/get-user-type-wise-user-list/jatha`).then(
        (res) => {
          setLoading(false);
          let jathaArr = res?.data?.users || [];
          if (jathaArr.length > 0) {
            jathaArr = jathaArr.map((value) => {
              return {
                label: value.full_name,
                value: value._id,
              };
            });
            jathaArr.unshift({ label: "All", value: "" });
            setJathaList(jathaArr);
          } else {
            // ErrorToast("No Results.");
            setJathaList([]);
          }
        }
      );
    };
    GetJathaList();
  }, []);

  useEffect(() => {
    const getState = async () => {
      setLoading(true);
      await getNodeApi(
        `../general/get-all-states-by-country/${countryId}`
      ).then((res) => {
        setLoading(false);
        let stateArr = res?.data?.states || [];
        if (stateArr.length > 0) {
          stateArr = stateArr.map((value) => {
            return {
              label: value.name,
              value: value._id,
            };
          });
          stateArr.unshift({ label: "All", value: "" });
          setStateList(stateArr);
        } else {
          // ErrorToast("No Results.");
          setStateList([]);
        }
      });
    };
    if (countryId) {
      getState(countryId);
    }
  }, [countryId]);

  /* const handleSearchInputChange = (e) => {
    const searchValue = e?.target?.value;
    if (searchValue.length > 2) {
      setSearchEvent(searchValue);
    } else {
      setSearchEvent("");
    }
  };*/

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;

    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (searchValue.length > 2) {
        setSearchEvent(searchValue);
      } else {
        setSearchEvent("");
      }
    }, 500);

    if (searchValue.length >= 2) {
      setTimer(Timer);
    }
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    // await GetEventList(limit, pageNew, "");
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await GetEventList(parseInt(event.target.value, 10), 0, "");
  };

  const deleteEventById = (event) => {
    let now = moment();
    let date = event?.end_time;
    if (now.isAfter(date)) {
      Confirmation("Want to delete event?").then(async (deleteConfirm) => {
        setLoading(true);
        if (deleteConfirm.isConfirmed) {
          let data = {
            id: event._id,
          };

          await deleteNodeApi("event", data)
            .then((res) => {
              setLoading(false);
              SuccessToast(res?.message || "Event Deleted.");
              GetEventList(limit, page, "");
            })
            .catch((err) => {
              setLoading(false);
              if (typeof err?.response?.message !== "undefined") {
                ErrorToast(err?.response?.message || "something went wrong");
              } else {
                ErrorToast(err?.message || "something went wrong");
              }
            });
        }
      });
    } else {
      TextArea({ denyButtonText: "Close" }).then(async (deleteConfirm) => {
        setLoading(true);
        if (deleteConfirm.isConfirmed) {
          let reason = deleteConfirm.value;
          let data = {
            id: event._id,
            reason: reason,
          };
          await deleteNodeApi("event", data)
            .then((res) => {
              setLoading(false);
              SuccessToast(res?.message || "Event Deleted.");
              GetEventList(limit, page, "");
            })
            .catch((err) => {
              setLoading(false);
              if (typeof err?.response?.message !== "undefined") {
                ErrorToast(err?.response?.message || "something went wrong");
              } else {
                ErrorToast(err?.message || "something went wrong");
              }
            });
        }
      });
    }
  };

  const handleEventStatusChange = async (postObj) => {
    try {
      setLoading(true);
      const res = await putNodeApi("event/change-status", postObj);
      setLoading(false);
      GetEventList(limit, page, "");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const downloadExcel = () => {
    const excelUrl = "/inner-assets/sampleexcel/sample.xlsx";
    const link = document.createElement("a");
    link.href = excelUrl;
    link.download = "sample.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    GetEventList(limit, page, searchEvent, jathaId,evnetTypeId, countryId, stateId);
  }, [limit, page, searchEvent, jathaId,evnetTypeId, countryId, stateId, sortObj]);

  const sortData = async (field, order) => {
    const newOrder = (order === 'asc') ? 'desc' : 'asc';
    setSortObj({ sortBy: field, sortMode: newOrder });
  };

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Event List</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Event</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Event</li>
                </ul>
              </div>
              {role == RolePermission.USERTYPE ? (
                <div className="col-auto">
                  <Link to="/admin/add-event" className="btn btn-primary me-1">
                    <i className="fas fa-plus" /> Add Event
                  </Link>
                  <button
                    onClick={() => setShowImportModal(true)}
                    className="btn btn-primary me-1"
                  >
                    <i className="fas fa-plus" /> Import Events
                  </button>
                </div>
              ) : (
                <>
                  {hasPermission(RolePermission.EVENTADD) && (
                    <div className="col-auto">
                      <Link
                        to="/admin/add-event"
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Add Event
                      </Link>
                      <button
                        onClick={() => setShowImportModal(true)}
                        className="btn btn-primary me-1"
                      >
                        <i className="fas fa-plus" /> Import Events
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-auto">
              <Formik
                initialValues={{
                  jathaId: "",
                  evnetTypeId: evnetTypeId,
                  countryId: "",
                  stateId: "",
                }}
                validationSchema={Yup.object().shape({
                  jathaId: Yup.string(),
                  countryId: Yup.string(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form>
                    <div className="form-custom">
                      <div className="me-2 mt-2" style={{ width: "20%" }}>
                        <Select
                        value={evnetTypeId}
                          styles={customFilterStyle}
                          options={evnettypeList}
                          name="evnetTypeId"
                          placeholder="Select Type"
                          onChange={(selectedOption) => {
                            setevnetTypeId(selectedOption);
                          }}
                        />
                      </div>
                        <div className="me-2 mt-2" style={{ width: "20%" }}>
                          <Select
                            styles={customFilterStyle}
                            options={jathaList}
                            name="jathaId"
                            placeholder="Select jatha"
                            onChange={(selectedOption) => {
                              setJathaId(selectedOption?.value || "");
                            }}
                          />
                        </div>
                      <div className="me-2 mt-2" style={{ width: "20%" }}>
                        <Select
                          styles={customFilterStyle}
                          options={countryList}
                          name="countryId"
                          placeholder="Select country"
                          onChange={(selectedOption) => {
                            setCountryId(selectedOption?.value || "");
                          }}
                        />
                      </div>
                      <div className="me-2 mt-2" style={{ width: "20%" }}>
                        <Select
                          styles={customFilterStyle}
                          options={stateList}
                          name="stateId"
                          placeholder="Select state"
                          onChange={(selectedOption) => {
                            setStateId(selectedOption?.value || "");
                          }}
                        />
                      </div>
                      <div className="form-custom">
                        <input
                          type="text"
                          className="form-control"
                          id="role_search"
                          placeholder="Search Event"
                          onChange={handleSearchInputChange}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {/* <div className="col-auto">
              <Formik
                initialValues={{
                  jathaId: "",
                  countryId: "",
                }}
                validationSchema={Yup.object().shape({
                  jathaId: Yup.string(),
                  countryId: Yup.string(),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="form-custom">
                      <div className="me-2" style={{ width: "20%" }}>
                        <Select
                          options={jathaList}
                          name="jathaId"
                          placeholder="Select jatha"
                          onChange={(setJathaId) => {
                            GetEventList(limit, page, searchEvent, setJathaId?.value || "");
                          }}
                        />
                      </div>
                      <div className="me-2" style={{ width: "20%" }}>
                        <Select
                          options={countryList}
                          name="countryId"
                          placeholder="Select country"
                          onChange={(selectedOption) => {
                            GetEventList(limit, page, searchEvent, selectedOption?.value || "");
                          }}
                        />
                      </div>
                      <div className="me-2" style={{ width: "20%" }}>
                        <Select
                          options={jathaList}
                          name="jathaId"
                          placeholder="Select jatha"
                          onChange={(jathaId) => {
                            GetEventList(limit, page, searchEvent, jathaId?.value || "");
                          }}
                        />
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="event_search"
                        placeholder="Search Event"
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div> */}
            {/* <div className="form-custom">
              <div className="me-2" style={{ width: "20%" }}>
                <Select
                  options={jathaList}
                  onChange={(e) => {
                    setJathaId(e.value);
                  }}
                  name="jatha"
                  placeholder="Select jatha"
                />
              </div>
              {/* <div className="me-2" style={{ width: "20%" }}>
                <Select
                  styles={{ padding: "4px 41px" }}
                  options={countryList}
                  onChange={(e) => {
                    setCountryId(e.value);
                  }}
                  name="jatha"
                  placeholder="Select country"
                />
              </div> */}
            {/* <input
                type="text"
                className="form-control"
                id="event_search"
                placeholder="Search Event"
                onChange={handleSearchInputChange}
              /> */}
            {/* </div> */}
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-center table<img<image-hover datatable">
                      <thead className="thead-light">
                        <tr>
                          {/* <th onClick={() => sortData('name', sortObj.sortMode)} style={ { cursor: "pointer" } }>
                            Name {sortObj.sortBy === 'name' ? sortObj.sortMode === 'asc' ? '▲' : '▼' : ''}
                          </th> */}
                          {/* <th>Jatha or Parchaarak</th>
                           */}
                           <th onClick={() => sortData('events.jatha.full_name', sortObj.sortMode)} style={ { cursor: "pointer" } }>
                           Jatha or Parchaarak {sortObj.sortBy === 'events.jatha.full_name' ? sortObj.sortMode === 'asc' ? '▲' : '▼' : <i class="fas fa-sort"></i>}
                          </th>
                          <th onClick={() => sortData('name', sortObj.sortMode)} style={ { cursor: "pointer" } }>
                            Gurdwara {sortObj.sortBy === 'name' ? sortObj.sortMode === 'asc' ? '▲' : '▼' : <i class="fas fa-sort"></i>}
                          </th>
                          {/* <th>Event Type</th> */}
                          {/* <th>Description</th> */}
                          {/* <th>Event Location</th> */}
                          {/* <th>Country</th> */}
                          <th onClick={() => sortData('country.name', sortObj.sortMode)} style={ { cursor: "pointer" } }>
                          Country {sortObj.sortBy === 'country.name' ? sortObj.sortMode === 'asc' ? '▲' : '▼' : <i class="fas fa-sort"></i>}
                          </th>
                          {/* <th>Date</th> */}
                          <th onClick={() => sortData('events.date', sortObj.sortMode)} style={ { cursor: "pointer" } }>
                            Date {sortObj.sortBy === 'events.date' ? sortObj.sortMode === 'asc' ? '▲' : '▼' : <i class="fas fa-sort"></i>}
                          </th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Image</th>
                          {role == RolePermission.USERTYPE ? (
                            <th>Status</th>
                          ) : (
                            <>
                              {hasPermission(RolePermission.EVENTEDIT) && (
                                <th>Status</th>
                              )}
                            </>
                          )}

                          {role == RolePermission.USERTYPE ? (
                            <th>Action</th>
                          ) : (
                            <>
                              {hasPermission(
                                RolePermission.EVENTEDIT ||
                                  hasPermission(RolePermission.EVENTDELETE)
                              ) && <th>Action</th>}
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {eventListData.map((event, index) => (
                              <tr key={index}>
                                {/* <td>{event?.name || "-"}</td> */}
                                <td>
                                  {event?.jatha
                                    ? `${event?.jatha?.full_name}`
                                    : ""}
                                  {/* {event?.parchaarak
                                    ? `${event.parchaarak.first_name} ${event.parchaarak.last_name}`
                                    : ""} */}
                                </td>

                                <td>{event?.gurudwaras?.name || "-"}</td>
                                {/* <td>{event?.event_type || "-"}</td> */}
                                {/* <td>
                                  {event?.description &&
                                  event?.description?.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(event.description, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openDescriptionModal(event)
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    event?.description || "-"
                                  )}
                                </td> */}
                                {/* <td>
                                  {event?.event_location &&
                                  event?.event_location?.length > 30 ? (
                                    <>
                                      <span>
                                        {truncateText(event.event_location, 20)}
                                      </span>
                                      <Tooltip
                                        title="Details"
                                        className="btn btn-sm btn-white text-success me-2"
                                        onClick={() =>
                                          openLocationModal(
                                            event.event_location
                                          )
                                        }
                                      >
                                        <IconButton>
                                          <InfoIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    event?.event_location || "-"
                                  )}
                                </td> */}
                                <td>
                                  {event?.gurudwaras?.country?.name ||
                                    "-"}
                                </td>
                                <td>
                                  {moment(event?.date).tz(timeZone).format("D-MMM-yy") ||
                                    "-"}
                                    {/* {(event?.timezone != timeZone) && (
                                  <>
                                <br /><small>(Local : {moment(event?.date).tz(event?.timezone).format("D-MMM-yy")})</small>
                                </>
                                )} */}
                                </td>
                                {/* <td>{event?.date 
                                   || "-"}</td> */}
                          
                                <td>
                                {moment(event?.start_time).tz(timeZone).format('HH:mm') || "-"}
                                
                                {/* {(event?.timezone != timeZone) && (
                                  <>
                                <br /><small>(Local : {moment(event?.start_time).tz(event?.timezone).format('HH:mm')})</small>
                                </>
                                )} */}
                                {/* {event?.start_time || "-"} */}
                                </td>
                                <td>
                                {/* {event?.end_time || "-"} */}
                                {moment(event?.end_time).tz(timeZone).format('HH:mm') || "-"}
                             
                                {/* {(event?.timezone != timeZone) && (
                                  <>
                                     <br />
                                <small>(Local : {moment(event?.end_time).tz(event?.timezone).format('HH:mm')})</small>
                                  </>
                                )} */}
                                </td>
                                <td>
                                  {event?.image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_IMAGE_URL}${event?.image}`}
                                      alt="img"
                                      className="list-image-news"
                                    />
                                  ) : (
                                    <NameInitial
                                      name={event?.jatha?.full_name}
                                      type="event"
                                    />
                                  )}
                                </td>
                                {role == RolePermission.USERTYPE ? (
                                  <StatusButton
                                    module="event"
                                    role={event}
                                    changeActive={handleEventStatusChange}
                                  />
                                ) : (
                                  <>
                                    {hasPermission(
                                      RolePermission.EVENTEDIT
                                    ) && (
                                      <StatusButton
                                        module="event"
                                        role={event}
                                        changeActive={handleEventStatusChange}
                                      />
                                    )}
                                  </>
                                )}

                                <td>
                                  <Tooltip
                                    title="Details"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate(
                                        `/admin/event-detail/${event._id}`
                                      )
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>

                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/edit-event/${event?._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.EVENTEDIT
                                      ) && (
                                        <Tooltip
                                          title="Edit"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/edit-event/${event?._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-sm btn-white text-danger me-2"
                                      onClick={() => deleteEventById(event)}
                                    >
                                      <IconButton>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.EVENTDELETE
                                      ) && (
                                        <Tooltip
                                          title="Delete"
                                          className="btn btn-sm btn-white text-danger me-2"
                                          onClick={() => deleteEventById(event)}
                                        >
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
{/* 
                                  {role == RolePermission.USERTYPE ? (
                                    <Tooltip
                                      title="Rating & Review"
                                      className="btn btn-sm btn-white text-success me-2"
                                      onClick={() =>
                                        navigate(
                                          `/admin/rating-review/${event?._id}`
                                        )
                                      }
                                    >
                                      <IconButton>
                                        <i
                                          className="fas fa-star"
                                          style={{ color: "#7638ff" }}
                                        ></i>
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <>
                                      {hasPermission(
                                        RolePermission.RATINGLIST
                                      ) && (
                                        <Tooltip
                                          title="Rating & Review"
                                          className="btn btn-sm btn-white text-success me-2"
                                          onClick={() =>
                                            navigate(
                                              `/admin/rating-review/${event?._id}`
                                            )
                                          }
                                        >
                                          <IconButton>
                                            <i
                                              className="fas fa-star"
                                              style={{ color: "#7638ff" }}
                                            ></i>
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </>
                                  )} */}
                                </td>
                              </tr>
                            ))}

                            {eventListData.length === 0 && (
                              <tr>
                                <td align="center" colSpan={12}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <Modal show={show} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>{modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {selectedDescription}
                        {selectedLocation}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={showImportModal} onHide={closeModal}>
                      <Modal.Header>
                        <Modal.Title>Import Event</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <div className="d-flex">
                            <p>Download sample excel file here</p>
                            <span>
                              <Link
                                className="ms-2"
                                onClick={downloadExcel}
                                to="#"
                              >
                                Download
                              </Link>
                            </span>
                          </div>
                          <Formik
                            initialValues={{
                              file: "",
                            }}
                            validationSchema={fileValidation}
                            onSubmit={async (values, { setSubmitting }) => {
                              setLoading(true);
                              const logoFile = values?.file;
                              const formData = new FormData();
                              formData.append("excel_file", logoFile);
                              try {
                                await postNodeApi(
                                  "event/import-event",
                                  formData
                                ).then((res) => {
                                  if (res?.status === 0) {
                                    ErrorToast(
                                      res?.message || "something went wrong"
                                    );
                                  } else {
                                    SuccessToast(
                                      res?.message ||
                                        "Event imported successfully."
                                    );
                                    setShowImportModal(false);
                                    GetEventList();
                                    setLoading(false);
                                  }
                                });
                              } catch (err) {
                                setLoading(false);
                                if (
                                  typeof err?.response?.message !== "undefined"
                                ) {
                                  ErrorToast(
                                    err?.response?.message ||
                                      "something went wrong"
                                  );
                                } else {
                                  ErrorToast(
                                    err?.message || "something went wrong"
                                  );
                                }
                              }
                              setSubmitting(false);
                            }}
                          >
                            {(formik) => {
                              const {
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue,
                                handleSubmit,
                              } = formik;
                              return (
                                <Form onSubmit={handleSubmit}>
                                  <div className="form-group">
                                    <label>Upload excel file</label>
                                    <input
                                      accept=".csv, .xlsx"
                                      type="file"
                                      name="file"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "file",
                                          e.currentTarget.files[0]
                                        );
                                      }}
                                      className={`form-control ${
                                        errors.file && touched.file
                                          ? "input-error"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="file"
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                  <div className="text-end mt-4">
                                    <button
                                      type="button"
                                      className="btn btn-danger me-2 "
                                      onClick={() => setShowImportModal(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary btnstl"
                                      disabled={isSubmitting}
                                    >
                                      Import Event
                                      {isSubmitting && (
                                        <i className="fas fa-spinner fa-spin"></i>
                                      )}
                                    </button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                  <Pagination
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                    handleChangeRowsPerPage={handleLimitChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventList;
