// import { Pages } from "@mui/icons-material";
import Api from "../../Utils/Axios";

export const AddUserService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("users/", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUserPaginate = async ({ limit, page, search }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`users?offset=${page}&limit=${limit}&search=${search}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteUserById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`users/`, { data: { id: id } }));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put("users/change-status", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetUserByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`users/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditUserService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put("users/", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const adminGetUserPermissionService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("users/permissions/retrive"));
    } catch (error) {
      reject(error);
    }
  });
};
