import { Route, Routes, useNavigate } from "react-router-dom";
import { AdminLayout } from "../Components/Layout";
import PrivateRoute from "./PrivateRoute";
import { useEffect } from "react";
import {
  AddRoleManagement,
  EditRolePermission,
  Role,
} from "../Components/Admin/RoleManagement";
import { AddUser, EditUser, User } from "../Components/Admin/UserManagement";
import { Dashboard } from "../Components/Admin/Dashboard";
import { ChangePassword, Settings } from "../Components/Admin/settings";
import { useState } from "react";
import { PermissionAlert } from "../Components/Admin/PermissionAlert";
import {
  ListNews,
  AddNews,
  EditNews,
  NewsDetails,
} from "../Components/Admin/NewsBulletins";
import {
  EditJathasGianiPriest,
  JathasGianiPriest,
} from "../Components/Admin/jathas-giani-priest";
import AddJathasGianiPriest from "../Components/Admin/jathas-giani-priest/AddJathas";
import {
  GurdwaraList,
  AddGurdwara,
  EditGurdwara,
} from "../Components/Admin/Gurdwara";
import { AddEvent, EventList } from "../Components/Admin/EventMangement/Event";
import EditEvent from "../Components/Admin/EventMangement/Event/EditEvent";
// import { ParchaarakList } from "../Components/Admin/Parchaarak";
// import AddParchaarak from "../Components/Admin/Parchaarak/AddParchaarak";
// import EditParchaarak from "../Components/Admin/Parchaarak/EditParchaarak";
import { NormalUserList } from "../Components/Admin/NormalUser";
import AddNormalUser from "../Components/Admin/NormalUser/AddNormalUser";
import EditNormalUser from "../Components/Admin/NormalUser/EditNormalUser";
import { Logout } from "./AdminRoutes";
import { SiteSettings } from "../Components/Admin/SiteSettings";
import usePermission from "../Hooks/useHasPermisson";
import { RolePermission } from "../Utils/constance/options";
import SearchingComponent from "../Components/Admin/SearchingComponent";
import RatingReview from "../Components/Admin/EventMangement/RatingReviev/RatingReview";
import {
  AddCountry,
  CountryList,
  EditCountry,
} from "../Components/Admin/StaticData/Country";
import {
  StateList,
  AddState,
  EditState,
} from "../Components/Admin/StaticData/State";
import {
  CityList,
  AddCity,
  EditCity,
} from "../Components/Admin/StaticData/City";
import {
  AddSponser,
  EditSponser,
  SponserList,
} from "../Components/Admin/Sponser";
import { DonationList } from "../Components/Admin/Donation";
import {
  AddOtherApps,
  EditOtherApps,
  OtherAppsList,
} from "../Components/Admin/OtherApps";
import { ContactUsList, FeedBackList } from "../Components/Admin/ContactUs";
import { Legal } from "../Components/Admin/Legal";
import { PrivacyPolicy } from "../Components/Admin/StaticContent/PrivacyPolicy";
import AboutUs from "../Components/Admin/StaticContent/About Us/About";
import TermsUse from "../Components/Admin/StaticContent/TermOfUse/TermsUse";
import SikhismBasics from "../Components/Admin/StaticContent/SikhismBasics/SikhismBasics";
import { Kirtan } from "../Components/Admin/StaticContent/Kirtan";
import {
  AddOnbording,
  EditOnbording,
  OnbordingList,
} from "../Components/Admin/StaticContent/Onbording";
import EventDetails from "../Components/Admin/EventMangement/Event/EventDetails";
import { AboutUsPunjabi } from "../Components/Admin/StaticContent/About Us Punjabi";
import { KirtanPunjabi } from "../Components/Admin/StaticContent/Kirtan Punjabi";
import { SikhismBasicsPunjabi } from "../Components/Admin/StaticContent/SikhismBasicsPunjabi";
import { HelpAdd, HelpEdit, HelpList } from "../Components/Admin/Help";
import { RequestedGurdwaraList } from "../Components/Admin/RequestedGurdwara";


const AdminRoutesPermission = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { hasPermission, role, token } = usePermission();

  useEffect(() => {
    setLoading(false);
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <AdminLayout />
      {!loading && (
        <Routes>
          <Route
            exact
            path="/dashboard"
            element={<PrivateRoute Component={Dashboard} />}
          />

          <Route
            exact
            path="/profile"
            element={<PrivateRoute Component={Settings} />}
          />

          <Route
            exact
            path="/setting/change-password"
            element={<PrivateRoute Component={ChangePassword} />}
          />

          <Route exact path="/logout" element={<Logout />} />

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/sub-admin"
              element={<PrivateRoute Component={User} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.USERLIST) ? (
                <Route
                  exact
                  path="/sub-admin"
                  element={<PrivateRoute Component={User} />}
                />
              ) : (
                <Route exact path="/sub-admin" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-subadmin"
              element={<PrivateRoute Component={AddUser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.USERADD) ? (
                <Route
                  exact
                  path="/add-subadmin"
                  element={<PrivateRoute Component={AddUser} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-subadmin"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-subadmin/:id"
              element={<PrivateRoute Component={EditUser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.USEREDIT) ? (
                <Route
                  exact
                  path="/edit-subadmin/:id"
                  element={<PrivateRoute Component={EditUser} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-subadmin/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/role"
              element={<PrivateRoute Component={Role} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ROLELIST) ? (
                <Route
                  exact
                  path="/role"
                  element={<PrivateRoute Component={Role} />}
                />
              ) : (
                <Route exact path="/role" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-role-management"
              element={<PrivateRoute Component={AddRoleManagement} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ROLEADD) ? (
                <Route
                  exact
                  path="/add-role-management"
                  element={<PrivateRoute Component={AddRoleManagement} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-role-management"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-role-permission/:id"
              element={<PrivateRoute Component={EditRolePermission} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ROLEEDIT) ? (
                <Route
                  exact
                  path="/edit-role-permission/:id"
                  element={<PrivateRoute Component={EditRolePermission} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-role-permission/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/news-bulletins"
              element={<PrivateRoute Component={ListNews} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.NEWSLIST) ? (
                <Route
                  exact
                  path="/news-bulletins"
                  element={<PrivateRoute Component={ListNews} />}
                />
              ) : (
                <Route
                  exact
                  path="/news-bulletins"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-news"
              element={<PrivateRoute Component={AddNews} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.NEWSADD) ? (
                <Route
                  exact
                  path="/add-news"
                  element={<PrivateRoute Component={AddNews} />}
                />
              ) : (
                <Route exact path="/add-news" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-news/:id"
              element={<PrivateRoute Component={EditNews} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.NEWSEDIT) ? (
                <Route
                  exact
                  path="/edit-news/:id"
                  element={<PrivateRoute Component={EditNews} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-news/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/details-news/:id"
              element={<PrivateRoute Component={NewsDetails} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.NEWSLIST) ? (
                <Route
                  exact
                  path="/details-news/:id"
                  element={<PrivateRoute Component={NewsDetails} />}
                />
              ) : (
                <Route
                  exact
                  path="/details-news/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/country"
              element={<PrivateRoute Component={CountryList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.COUNTRYLIST) ? (
                <Route
                  exact
                  path="/country"
                  element={<PrivateRoute Component={CountryList} />}
                />
              ) : (
                <Route exact path="/country" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-country"
              element={<PrivateRoute Component={AddCountry} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.COUNTRYADD) ? (
                <Route
                  exact
                  path="/add-country"
                  element={<PrivateRoute Component={AddCountry} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-country"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-country/:id"
              element={<PrivateRoute Component={EditCountry} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.COUNTRYEDIT) ? (
                <Route
                  exact
                  path="/edit-country/:id"
                  element={<PrivateRoute Component={EditCountry} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-country/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/state"
              element={<PrivateRoute Component={StateList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.STATELIST) ? (
                <Route
                  exact
                  path="/state"
                  element={<PrivateRoute Component={StateList} />}
                />
              ) : (
                <Route exact path="/state" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-state"
              element={<PrivateRoute Component={AddState} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.STATEADD) ? (
                <Route
                  exact
                  path="/add-state"
                  element={<PrivateRoute Component={AddState} />}
                />
              ) : (
                <Route exact path="/add-state" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-state/:id"
              element={<PrivateRoute Component={EditState} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.STATEEDIT) ? (
                <Route
                  exact
                  path="/edit-state/:id"
                  element={<PrivateRoute Component={EditState} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-state/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/gurdwara"
              element={<PrivateRoute Component={GurdwaraList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.GURDWARALIST) ? (
                <Route
                  exact
                  path="/gurdwara"
                  element={<PrivateRoute Component={GurdwaraList} />}
                />
              ) : (
                <Route exact path="/gurdwara" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/requested-gurdwara"
              element={<PrivateRoute Component={RequestedGurdwaraList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.REQUESTGURDWARALIST) ? (
                <Route
                exact
                path="/requested-gurdwara"
                element={<PrivateRoute Component={RequestedGurdwaraList} />}
              />
              ) : (
                <Route exact path="/gurdwara" element={<PermissionAlert />} />
              )}
            </>
          )}

          {/* {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/city"
              element={<PrivateRoute Component={CityList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.CITYLIST) ? (
                <Route
                  exact
                  path="/city"
                  element={<PrivateRoute Component={CityList} />}
                />
              ) : (
                <Route exact path="/city" element={<PermissionAlert />} />
              )}
            </>
          )} */}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-gurdwara"
              element={<PrivateRoute Component={AddGurdwara} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.GURDWARAADD) ? (
                <Route
                  exact
                  path="/add-gurdwara"
                  element={<PrivateRoute Component={AddGurdwara} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-gurdwara"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {/* {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-city"
              element={<PrivateRoute Component={AddCity} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.CITYADD) ? (
                <Route
                  exact
                  path="/add-city"
                  element={<PrivateRoute Component={AddCity} />}
                />
              ) : (
                <Route exact path="/add-city" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-city/:id"
              element={<PrivateRoute Component={EditCity} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.CITYEDIT) ? (
                <Route
                  exact
                  path="/edit-city/:id"
                  element={<PrivateRoute Component={EditCity} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-city/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )} */}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-gurdwara/:id"
              element={<PrivateRoute Component={EditGurdwara} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.GURDWARAEDIT) ? (
                <Route
                  exact
                  path="/edit-gurdwara/:id"
                  element={<PrivateRoute Component={EditGurdwara} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-gurdwara/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/jathas"
              element={<PrivateRoute Component={JathasGianiPriest} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSERLIST) ? (
                <Route
                  exact
                  path="/jathas"
                  element={<PrivateRoute Component={JathasGianiPriest} />}
                />
              ) : (
                <Route exact path="/jathas" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-jathas"
              element={<PrivateRoute Component={AddJathasGianiPriest} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSERADD) ? (
                <Route
                  exact
                  path="/add-jathas"
                  element={<PrivateRoute Component={AddJathasGianiPriest} />}
                />
              ) : (
                <Route exact path="/add-jathas" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-jathas/:id"
              element={<PrivateRoute Component={EditJathasGianiPriest} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSEREDIT) ? (
                <Route
                  exact
                  path="/edit-jathas/:id"
                  element={<PrivateRoute Component={EditJathasGianiPriest} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-jathas/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {/* {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/parchaarak"
              element={<PrivateRoute Component={ParchaarakList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSERLIST) ? (
                <Route
                  exact
                  path="/parchaarak"
                  element={<PrivateRoute Component={ParchaarakList} />}
                />
              ) : (
                <Route exact path="/parchaarak" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-parchaarak"
              element={<PrivateRoute Component={AddParchaarak} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSERADD) ? (
                <Route
                  exact
                  path="/add-parchaarak"
                  element={<PrivateRoute Component={AddParchaarak} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-parchaarak"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-parchaarak/:id"
              element={<PrivateRoute Component={EditParchaarak} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSEREDIT) ? (
                <Route
                  exact
                  path="/edit-parchaarak/:id"
                  element={<PrivateRoute Component={EditParchaarak} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-parchaarak/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )} */}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/normal-user"
              element={<PrivateRoute Component={NormalUserList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSERLIST) ? (
                <Route
                  exact
                  path="/normal-user"
                  element={<PrivateRoute Component={NormalUserList} />}
                />
              ) : (
                <Route
                  exact
                  path="/normal-user"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-normaluser"
              element={<PrivateRoute Component={AddNormalUser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSERADD) ? (
                <Route
                  exact
                  path="/add-normaluser"
                  element={<PrivateRoute Component={AddNormalUser} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-normaluser"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-normaluser/:id"
              element={<PrivateRoute Component={EditNormalUser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.APPUSEREDIT) ? (
                <Route
                  exact
                  path="/edit-normaluser/:id"
                  element={<PrivateRoute Component={EditNormalUser} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-normaluser/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/event"
              element={<PrivateRoute Component={EventList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.EVENTLIST) ? (
                <Route
                  exact
                  path="/event"
                  element={<PrivateRoute Component={EventList} />}
                />
              ) : (
                <Route exact path="/event" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-event"
              element={<PrivateRoute Component={AddEvent} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.EVENTADD) ? (
                <Route
                  exact
                  path="/add-event"
                  element={<PrivateRoute Component={AddEvent} />}
                />
              ) : (
                <Route exact path="/add-event" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-event/:id"
              element={<PrivateRoute Component={EditEvent} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.EVENTEDIT) ? (
                <Route
                  exact
                  path="/edit-event/:id"
                  element={<PrivateRoute Component={EditEvent} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-event/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/event-detail/:id"
              element={<PrivateRoute Component={EventDetails} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.EVENTLIST) ? (
                <Route
                  exact
                  path="/event-detail/:id"
                  element={<PrivateRoute Component={EventDetails} />}
                />
              ) : (
                <Route
                  exact
                  path="/event-detail/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/rating-review/:id"
              element={<PrivateRoute Component={RatingReview} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.RATINGLIST) ? (
                <Route
                  exact
                  path="/rating-review/:id"
                  element={<PrivateRoute Component={RatingReview} />}
                />
              ) : (
                <Route
                  exact
                  path="/rating-review/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/rating-review"
              element={<PrivateRoute Component={RatingReview} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.RATINGLIST) ? (
                <Route
                  exact
                  path="/rating-review"
                  element={<PrivateRoute Component={RatingReview} />}
                />
              ) : (
                <Route
                  exact
                  path="/rating-review"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/site-settings"
              element={<PrivateRoute Component={SiteSettings} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.SETTINGVIEW) ? (
                <Route
                  exact
                  path="/site-settings"
                  element={<PrivateRoute Component={SiteSettings} />}
                />
              ) : (
                <Route
                  exact
                  path="/site-settings"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/about-us"
              element={<PrivateRoute Component={AboutUs} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ABOUTUSVIEWUPDATE) ? (
                <Route
                  exact
                  path="/about-us"
                  element={<PrivateRoute Component={AboutUs} />}
                />
              ) : (
                <Route exact path="/about-us" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/about-us-punjabi"
              element={<PrivateRoute Component={AboutUsPunjabi} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ABOUTUSPUNJABIVIEWUPDATE) ? (
                <Route
                  exact
                  path="/about-us-punjabi"
                  element={<PrivateRoute Component={AboutUsPunjabi} />}
                />
              ) : (
                <Route
                  exact
                  path="/about-us-punjabi"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/terms-of-use"
              element={<PrivateRoute Component={TermsUse} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.TERMSOFUSEVIEWUPDATE) ? (
                <Route
                  exact
                  path="/terms-of-use"
                  element={<PrivateRoute Component={TermsUse} />}
                />
              ) : (
                <Route
                  exact
                  path="/terms-of-use"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/what-is-kirtan"
              element={<PrivateRoute Component={Kirtan} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.WHATISKIRTANVIEWUPDATE) ? (
                <Route
                  exact
                  path="/what-is-kirtan"
                  element={<PrivateRoute Component={Kirtan} />}
                />
              ) : (
                <Route
                  exact
                  path="/what-is-kirtan"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/what-is-kirtan-punjabi"
              element={<PrivateRoute Component={KirtanPunjabi} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.WHATISKIRTANPUNJABIVIEWUPDATE) ? (
                <Route
                  exact
                  path="/what-is-kirtan-punjabi"
                  element={<PrivateRoute Component={KirtanPunjabi} />}
                />
              ) : (
                <Route
                  exact
                  path="/what-is-kirtan-punjabi"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/sikhism-basics"
              element={<PrivateRoute Component={SikhismBasics} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.SIKHISMBASICSVIEWUPDATE) ? (
                <Route
                  exact
                  path="/sikhism-basics"
                  element={<PrivateRoute Component={SikhismBasics} />}
                />
              ) : (
                <Route
                  exact
                  path="/sikhism-basics"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/sikhism-basics-punjabi"
              element={<PrivateRoute Component={SikhismBasicsPunjabi} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.SIKHISMBASICSPUNJABIVIEWUPDATE) ? (
                <Route
                  exact
                  path="/sikhism-basics-punjabi"
                  element={<PrivateRoute Component={SikhismBasicsPunjabi} />}
                />
              ) : (
                <Route
                  exact
                  path="/sikhism-basics-punjabi"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/privacy-policy"
              element={<PrivateRoute Component={PrivacyPolicy} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.PRIVACYPOLICYVIEWUPDATE) ? (
                <Route
                  exact
                  path="/privacy-policy"
                  element={<PrivateRoute Component={PrivacyPolicy} />}
                />
              ) : (
                <Route exact path="/about-us" element={<PermissionAlert />} />
              )}
            </>
          )}

          {/* {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/legal"
              element={<PrivateRoute Component={Legal} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.LEGALVIEWUPDATE) ? (
                <Route
                  exact
                  path="/legal"
                  element={<PrivateRoute Component={Legal} />}
                />
              ) : (
                <Route exact path="/legal" element={<PermissionAlert />} />
              )}
            </>
          )} */}

          {/* Onbording Routes */}
          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/onbording-list"
              element={<PrivateRoute Component={OnbordingList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ONBORDINGLIST) ? (
                <Route
                  exact
                  path="/onbording-list"
                  element={<PrivateRoute Component={OnbordingList} />}
                />
              ) : (
                <Route
                  exact
                  path="/onbording-list"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-onbording"
              element={<PrivateRoute Component={AddOnbording} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ONBORDINGADD) ? (
                <Route
                  exact
                  path="/add-onbording"
                  element={<PrivateRoute Component={AddOnbording} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-onbording"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-onbording/:id"
              element={<PrivateRoute Component={EditOnbording} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.ONBORDINGEDIT) ? (
                <Route
                  exact
                  path="/edit-onbording/:id"
                  element={<PrivateRoute Component={EditOnbording} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-onbording/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          <Route
            exact
            path="/searching-component"
            element={<PrivateRoute Component={SearchingComponent} />}
          />

          {/* Sponser Routes */}
          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/sponser-list"
              element={<PrivateRoute Component={SponserList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.SPONSORLIST) ? (
                <Route
                  exact
                  path="/sponser-list"
                  element={<PrivateRoute Component={SponserList} />}
                />
              ) : (
                <Route
                  exact
                  path="/sponser-list"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-sponser"
              element={<PrivateRoute Component={AddSponser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.SPONSORADD) ? (
                <Route
                  exact
                  path="/add-sponser"
                  element={<PrivateRoute Component={AddSponser} />}
                />
              ) : (
                <Route
                  exact
                  path="/add-sponser"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-sponser/:id"
              element={<PrivateRoute Component={EditSponser} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.SPONSOREDIT) ? (
                <Route
                  exact
                  path="/edit-sponser/:id"
                  element={<PrivateRoute Component={EditSponser} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-sponser/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {/* Help Routes */}
          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/help-list"
              element={<PrivateRoute Component={HelpList} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.HELPLIST) ? (
                <Route
                  exact
                  path="/help-list"
                  element={<PrivateRoute Component={HelpList} />}
                />
              ) : (
                <Route exact path="/help-list" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/add-help"
              element={<PrivateRoute Component={HelpAdd} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.HELPADD) ? (
                <Route
                  exact
                  path="/add-help"
                  element={<PrivateRoute Component={HelpAdd} />}
                />
              ) : (
                <Route exact path="/add-help" element={<PermissionAlert />} />
              )}
            </>
          )}

          {role == RolePermission.USERTYPE ? (
            <Route
              exact
              path="/edit-help/:id"
              element={<PrivateRoute Component={HelpEdit} />}
            />
          ) : (
            <>
              {hasPermission(RolePermission.HELPEDIT) ? (
                <Route
                  exact
                  path="/edit-help/:id"
                  element={<PrivateRoute Component={HelpEdit} />}
                />
              ) : (
                <Route
                  exact
                  path="/edit-help/:id"
                  element={<PermissionAlert />}
                />
              )}
            </>
          )}

          {/* Donation Routes */}
          <Route
            exact
            path="/donation-list"
            element={<PrivateRoute Component={DonationList} />}
          />

          {/*contactus Routes */}
          <Route
            exact
            path="/contact-us-list"
            element={<PrivateRoute Component={ContactUsList} />}
          />
          {/* Other Apps Routes */}
          <Route
            exact
            path="/otherapps-list"
            element={<PrivateRoute Component={OtherAppsList} />}
          />

          <Route
            exact
            path="/add-otherapps"
            element={<PrivateRoute Component={AddOtherApps} />}
          />

          <Route
            exact
            path="/edit-otherapps/:id"
            element={<PrivateRoute Component={EditOtherApps} />}
          />
        </Routes>
      )}
    </>
  );
};
export default AdminRoutesPermission;
