import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getNodeApi } from "../../Services/Api/appUtilsApi";
import { useLoader } from "../../Hooks/LoaderContext";

const TermsConditions = () => {
  const { setLoading } = useLoader();
  const [termsConditions, setTermsConditions] = useState({ __html: "" });

  useEffect(() => {
    const GetTermsConditions = async (type) => {
      setLoading(true);
      await getNodeApi("static-content/" + type)
        .then((res) => {
          setLoading(false);
          if (res?.status === 1) {
            setTermsConditions({ __html: res?.data?.description });
          } else {
            setTermsConditions([]);
          }
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    };
    GetTermsConditions("terms_of_use");
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Terms And Conditions</title>
      </Helmet>
      <div className="header header-one">
        <div>
          <div className="header-left header-left-one">
            <span
              className="logo"
              bis_size='{"x":66,"y":0,"w":108,"h":60,"abs_x":66,"abs_y":0}'
            >
              <img
                src="/inner-assets/img/Logo_2.png"
                alt="Logo"
                bis_size='{"x":66,"y":9,"w":108,"h":45,"abs_x":66,"abs_y":9}'
                bis_id="bn_lotwgcxq45a1o1gr5g97zo"
              />
            </span>
          </div>
        </div>
      </div>
      <div>
        <h1 className="heading">Terms And Condition</h1>
      </div>
      <div  className="terms-conditions" dangerouslySetInnerHTML={termsConditions} />
    </>
  );
};

export default TermsConditions;