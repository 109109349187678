import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { RolePermission } from "../../../Utils/constance/options";
import usePermission from "../../../Hooks/useHasPermisson";
import { getNodeApi } from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const { hasPermission, role } = usePermission();
  const { setLoading } = useLoader();

  const GetDashboard = async () => {
    setLoading(true);
    await getNodeApi("general/get-dashboard-counters")
      .then((res) => {
        setLoading(false);
        if (res) {
          setData(res?.data);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setData([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetDashboard();
  }, []);

  return (
    <>
      <Helmet>
        <title>KIRTAN4U | Dashboard</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/normal-user">Users</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.users}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.APPUSERLIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-users" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/normal-user">Users</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.users}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/sub-admin">Admin</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.admins}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.USERLIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-users" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/sub-admin">Admin</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.admins}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/jathas">Jatha or Parchaarak</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.appusers}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.APPUSERLIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-users" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/jathas-giani-priest">
                                Jatha or Parchaarak
                              </Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.appusers}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/parchaarak">Parchaaraks</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.parchaaraks}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.APPUSERLIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-users" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/parchaarak">Parchaaraks</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.parchaaraks}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )} */}

            {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/gurdwara">Gurdwara</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.gurdwaras}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.GURDWARALIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-users" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/gurdwara">Gurdwara</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.gurdwaras}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-2">
                        <i className="fas fa-users" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/event">Events</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.events}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.EVENTLIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-2">
                            <i className="fas fa-users" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/event">Events</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.events}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-4">
                        <i className="fas fa-file-alt" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/country">Countries</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.countries}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.COUNTRYLIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-4">
                            <i className="fas fa-file-alt" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/country">Country</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.countries}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-4">
                        <i className="fas fa-file-alt" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/state">States</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.states}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.STATELIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-4">
                            <i className="fas fa-file-alt" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/state">States</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.states}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* {role == RolePermission.USERTYPE ? (
              <div className="col-xl-3 col-md-4 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-4">
                        <i className="fas fa-file-alt" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title">
                          <Link to="/admin/city">Cities</Link>
                        </div>
                        <div className="dash-counts">
                          <p>{data?.cities}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {hasPermission(RolePermission.CITYLIST) && (
                  <div className="col-xl-3 col-md-4 col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="dash-widget-header">
                          <span className="dash-widget-icon bg-4">
                            <i className="fas fa-file-alt" />
                          </span>
                          <div className="dash-count">
                            <div className="dash-title">
                              <Link to="/admin/city">Cities</Link>
                            </div>
                            <div className="dash-counts">
                              <p>{data?.cities}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )} */}
          </div>
          {/* <div className="row">
            <div className="col-xl-12 d-flex">
              <div className="card flex-fill">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title">Booking Analytics</h5>
                    <div className="dropdown">
                      <select
                        name="period"
                        onChange={(e) => setAnalyticsPeriod(e.target.value)}
                      >
                        <option value="week">Weekly</option>
                        <option value="month">Monthly</option>
                        <option value="year">Yearly</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {Object.keys(chartData).length === 0 ? (
                    <span>Loading...</span>
                  ) : (
                    <Bar options={options} data={chartData} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-title">Recent Bookings</h5>
                    </div>
                    <div className="col-auto">
                      <Link
                        to="/admin/customer-booking-list"
                        className="btn-right btn btn-sm btn-outline-primary"
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card card-table">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-stripped table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th>Customer</th>
                            <th>Amount</th>
                            <th>Created On</th>
                            <th>Status</th>
                            <th className="text-right">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isLoading && (
                            <>
                              {customerBooking.map((booking, index) => (
                                <tr key={index}>
                                  <p>
                                    <h2 className="table-avatar">
                                      <img
                                        className="avatar avatar-sm me-2 avatar-img rounded-circle"
                                        src={booking?.customer?.profile_picture}
                                        alt="User"
                                      />
                                      {booking?.customer?.name}
                                    </h2>
                                  </p>
                                  <p>
                                    <span className="currency-type-text">
                                      AED{" "}
                                    </span>
                                    {booking?.customer_payment_amount}
                                  </p>
                                  <p>
                                    <span>
                                      {" "}
                                      {format(
                                        new Date(booking?.createdAt),
                                        "dd MMMM, yyyy"
                                      )}
                                    </span>
                                  </p>
                                  <p>
                                    {booking?.status === 0 && (
                                      <span className="badge bg-warning-light">
                                        Pending
                                      </span>
                                    )}
                                    {booking?.status === 1 && (
                                      <span className="badge bg-success-light">
                                        Success
                                      </span>
                                    )}
                                    {booking?.status === 2 && (
                                      <span className="badge bg-danger-light">
                                        Failed
                                      </span>
                                    )}
                                    {booking?.status === 3 && (
                                      <span className="badge bg-danger-light">
                                        Cancelled
                                      </span>
                                    )}
                                  </p>
                                  <p className="text-right">
                                    <div className="dropdown dropdown-action">
                                      <Link
                                        to="#"
                                        className="action-icon dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </Link>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <Link
                                          className="dropdown-item"
                                          to="/admin/view-booking"
                                          state={{
                                            id: booking?.booking_reference,
                                          }}
                                        >
                                          <i className="far fa-eye me-2" />
                                          View
                                        </Link>
                                      </div>
                                    </div>
                                  </p>
                                </tr>
                              ))}
                              {customerBooking.length === 0 && (
                                <tr>
                                  <p align="left" colSpan={8}>
                                    No Records Found.
                                  </p>
                                </tr>
                              )}
                              {isLoading && (
                                <tr>
                                  <p align="center" colSpan={8}>
                                    Loading...
                                  </p>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
