import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import SwitchToggle from "../../../Utils/SwitchToggle";
import { getNodeApi, putNodeApi } from "../../../Services/Api/appUtilsApi";
import { useLoader } from "../../../Hooks/LoaderContext";

const HelpEdit = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [helpData, setHelpData] = useState([]);
  const { setLoading } = useLoader();

  console.log("edit help data: ", helpData);

  const editHelpInitialValues = {
    question: helpData?.question || "",
    answer: helpData?.answer || "",
    is_active: helpData?.is_active || false,
  };

  const editHelpValidationSchema = Yup.object().shape({
    question: Yup.string().required("Question is required"),
    answer: Yup.string().required("Answer is required"),
  });

  useEffect(() => {
    const getHelpById = async () => {
      setLoading(true);
      await getNodeApi(`help/${id}`)
        .then((res) => {
          setLoading(false);
          let data = res?.data || [];
          setHelpData(data);
        })
        .catch((err) => {
          setLoading(false);
          if (typeof err?.response?.message !== "undefined") {
            ErrorToast(err?.response?.message || "something went wrong");
          } else {
            ErrorToast(err?.message || "something went wrong");
          }
        });
    };
    getHelpById();
  }, []);

  return (
    <>
      <Helmet>
        <title>Kirtan4u | Edit Help</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Help</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/help-list">Help</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Help</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/help-list" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {/* <h4 className="card-title">Basic Info</h4> */}
                  <Formik
                    enableReinitialize
                    initialValues={editHelpInitialValues}
                    validationSchema={editHelpValidationSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      setLoading(true);

                      const editHelpData = {
                        id: id,
                        question: values?.question,
                        answer: values?.answer,
                        is_active: values?.is_active,
                      };

                      await putNodeApi(`help`, editHelpData)
                        .then((res) => {
                          setLoading(false);
                          if (res?.status === 0) {
                            ErrorToast(res?.message || "something went wrong");
                          } else {
                            SuccessToast(
                              res?.message || "Help updated successfully."
                            );
                            setSubmitting(false);
                            navigate("/admin/help-list");
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          if (typeof err?.response?.message !== "undefined") {
                            ErrorToast(
                              err?.response?.message || "something went wrong"
                            );
                          } else {
                            ErrorToast(err?.message || "something went wrong");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Question
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.question && touched.question
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="question"
                                  placeholder="Enter question"
                                />
                                <ErrorMessage
                                  name="question"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Answer
                                  <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  className="form-control"
                                  name="answer"
                                  placeholder="Enter answer"
                                />
                                <ErrorMessage
                                  name="answer"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <SwitchToggle
                            setFieldValue={setFieldValue}
                            value={values}
                          />
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Edit Help
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpEdit;
