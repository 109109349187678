export const getEventImage = (name) => {
  if (name === undefined || name === "") {
    return "";
  }
  const str = name;
  let arr = "";
  arr = str.split(" ");
  const firstString = arr[0];

  let secondString = "";
  if (arr.length > 1) {
    secondString = arr[arr.length - 1];
  } else {
    return firstString.substring(0, 2).toUpperCase();
  }

  const nameString = `${firstString} ${secondString}`;
  const names = nameString.split(" ");
  let intprofile = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    intprofile += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return intprofile;
};
